@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

$dark-primary-text: rgba(black, 0.87);

// define a real custom palette (using http://mcg.mbitson.com)
$mg-brand: (
  50: #e5e8f2,
  100: #bfc5e0,
  200: #949fcb,
  300: #6979b6,
  400: #495ca6,
  500: #293f96,
  600: #24398e,
  700: #1f3183,
  800: #192979,
  900: #0f1b68,
  A100: #9ca7ff,
  A200: #697aff,
  A400: #364cff,
  A700: #1d36ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: white,
  ),
);

$mg-green: (
  50: #e3f1ea,
  100: #b9dbcb,
  200: #8ac3a8,
  300: #5bab85,
  400: #37996a,
  500: #148750,
  600: #127f49,
  700: #0e7440,
  800: #0b6a37,
  900: #065727,
  A100: #8affb2,
  A200: #57ff90,
  A400: #24ff6f,
  A700: #0aff5e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: white,
  ),
);

$mg-orange: (
  50: #fdedea,
  100: #fbd1cb,
  200: #f8b3a9,
  300: #f59586,
  400: #f37e6c,
  500: #f16752,
  600: #ef5f4b,
  700: #ed5441,
  800: #eb4a38,
  900: #e73928,
  A100: #ffffff,
  A200: #fff2f1,
  A400: #ffc2be,
  A700: #ffaba4,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: white,
  ),
);

// mandatory stuff for theming
$mg-palette-primary: mat.m2-define-palette($mg-brand);
$mg-palette-accent: mat.m2-define-palette($mg-green);
$mg-palette-warn: mat.m2-define-palette($mg-orange);

$mg-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $mg-palette-primary,
      accent: $mg-palette-accent,
      warn: $mg-palette-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: -1,
  )
);

@include mat.all-component-themes($mg-theme);

// mdc-button mdc-button--outlined mat-mdc-outlined-button mat-primary mat-mdc-button-base

.mdc-button.mdc-button--outlined.mat-primary {
  color: #293f96;
  border-color: #293f96;
}

.mat-mdc-table {
  thead th.mat-mdc-header-cell {
    background-color: #293f96;
    color: #cfd6f0;
  }

  tbody tr.mat-mdc-row:hover {
    background-color: rgba(183, 196, 248, 0.111);
    cursor: pointer;
  }
}

// Fixes this bug: https://github.com/angular/components/issues/26056
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-label-text-color: white;
}

.mat-mdc-form-field-focus-overlay {
  background-color: white;
}

.mat-mdc-form-field-error {
  font-weight: bold;
  color: mat.m2-get-color-from-palette($mg-orange, 400);
}

// Custom disabled button colors
button[disabled][color='warn'] {
  background-color: mat.m2-get-color-from-palette($mg-orange, 300);
  color: mat.m2-get-color-from-palette($mg-orange, 50);
  border-color: mat.m2-get-color-from-palette($mg-orange, 300);
}

button[disabled][color='primary'] {
  background-color: mat.m2-get-color-from-palette($mg-brand, 300);
  color: mat.m2-get-color-from-palette($mg-brand, 50);
  border-color: mat.m2-get-color-from-palette($mg-brand, 300);
}

button[disabled][color='accent'] {
  background-color: mat.m2-get-color-from-palette($mg-green, 300);
  color: mat.m2-get-color-from-palette($mg-green, 50);
  border-color: mat.m2-get-color-from-palette($mg-green, 300);
}
