/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif !important;
}

i-feather.svg-btn-primary svg {
  stroke-width: 2px !important;
}

i-feather.svg-btn-portal svg {
  height: 24px !important;
  stroke-width: 2px !important;
}

.cdk-overlay-pane {
  div.mat-mdc-menu-panel {
    min-width: 200px;
    border-top: 2px;
    border-color: #f16752;
    border-style: solid;
  }
}

.mat-mdc-menu-item,
.mat-mdc-menu-item-text {
  color: #231f20;
}

@media screen and (max-width: 768px) {
  .show-on-mobile {
    display: inherit;
  }
  .hide-on-mobile {
    display: none;
  }
}

@media only screen {
  .hide,
  .hide-on-desktop {
    display: none;
  }
}
