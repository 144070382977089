@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  svg.feather {
    stroke-width: 1.5 !important;
  }
  a {
    border-color: white;
  }
}

@layer utilities {
  .flex-2 {
    flex: 2;
  }
  .flex-3 {
    flex: 3;
  }
  .flex-4 {
    flex: 4;
  }
  .collapse {
    visibility: inherit;
  }
}
