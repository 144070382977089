@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/bootstrap.css';

.min-w-50 {
  min-width: 50px;
}

.min-w-100 {
  min-width: 100px;
}

.min-w-150 {
  min-width: 150px;
}

.min-w-200 {
  min-width: 200px;
}

.min-w-250 {
  min-width: 250px;
}

.min-w-300 {
  min-width: 300px;
}

.min-w-350 {
  min-width: 350px;
}

.min-w-400 {
  min-width: 400px;
}

.max-w-50 {
  max-width: 50px;
}

.max-w-100 {
  max-width: 100px;
}

.max-w-150 {
  max-width: 150px;
}

.max-w-200 {
  max-width: 200px;
}

.max-w-250 {
  max-width: 250px;
}

.max-w-300 {
  max-width: 300px;
}

.max-w-350 {
  max-width: 350px;
}

.max-w-400 {
  max-width: 400px;
}

@media (min-width: 1200px) {
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 1200px) {
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  .container-lg {
    max-width: 1232px;
  }
}

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1532px;
  }
}

.textblock {
  max-width: 600px;
}

#ngb-live {
  display: none;
}

.border.border-dashed {
  border-style: dashed;
  border-color: #483d3f;
}

.alert-danger {
  color: #ab0a3d;
  background-color: #f1e0e3;
  border-color: #ab0a3d;
}

.alert-warning {
  color: #c73800;
  background-color: #f3ebe0;
  border-color: #c73800;
}

.alert-success {
  color: #483d3f;
  background-color: #e1f0e8;
  border-color: #148750 !important;
}

.alert-info {
  color: #293f96;
  background-color: #e5ebf1;
  border-color: #293f96;
}

.text-warning-icon {
  color: #c73800;
}

.text-success-icon {
  color: #148750;
}

.height-100 {
  height: 100%;
}

.bg-primary * {
  color: #fff;
}

.mb-4-5 {
  margin-bottom: 2rem;
}

.modal {
  padding-right: 0;
}

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #293f96;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ea123c;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #148750;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --lightblue: #f0f0f0;
  --modal-text: #483d3f;
  --secondary-500: #68788a;
  --primary: #293f96;
  --secondary: #607d8b;
  --success: #148750;
  --info: #293f96;
  --warning: #95580e;
  --danger: #ab0a3d;
  --light: #f0f0f0;
  --dark: #455a64;
  --info-100: #e5ebf1;
  --warning-100: #f3ebe0;
  --danger-100: #f1e0e3;
  --success-100: #e1f0e8;
  --gray: #607d8b;
  --gray-dark: #483d3f;
  --blue-100: #00b2e3;
  --blue-300: #5687dd;
  --blue-500: #293f96;
  --blue-700: #113c78;
  --blue-900: #000e4e;
  --green-100: #83ed83;
  --green-300: #4ea80d;
  --green-500: #148750;
  --green-700: #116b40;
  --green-900: #0f6039;
  --red-100: #ff9678;
  --red-300: #ff644b;
  --red-500: #ea123c;
  --red-700: #a90000;
  --red-900: #730000;
  --background-100: #f0f0f0;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: 'Work Sans', sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231f20;
  text-align: left;
  background-color: #f0f0f0;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 2rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  font-style: normal;
  line-height: inherit;
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

dl,
ol,
ul {
  margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #293f96;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0083ad;
}

a:not([href]),
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

code,
kbd,
pre,
samp {
  font-family:
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    Liberation Mono,
    Courier New,
    monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  border-style: none;
}

img,
svg {
  vertical-align: middle;
}

svg {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #607d8b;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1,
h1 {
  font-size: 1.714rem;
}

.h2,
h2 {
  font-size: 1.429rem;
}

.h3,
h3 {
  font-size: 1.286rem;
}

.h4,
h4 {
  font-size: 1.143rem;
}

.h5,
.h6,
h5,
h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
}

.display-1,
.display-2 {
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
}

.display-3 {
  font-size: 4.5rem;
}

.display-3,
.display-4 {
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #607d8b;
}

.blockquote-footer:before {
  content: '\2014\A0';
}

.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f0f0f0;
  border: 1px solid #b0bec5;
  border-radius: 0.5rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #607d8b;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #231f20;
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 500;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #231f20;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #231f20;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e1e8ee;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e1e8ee;
}

.table tbody + tbody {
  border-top: 2px solid #e1e8ee;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #e1e8ee;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #231f20;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #bbd1e7;
}

.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #81aad3;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a8c4e0;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d2dbdf;
}

.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #acbbc3;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c3cfd4;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #c2ccbd;
}

.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8ea185;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b5c1af;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #bbd1e7;
}

.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #81aad3;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a8c4e0;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #d1c8ba;
}

.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #aa9880;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #c7bcab;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #dbbab9;
}

.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #bd7f7e;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #d2a9a8;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #f7fbff;
}

.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #f1f7ff;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #deeeff;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #cbd1d4;
}

.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #9ea9ae;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #bdc5c8;
}

.table-info-100,
.table-info-100 > td,
.table-info-100 > th {
  background-color: #f1f9fc;
}

.table-info-100 tbody + tbody,
.table-info-100 td,
.table-info-100 th,
.table-info-100 thead th {
  border-color: #e4f3fa;
}

.table-hover .table-info-100:hover,
.table-hover .table-info-100:hover > td,
.table-hover .table-info-100:hover > th {
  background-color: #dcf0f8;
}

.table-warning-100,
.table-warning-100 > td,
.table-warning-100 > th {
  background-color: #fdfcf4;
}

.table-warning-100 tbody + tbody,
.table-warning-100 td,
.table-warning-100 th,
.table-warning-100 thead th {
  border-color: #fbf9ea;
}

.table-hover .table-warning-100:hover,
.table-hover .table-warning-100:hover > td,
.table-hover .table-warning-100:hover > th {
  background-color: #f9f6de;
}

.table-danger-100,
.table-danger-100 > td,
.table-danger-100 > th {
  background-color: #fcf4f3;
}

.table-danger-100 tbody + tbody,
.table-danger-100 td,
.table-danger-100 th,
.table-danger-100 thead th {
  border-color: #faeae9;
}

.table-hover .table-danger-100:hover,
.table-hover .table-danger-100:hover > td,
.table-hover .table-danger-100:hover > th {
  background-color: #f7e1df;
}

.table-success-100,
.table-success-100 > td,
.table-success-100 > th {
  background-color: #f6fbf3;
}

.table-success-100 tbody + tbody,
.table-success-100 td,
.table-success-100 th,
.table-success-100 thead th {
  border-color: #eef8e9;
}

.table-hover .table-success-100:hover,
.table-hover .table-success-100:hover > td,
.table-hover .table-success-100:hover > th {
  background-color: #e8f5e0;
}

.table-gray,
.table-gray > td,
.table-gray > th {
  background-color: #d2dbdf;
}

.table-gray tbody + tbody,
.table-gray td,
.table-gray th,
.table-gray thead th {
  border-color: #acbbc3;
}

.table-hover .table-gray:hover,
.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: #c3cfd4;
}

.table-gray-dark,
.table-gray-dark > td,
.table-gray-dark > th {
  background-color: #d1d6dd;
}

.table-gray-dark tbody + tbody,
.table-gray-dark td,
.table-gray-dark th,
.table-gray-dark thead th {
  border-color: #a9b4c0;
}

.table-hover .table-gray-dark:hover,
.table-hover .table-gray-dark:hover > td,
.table-hover .table-gray-dark:hover > th {
  background-color: #c2c9d2;
}

.table-blue-100,
.table-blue-100 > td,
.table-blue-100 > th {
  background-color: #dfebff;
}

.table-blue-100 tbody + tbody,
.table-blue-100 td,
.table-blue-100 th,
.table-blue-100 thead th {
  border-color: #c3d9ff;
}

.table-hover .table-blue-100:hover,
.table-hover .table-blue-100:hover > td,
.table-hover .table-blue-100:hover > th {
  background-color: #c6dbff;
}

.table-blue-300,
.table-blue-300 > td,
.table-blue-300 > th {
  background-color: #d0ddf5;
}

.table-blue-300 tbody + tbody,
.table-blue-300 td,
.table-blue-300 th,
.table-blue-300 thead th {
  border-color: #a7c1ed;
}

.table-hover .table-blue-300:hover,
.table-hover .table-blue-300:hover > td,
.table-hover .table-blue-300:hover > th {
  background-color: #bbcef1;
}

.table-blue-500,
.table-blue-500 > td,
.table-blue-500 > th {
  background-color: #bbd1e7;
}

.table-blue-500 tbody + tbody,
.table-blue-500 td,
.table-blue-500 th,
.table-blue-500 thead th {
  border-color: #81aad3;
}

.table-hover .table-blue-500:hover,
.table-hover .table-blue-500:hover > td,
.table-hover .table-blue-500:hover > th {
  background-color: #a8c4e0;
}

.table-blue-700,
.table-blue-700 > td,
.table-blue-700 > th {
  background-color: #bcc8d9;
}

.table-blue-700 tbody + tbody,
.table-blue-700 td,
.table-blue-700 th,
.table-blue-700 thead th {
  border-color: #839ab9;
}

.table-hover .table-blue-700:hover,
.table-hover .table-blue-700:hover > td,
.table-hover .table-blue-700:hover > th {
  background-color: #acbbd0;
}

.table-blue-900,
.table-blue-900 > td,
.table-blue-900 > th {
  background-color: #b8bccd;
}

.table-blue-900 tbody + tbody,
.table-blue-900 td,
.table-blue-900 th,
.table-blue-900 thead th {
  border-color: #7a82a3;
}

.table-hover .table-blue-900:hover,
.table-hover .table-blue-900:hover > td,
.table-hover .table-blue-900:hover > th {
  background-color: #a9aec2;
}

.table-green-100,
.table-green-100 > td,
.table-green-100 > th {
  background-color: #dcfadc;
}

.table-green-100 tbody + tbody,
.table-green-100 td,
.table-green-100 th,
.table-green-100 thead th {
  border-color: #bff6bf;
}

.table-hover .table-green-100:hover,
.table-hover .table-green-100:hover > td,
.table-hover .table-green-100:hover > th {
  background-color: #c6f7c6;
}

.table-green-300,
.table-green-300 > td,
.table-green-300 > th {
  background-color: #cde7bb;
}

.table-green-300 tbody + tbody,
.table-green-300 td,
.table-green-300 th,
.table-green-300 thead th {
  border-color: #a3d281;
}

.table-hover .table-green-300:hover,
.table-hover .table-green-300:hover > td,
.table-hover .table-green-300:hover > th {
  background-color: #bfe0a8;
}

.table-green-500,
.table-green-500 > td,
.table-green-500 > th {
  background-color: #badec2;
}

.table-green-500 tbody + tbody,
.table-green-500 td,
.table-green-500 th,
.table-green-500 thead th {
  border-color: #7ec28e;
}

.table-hover .table-green-500:hover,
.table-hover .table-green-500:hover > td,
.table-hover .table-green-500:hover > th {
  background-color: #a9d6b3;
}

.table-green-700,
.table-green-700 > td,
.table-green-700 > th {
  background-color: #b8d1b8;
}

.table-green-700 tbody + tbody,
.table-green-700 td,
.table-green-700 th,
.table-green-700 thead th {
  border-color: #7aa97a;
}

.table-hover .table-green-700:hover,
.table-hover .table-green-700:hover > td,
.table-hover .table-green-700:hover > th {
  background-color: #a9c7a9;
}

.table-green-900,
.table-green-900 > td,
.table-green-900 > th {
  background-color: #b8c5b8;
}

.table-green-900 tbody + tbody,
.table-green-900 td,
.table-green-900 th,
.table-green-900 thead th {
  border-color: #7a937a;
}

.table-hover .table-green-900:hover,
.table-hover .table-green-900:hover > td,
.table-hover .table-green-900:hover > th {
  background-color: #aabaaa;
}

.table-red-100,
.table-red-100 > td,
.table-red-100 > th {
  background-color: #ffe2d9;
}

.table-red-100 tbody + tbody,
.table-red-100 td,
.table-red-100 th,
.table-red-100 thead th {
  border-color: #ffc8b9;
}

.table-hover .table-red-100:hover,
.table-hover .table-red-100:hover > td,
.table-hover .table-red-100:hover > th {
  background-color: #ffcfc0;
}

.table-red-300,
.table-red-300 > td,
.table-red-300 > th {
  background-color: #ffd4cd;
}

.table-red-300 tbody + tbody,
.table-red-300 td,
.table-red-300 th,
.table-red-300 thead th {
  border-color: #ffaea1;
}

.table-hover .table-red-300:hover,
.table-hover .table-red-300:hover > td,
.table-hover .table-red-300:hover > th {
  background-color: #ffbeb4;
}

.table-red-500,
.table-red-500 > td,
.table-red-500 > th {
  background-color: #f7c3c1;
}

.table-red-500 tbody + tbody,
.table-red-500 td,
.table-red-500 th,
.table-red-500 thead th {
  border-color: #f1908b;
}

.table-hover .table-red-500:hover,
.table-hover .table-red-500:hover > td,
.table-hover .table-red-500:hover > th {
  background-color: #f4adaa;
}

.table-red-700,
.table-red-700 > td,
.table-red-700 > th {
  background-color: #e7b8b8;
}

.table-red-700 tbody + tbody,
.table-red-700 td,
.table-red-700 th,
.table-red-700 thead th {
  border-color: #d27a7a;
}

.table-hover .table-red-700:hover,
.table-hover .table-red-700:hover > td,
.table-hover .table-red-700:hover > th {
  background-color: #e1a5a5;
}

.table-red-900,
.table-red-900 > td,
.table-red-900 > th {
  background-color: #d8b8b8;
}

.table-red-900 tbody + tbody,
.table-red-900 td,
.table-red-900 th,
.table-red-900 thead th {
  border-color: #b67a7a;
}

.table-hover .table-red-900:hover,
.table-hover .table-red-900:hover > td,
.table-hover .table-red-900:hover > th {
  background-color: #cfa8a8;
}

.table-background-100,
.table-background-100 > td,
.table-background-100 > th {
  background-color: #fbfcfd;
}

.table-background-100 tbody + tbody,
.table-background-100 td,
.table-background-100 th,
.table-background-100 thead th {
  border-color: #f7f9fc;
}

.table-hover .table-background-100:hover,
.table-hover .table-background-100:hover > td,
.table-hover .table-background-100:hover > th {
  background-color: #eaeff5;
}

.table-active,
.table-active > td,
.table-active > th,
.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #455a64;
  border-color: #556e7b;
}

.table .thead-light th {
  color: #65798f;
  background-color: #cfd8dc;
  border-color: #e1e8ee;
}

.table-dark {
  color: #fff;
  background-color: #455a64;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #556e7b;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231f20;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c1c8cc;
  border-radius: 0.5rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #231f20;
}

.form-control:focus {
  color: #231f20;
  background-color: #fff;
  border-color: #c1c8cc;
  outline: 0;
  box-shadow: 0 0 0 2px #00b2e3;
}

.form-control::-webkit-input-placeholder {
  color: #68788a;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #68788a;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #68788a;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #68788a;
  opacity: 1;
}

.form-control::placeholder {
  color: #68788a;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #cfd8dc;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #231f20;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #231f20;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -4px;
  margin-left: -4px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 4px;
  padding-left: 4px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: #607d8b;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #148750;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  // z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(37, 74, 21, 0.9);
  border-radius: 0.5rem;
}

.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #148750;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23254A15' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #148750;
  box-shadow: 0 0 0 0 rgba(37, 74, 21, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #148750;
  padding-right: calc(0.75em + 2.3125rem);
  background:
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23455A64' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23254A15' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #148750;
  box-shadow: 0 0 0 0 rgba(37, 74, 21, 0.25);
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #148750;
}

.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #148750;
}

.custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated .custom-control-input:valid ~ .custom-control-label:before {
  border-color: #148750;
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
  border-color: #397220;
  background-color: #397220;
}

.custom-control-input.is-valid:focus ~ .custom-control-label:before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0 rgba(37, 74, 21, 0.25);
}

.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before,
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #148750;
}

.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #148750;
  box-shadow: 0 0 0 0 rgba(37, 74, 21, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ab0a3d;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  // z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(128, 8, 6, 0.9);
  border-radius: 0.5rem;
}

.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #ab0a3d;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23800806'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23800806' stroke='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #ab0a3d;
  box-shadow: 0 0 0 0 rgba(128, 8, 6, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #ab0a3d;
  padding-right: calc(0.75em + 2.3125rem);
  background:
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23455A64' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23800806'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23800806' stroke='none'/%3E%3C/svg%3E")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #ab0a3d;
  box-shadow: 0 0 0 0 rgba(128, 8, 6, 0.25);
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #ab0a3d;
}

.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #ab0a3d;
}

.custom-control-input.is-invalid ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  border-color: #ab0a3d;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
  border-color: #b10b08;
  background-color: #b10b08;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0 rgba(128, 8, 6, 0.25);
}

.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before,
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #ab0a3d;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #ab0a3d;
  box-shadow: 0 0 0 0 rgba(128, 8, 6, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
  }

  .form-inline .form-group,
  .form-inline label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    flex: 0 0 auto;
    flex-flow: row wrap;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 500;
  color: #231f20;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #231f20;
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #094987;
  border-color: #09427b;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0 rgba(48, 116, 184, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #09427b;
  border-color: #083c6f;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(48, 116, 184, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #607d8b;
  border-color: #607d8b;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover {
  color: #fff;
  background-color: #506974;
  border-color: #4b626d;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0 rgba(120, 145, 156, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #607d8b;
  border-color: #607d8b;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4b626d;
  border-color: #465b65;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(120, 145, 156, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
  color: #fff;
  background-color: #162c0d;
  border-color: #11220a;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0 rgba(70, 101, 56, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #11220a;
  border-color: #0c1807;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(70, 101, 56, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-info.focus,
.btn-info:focus,
.btn-info:hover {
  color: #fff;
  background-color: #094987;
  border-color: #09427b;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0 rgba(48, 116, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #09427b;
  border-color: #083c6f;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(48, 116, 184, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #c73800;
  border-color: #c73800;
}

.btn-warning.focus,
.btn-warning:focus,
.btn-warning:hover {
  color: #fff;
  background-color: #392306;
  border-color: #2d1c05;
}

.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0 rgba(116, 87, 47, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #c73800;
  border-color: #c73800;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #2d1c05;
  border-color: #221504;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(116, 87, 47, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ab0a3d;
  border-color: #ab0a3d;
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover {
  color: #fff;
  background-color: #5b0604;
  border-color: #4f0504;
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0 rgba(147, 45, 43, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #ab0a3d;
  border-color: #ab0a3d;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #4f0504;
  border-color: #430403;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(147, 45, 43, 0.5);
}

.btn-light {
  color: #231f20;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-light.focus,
.btn-light:focus,
.btn-light:hover {
  color: #231f20;
  background-color: #bedbff;
  border-color: #b1d4ff;
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0 rgba(196, 208, 223, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #231f20;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #231f20;
  background-color: #b1d4ff;
  border-color: #a4cdff;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(196, 208, 223, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64;
}

.btn-dark.focus,
.btn-dark:focus,
.btn-dark:hover {
  color: #fff;
  background-color: #35464d;
  border-color: #303f46;
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0 rgba(97, 115, 123, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #303f46;
  border-color: #2b383e;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(97, 115, 123, 0.5);
}

.btn-info-100 {
  color: #231f20;
  background-color: #e5ebf1;
  border-color: #e5ebf1;
}

.btn-info-100.focus,
.btn-info-100:focus,
.btn-info-100:hover {
  color: #231f20;
  background-color: #acdaef;
  border-color: #a1d5ed;
}

.btn-info-100.focus,
.btn-info-100:focus {
  box-shadow: 0 0 0 0 rgba(175, 201, 214, 0.5);
}

.btn-info-100.disabled,
.btn-info-100:disabled {
  color: #231f20;
  background-color: #e5ebf1;
  border-color: #e5ebf1;
}

.btn-info-100:not(:disabled):not(.disabled).active,
.btn-info-100:not(:disabled):not(.disabled):active,
.show > .btn-info-100.dropdown-toggle {
  color: #231f20;
  background-color: #a1d5ed;
  border-color: #97d0eb;
}

.btn-info-100:not(:disabled):not(.disabled).active:focus,
.btn-info-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-info-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(175, 201, 214, 0.5);
}

.btn-warning-100 {
  color: #231f20;
  background-color: #f3ebe0;
  border-color: #f3ebe0;
}

.btn-warning-100.focus,
.btn-warning-100:focus,
.btn-warning-100:hover {
  color: #231f20;
  background-color: #f2e9b5;
  border-color: #f1e6aa;
}

.btn-warning-100.focus,
.btn-warning-100:focus {
  box-shadow: 0 0 0 0 rgba(213, 211, 188, 0.5);
}

.btn-warning-100.disabled,
.btn-warning-100:disabled {
  color: #231f20;
  background-color: #f3ebe0;
  border-color: #f3ebe0;
}

.btn-warning-100:not(:disabled):not(.disabled).active,
.btn-warning-100:not(:disabled):not(.disabled):active,
.show > .btn-warning-100.dropdown-toggle {
  color: #231f20;
  background-color: #f1e6aa;
  border-color: #efe3a0;
}

.btn-warning-100:not(:disabled):not(.disabled).active:focus,
.btn-warning-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(213, 211, 188, 0.5);
}

.btn-danger-100 {
  color: #231f20;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}

.btn-danger-100.focus,
.btn-danger-100:focus,
.btn-danger-100:hover {
  color: #231f20;
  background-color: #efbab4;
  border-color: #edb0aa;
}

.btn-danger-100.focus,
.btn-danger-100:focus {
  box-shadow: 0 0 0 0 rgba(211, 187, 186, 0.5);
}

.btn-danger-100.disabled,
.btn-danger-100:disabled {
  color: #231f20;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}

.btn-danger-100:not(:disabled):not(.disabled).active,
.btn-danger-100:not(:disabled):not(.disabled):active,
.show > .btn-danger-100.dropdown-toggle {
  color: #231f20;
  background-color: #edb0aa;
  border-color: #eba69f;
}

.btn-danger-100:not(:disabled):not(.disabled).active:focus,
.btn-danger-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 187, 186, 0.5);
}

.btn-success-100 {
  color: #231f20;
  background-color: #e1f0e8;
  border-color: #e1f0e8;
}

.btn-success-100.focus,
.btn-success-100:focus,
.btn-success-100:hover {
  color: #231f20;
  background-color: #c7e9b8;
  border-color: #bfe6ae;
}

.btn-success-100.focus,
.btn-success-100:focus {
  box-shadow: 0 0 0 0 rgba(191, 210, 187, 0.5);
}

.btn-success-100.disabled,
.btn-success-100:disabled {
  color: #231f20;
  background-color: #e1f0e8;
  border-color: #e1f0e8;
}

.btn-success-100:not(:disabled):not(.disabled).active,
.btn-success-100:not(:disabled):not(.disabled):active,
.show > .btn-success-100.dropdown-toggle {
  color: #231f20;
  background-color: #bfe6ae;
  border-color: #b8e3a4;
}

.btn-success-100:not(:disabled):not(.disabled).active:focus,
.btn-success-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-success-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(191, 210, 187, 0.5);
}

.btn-gray {
  color: #fff;
  background-color: #607d8b;
  border-color: #607d8b;
}

.btn-gray.focus,
.btn-gray:focus,
.btn-gray:hover {
  color: #fff;
  background-color: #506974;
  border-color: #4b626d;
}

.btn-gray.focus,
.btn-gray:focus {
  box-shadow: 0 0 0 0 rgba(120, 145, 156, 0.5);
}

.btn-gray.disabled,
.btn-gray:disabled {
  color: #fff;
  background-color: #607d8b;
  border-color: #607d8b;
}

.btn-gray:not(:disabled):not(.disabled).active,
.btn-gray:not(:disabled):not(.disabled):active,
.show > .btn-gray.dropdown-toggle {
  color: #fff;
  background-color: #4b626d;
  border-color: #465b65;
}

.btn-gray:not(:disabled):not(.disabled).active:focus,
.btn-gray:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(120, 145, 156, 0.5);
}

.btn-gray-dark {
  color: #fff;
  background-color: #65798f;
  border-color: #65798f;
}

.btn-gray-dark.focus,
.btn-gray-dark:focus,
.btn-gray-dark:hover {
  color: #fff;
  background-color: #4b5b6e;
  border-color: #455567;
}

.btn-gray-dark.focus,
.btn-gray-dark:focus {
  box-shadow: 0 0 0 0 rgba(115, 132, 151, 0.5);
}

.btn-gray-dark.disabled,
.btn-gray-dark:disabled {
  color: #fff;
  background-color: #65798f;
  border-color: #65798f;
}

.btn-gray-dark:not(:disabled):not(.disabled).active,
.btn-gray-dark:not(:disabled):not(.disabled):active,
.show > .btn-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #455567;
  border-color: #404f5f;
}

.btn-gray-dark:not(:disabled):not(.disabled).active:focus,
.btn-gray-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(115, 132, 151, 0.5);
}

.btn-blue-100 {
  color: #231f20;
  background-color: #00b2e3;
  border-color: #00b2e3;
}

.btn-blue-100.focus,
.btn-blue-100:focus,
.btn-blue-100:hover {
  color: #231f20;
  background-color: #669eff;
  border-color: #5996ff;
}

.btn-blue-100.focus,
.btn-blue-100:focus {
  box-shadow: 0 0 0 0 rgba(121, 159, 223, 0.5);
}

.btn-blue-100.disabled,
.btn-blue-100:disabled {
  color: #231f20;
  background-color: #00b2e3;
  border-color: #00b2e3;
}

.btn-blue-100:not(:disabled):not(.disabled).active,
.btn-blue-100:not(:disabled):not(.disabled):active,
.show > .btn-blue-100.dropdown-toggle {
  color: #fff;
  background-color: #5996ff;
  border-color: #4c8eff;
}

.btn-blue-100:not(:disabled):not(.disabled).active:focus,
.btn-blue-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-blue-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(121, 159, 223, 0.5);
}

.btn-blue-300 {
  color: #fff;
  background-color: #5687dd;
  border-color: #5687dd;
}

.btn-blue-300.focus,
.btn-blue-300:focus,
.btn-blue-300:hover {
  color: #fff;
  background-color: #3670d7;
  border-color: #2c69d4;
}

.btn-blue-300.focus,
.btn-blue-300:focus {
  box-shadow: 0 0 0 0 rgba(111, 153, 226, 0.5);
}

.btn-blue-300.disabled,
.btn-blue-300:disabled {
  color: #fff;
  background-color: #5687dd;
  border-color: #5687dd;
}

.btn-blue-300:not(:disabled):not(.disabled).active,
.btn-blue-300:not(:disabled):not(.disabled):active,
.show > .btn-blue-300.dropdown-toggle {
  color: #fff;
  background-color: #2c69d4;
  border-color: #2963cb;
}

.btn-blue-300:not(:disabled):not(.disabled).active:focus,
.btn-blue-300:not(:disabled):not(.disabled):active:focus,
.show > .btn-blue-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(111, 153, 226, 0.5);
}

.btn-blue-500 {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-blue-500.focus,
.btn-blue-500:focus,
.btn-blue-500:hover {
  color: #fff;
  background-color: #094987;
  border-color: #09427b;
}

.btn-blue-500.focus,
.btn-blue-500:focus {
  box-shadow: 0 0 0 0 rgba(48, 116, 184, 0.5);
}

.btn-blue-500.disabled,
.btn-blue-500:disabled {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-blue-500:not(:disabled):not(.disabled).active,
.btn-blue-500:not(:disabled):not(.disabled):active,
.show > .btn-blue-500.dropdown-toggle {
  color: #fff;
  background-color: #09427b;
  border-color: #083c6f;
}

.btn-blue-500:not(:disabled):not(.disabled).active:focus,
.btn-blue-500:not(:disabled):not(.disabled):active:focus,
.show > .btn-blue-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(48, 116, 184, 0.5);
}

.btn-blue-700 {
  color: #fff;
  background-color: #113c78;
  border-color: #113c78;
}

.btn-blue-700.focus,
.btn-blue-700:focus,
.btn-blue-700:hover {
  color: #fff;
  background-color: #0c2b56;
  border-color: #0b264b;
}

.btn-blue-700.focus,
.btn-blue-700:focus {
  box-shadow: 0 0 0 0 rgba(53, 89, 140, 0.5);
}

.btn-blue-700.disabled,
.btn-blue-700:disabled {
  color: #fff;
  background-color: #113c78;
  border-color: #113c78;
}

.btn-blue-700:not(:disabled):not(.disabled).active,
.btn-blue-700:not(:disabled):not(.disabled):active,
.show > .btn-blue-700.dropdown-toggle {
  color: #fff;
  background-color: #0b264b;
  border-color: #092040;
}

.btn-blue-700:not(:disabled):not(.disabled).active:focus,
.btn-blue-700:not(:disabled):not(.disabled):active:focus,
.show > .btn-blue-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(53, 89, 140, 0.5);
}

.btn-blue-900 {
  color: #fff;
  background-color: #000e4e;
  border-color: #000e4e;
}

.btn-blue-900.focus,
.btn-blue-900:focus,
.btn-blue-900:hover {
  color: #fff;
  background-color: #000728;
  border-color: #00051b;
}

.btn-blue-900.focus,
.btn-blue-900:focus {
  box-shadow: 0 0 0 0 rgba(38, 50, 105, 0.5);
}

.btn-blue-900.disabled,
.btn-blue-900:disabled {
  color: #fff;
  background-color: #000e4e;
  border-color: #000e4e;
}

.btn-blue-900:not(:disabled):not(.disabled).active,
.btn-blue-900:not(:disabled):not(.disabled):active,
.show > .btn-blue-900.dropdown-toggle {
  color: #fff;
  background-color: #00051b;
  border-color: #00030e;
}

.btn-blue-900:not(:disabled):not(.disabled).active:focus,
.btn-blue-900:not(:disabled):not(.disabled):active:focus,
.show > .btn-blue-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 50, 105, 0.5);
}

.btn-green-100 {
  color: #231f20;
  background-color: #83ed83;
  border-color: #83ed83;
}

.btn-green-100.focus,
.btn-green-100:focus,
.btn-green-100:hover {
  color: #231f20;
  background-color: #62e862;
  border-color: #56e756;
}

.btn-green-100.focus,
.btn-green-100:focus {
  box-shadow: 0 0 0 0 rgba(113, 206, 117, 0.5);
}

.btn-green-100.disabled,
.btn-green-100:disabled {
  color: #231f20;
  background-color: #83ed83;
  border-color: #83ed83;
}

.btn-green-100:not(:disabled):not(.disabled).active,
.btn-green-100:not(:disabled):not(.disabled):active,
.show > .btn-green-100.dropdown-toggle {
  color: #231f20;
  background-color: #56e756;
  border-color: #4be54b;
}

.btn-green-100:not(:disabled):not(.disabled).active:focus,
.btn-green-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-green-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(113, 206, 117, 0.5);
}

.btn-green-300 {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-green-300.focus,
.btn-green-300:focus,
.btn-green-300:hover {
  color: #fff;
  background-color: #3e840a;
  border-color: #387909;
}

.btn-green-300.focus,
.btn-green-300:focus {
  box-shadow: 0 0 0 0 rgba(105, 181, 49, 0.5);
}

.btn-green-300.disabled,
.btn-green-300:disabled {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-green-300:not(:disabled):not(.disabled).active,
.btn-green-300:not(:disabled):not(.disabled):active,
.show > .btn-green-300.dropdown-toggle {
  color: #fff;
  background-color: #387909;
  border-color: #336d08;
}

.btn-green-300:not(:disabled):not(.disabled).active:focus,
.btn-green-300:not(:disabled):not(.disabled):active:focus,
.show > .btn-green-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(105, 181, 49, 0.5);
}

.btn-green-500 {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-green-500.focus,
.btn-green-500:focus,
.btn-green-500:hover {
  color: #fff;
  background-color: #05651c;
  border-color: #055819;
}

.btn-green-500.focus,
.btn-green-500:focus {
  box-shadow: 0 0 0 0 rgba(44, 155, 71, 0.5);
}

.btn-green-500.disabled,
.btn-green-500:disabled {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-green-500:not(:disabled):not(.disabled).active,
.btn-green-500:not(:disabled):not(.disabled):active,
.show > .btn-green-500.dropdown-toggle {
  color: #fff;
  background-color: #055819;
  border-color: #044c15;
}

.btn-green-500:not(:disabled):not(.disabled).active:focus,
.btn-green-500:not(:disabled):not(.disabled):active:focus,
.show > .btn-green-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(44, 155, 71, 0.5);
}

.btn-green-700 {
  color: #fff;
  background-color: #116b40;
  border-color: #116b40;
}

.btn-green-700.focus,
.btn-green-700:focus,
.btn-green-700:hover {
  color: #fff;
  background-color: #003400;
  border-color: #002700;
}

.btn-green-700.focus,
.btn-green-700:focus {
  box-shadow: 0 0 0 0 rgba(38, 115, 38, 0.5);
}

.btn-green-700.disabled,
.btn-green-700:disabled {
  color: #fff;
  background-color: #116b40;
  border-color: #116b40;
}

.btn-green-700:not(:disabled):not(.disabled).active,
.btn-green-700:not(:disabled):not(.disabled):active,
.show > .btn-green-700.dropdown-toggle {
  color: #fff;
  background-color: #002700;
  border-color: #001a00;
}

.btn-green-700:not(:disabled):not(.disabled).active:focus,
.btn-green-700:not(:disabled):not(.disabled):active:focus,
.show > .btn-green-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 115, 38, 0.5);
}

.btn-green-900 {
  color: #fff;
  background-color: #0f6039;
  border-color: #0f6039;
}

.btn-green-900.focus,
.btn-green-900:focus,
.btn-green-900:hover {
  color: #fff;
  background-color: #000900;
  border-color: #231f20;
}

.btn-green-900.focus,
.btn-green-900:focus {
  box-shadow: 0 0 0 0 rgba(38, 78, 38, 0.5);
}

.btn-green-900.disabled,
.btn-green-900:disabled {
  color: #fff;
  background-color: #0f6039;
  border-color: #0f6039;
}

.btn-green-900:not(:disabled):not(.disabled).active,
.btn-green-900:not(:disabled):not(.disabled):active,
.show > .btn-green-900.dropdown-toggle {
  color: #fff;
  background-color: #231f20;
  border-color: #231f20;
}

.btn-green-900:not(:disabled):not(.disabled).active:focus,
.btn-green-900:not(:disabled):not(.disabled):active:focus,
.show > .btn-green-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 78, 38, 0.5);
}

.btn-red-100 {
  color: #231f20;
  background-color: #ff9678;
  border-color: #ff9678;
}

.btn-red-100.focus,
.btn-red-100:focus,
.btn-red-100:hover {
  color: #231f20;
  background-color: #ff7852;
  border-color: #ff6e45;
}

.btn-red-100.focus,
.btn-red-100:focus {
  box-shadow: 0 0 0 0 rgba(219, 132, 108, 0.5);
}

.btn-red-100.disabled,
.btn-red-100:disabled {
  color: #231f20;
  background-color: #ff9678;
  border-color: #ff9678;
}

.btn-red-100:not(:disabled):not(.disabled).active,
.btn-red-100:not(:disabled):not(.disabled):active,
.show > .btn-red-100.dropdown-toggle {
  color: #fff;
  background-color: #ff6e45;
  border-color: #ff6438;
}

.btn-red-100:not(:disabled):not(.disabled).active:focus,
.btn-red-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-red-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(219, 132, 108, 0.5);
}

.btn-red-300 {
  color: #fff;
  background-color: #ff644b;
  border-color: #ff644b;
}

.btn-red-300.focus,
.btn-red-300:focus,
.btn-red-300:hover {
  color: #fff;
  background-color: #ff4325;
  border-color: #ff3818;
}

.btn-red-300.focus,
.btn-red-300:focus {
  box-shadow: 0 0 0 0 rgba(255, 123, 102, 0.5);
}

.btn-red-300.disabled,
.btn-red-300:disabled {
  color: #fff;
  background-color: #ff644b;
  border-color: #ff644b;
}

.btn-red-300:not(:disabled):not(.disabled).active,
.btn-red-300:not(:disabled):not(.disabled):active,
.show > .btn-red-300.dropdown-toggle {
  color: #fff;
  background-color: #ff3818;
  border-color: #ff2d0b;
}

.btn-red-300:not(:disabled):not(.disabled).active:focus,
.btn-red-300:not(:disabled):not(.disabled):active:focus,
.show > .btn-red-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 123, 102, 0.5);
}

.btn-red-500 {
  color: #fff;
  background-color: #ea123c;
  border-color: #ea123c;
}

.btn-red-500.focus,
.btn-red-500:focus,
.btn-red-500:hover {
  color: #fff;
  background-color: #c62018;
  border-color: #ba1e17;
}

.btn-red-500.focus,
.btn-red-500:focus {
  box-shadow: 0 0 0 0 rgba(232, 73, 65, 0.5);
}

.btn-red-500.disabled,
.btn-red-500:disabled {
  color: #fff;
  background-color: #ea123c;
  border-color: #ea123c;
}

.btn-red-500:not(:disabled):not(.disabled).active,
.btn-red-500:not(:disabled):not(.disabled):active,
.show > .btn-red-500.dropdown-toggle {
  color: #fff;
  background-color: #ba1e17;
  border-color: #af1c15;
}

.btn-red-500:not(:disabled):not(.disabled).active:focus,
.btn-red-500:not(:disabled):not(.disabled):active:focus,
.show > .btn-red-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(232, 73, 65, 0.5);
}

.btn-red-700 {
  color: #fff;
  background-color: #a90000;
  border-color: #a90000;
}

.btn-red-700.focus,
.btn-red-700:focus,
.btn-red-700:hover {
  color: #fff;
  background-color: #830000;
  border-color: #760000;
}

.btn-red-700.focus,
.btn-red-700:focus {
  box-shadow: 0 0 0 0 rgba(182, 38, 38, 0.5);
}

.btn-red-700.disabled,
.btn-red-700:disabled {
  color: #fff;
  background-color: #a90000;
  border-color: #a90000;
}

.btn-red-700:not(:disabled):not(.disabled).active,
.btn-red-700:not(:disabled):not(.disabled):active,
.show > .btn-red-700.dropdown-toggle {
  color: #fff;
  background-color: #760000;
  border-color: #690000;
}

.btn-red-700:not(:disabled):not(.disabled).active:focus,
.btn-red-700:not(:disabled):not(.disabled):active:focus,
.show > .btn-red-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(182, 38, 38, 0.5);
}

.btn-red-900 {
  color: #fff;
  background-color: #730000;
  border-color: #730000;
}

.btn-red-900.focus,
.btn-red-900:focus,
.btn-red-900:hover {
  color: #fff;
  background-color: #4d0000;
  border-color: #400000;
}

.btn-red-900.focus,
.btn-red-900:focus {
  box-shadow: 0 0 0 0 rgba(136, 38, 38, 0.5);
}

.btn-red-900.disabled,
.btn-red-900:disabled {
  color: #fff;
  background-color: #730000;
  border-color: #730000;
}

.btn-red-900:not(:disabled):not(.disabled).active,
.btn-red-900:not(:disabled):not(.disabled):active,
.show > .btn-red-900.dropdown-toggle {
  color: #fff;
  background-color: #400000;
  border-color: #300;
}

.btn-red-900:not(:disabled):not(.disabled).active:focus,
.btn-red-900:not(:disabled):not(.disabled):active:focus,
.show > .btn-red-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(136, 38, 38, 0.5);
}

.btn-background-100 {
  color: #231f20;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-background-100.focus,
.btn-background-100:focus,
.btn-background-100:hover {
  color: #231f20;
  background-color: #d3deef;
  border-color: #e5ebf1;
}

.btn-background-100.focus,
.btn-background-100:focus {
  box-shadow: 0 0 0 0 rgba(205, 211, 218, 0.5);
}

.btn-background-100.disabled,
.btn-background-100:disabled {
  color: #231f20;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-background-100:not(:disabled):not(.disabled).active,
.btn-background-100:not(:disabled):not(.disabled):active,
.show > .btn-background-100.dropdown-toggle {
  color: #231f20;
  background-color: #e5ebf1;
  border-color: #c1d0e8;
}

.btn-background-100:not(:disabled):not(.disabled).active:focus,
.btn-background-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-background-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(205, 211, 218, 0.5);
}

.btn-outline-primary {
  color: #293f96;
  border-color: #293f96;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(12, 92, 171, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #293f96;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(12, 92, 171, 0.5);
}

.btn-outline-secondary {
  color: #607d8b;
  border-color: #607d8b;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #607d8b;
  border-color: #607d8b;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(96, 125, 139, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #607d8b;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #607d8b;
  border-color: #607d8b;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(96, 125, 139, 0.5);
}

.btn-outline-success {
  color: #148750;
  border-color: #148750;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(37, 74, 21, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #148750;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(37, 74, 21, 0.5);
}

.btn-outline-info {
  color: #293f96;
  border-color: #293f96;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(12, 92, 171, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #293f96;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(12, 92, 171, 0.5);
}

.btn-outline-warning {
  color: #c73800;
  border-color: #c73800;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #c73800;
  border-color: #c73800;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(91, 57, 10, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #c73800;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #c73800;
  border-color: #c73800;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(91, 57, 10, 0.5);
}

.btn-outline-danger {
  color: #ab0a3d;
  border-color: #ab0a3d;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ab0a3d;
  border-color: #ab0a3d;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(128, 8, 6, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ab0a3d;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ab0a3d;
  border-color: #ab0a3d;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(128, 8, 6, 0.5);
}

.btn-outline-light {
  color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-outline-light:hover {
  color: #231f20;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(228, 240, 255, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f0f0f0;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #231f20;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(228, 240, 255, 0.5);
}

.btn-outline-dark {
  color: #455a64;
  border-color: #455a64;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(69, 90, 100, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #455a64;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(69, 90, 100, 0.5);
}

.btn-outline-info-100 {
  color: #e5ebf1;
  border-color: #e5ebf1;
}

.btn-outline-info-100:hover {
  color: #231f20;
  background-color: #e5ebf1;
  border-color: #e5ebf1;
}

.btn-outline-info-100.focus,
.btn-outline-info-100:focus {
  box-shadow: 0 0 0 0 rgba(204, 232, 245, 0.5);
}

.btn-outline-info-100.disabled,
.btn-outline-info-100:disabled {
  color: #e5ebf1;
  background-color: transparent;
}

.btn-outline-info-100:not(:disabled):not(.disabled).active,
.btn-outline-info-100:not(:disabled):not(.disabled):active,
.show > .btn-outline-info-100.dropdown-toggle {
  color: #231f20;
  background-color: #e5ebf1;
  border-color: #e5ebf1;
}

.btn-outline-info-100:not(:disabled):not(.disabled).active:focus,
.btn-outline-info-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(204, 232, 245, 0.5);
}

.btn-outline-warning-100 {
  color: #f3ebe0;
  border-color: #f3ebe0;
}

.btn-outline-warning-100:hover {
  color: #231f20;
  background-color: #f3ebe0;
  border-color: #f3ebe0;
}

.btn-outline-warning-100.focus,
.btn-outline-warning-100:focus {
  box-shadow: 0 0 0 0 rgba(248, 243, 214, 0.5);
}

.btn-outline-warning-100.disabled,
.btn-outline-warning-100:disabled {
  color: #f3ebe0;
  background-color: transparent;
}

.btn-outline-warning-100:not(:disabled):not(.disabled).active,
.btn-outline-warning-100:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning-100.dropdown-toggle {
  color: #231f20;
  background-color: #f3ebe0;
  border-color: #f3ebe0;
}

.btn-outline-warning-100:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(248, 243, 214, 0.5);
}

.btn-outline-danger-100 {
  color: #f1e0e3;
  border-color: #f1e0e3;
}

.btn-outline-danger-100:hover {
  color: #231f20;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}

.btn-outline-danger-100.focus,
.btn-outline-danger-100:focus {
  box-shadow: 0 0 0 0 rgba(246, 215, 212, 0.5);
}

.btn-outline-danger-100.disabled,
.btn-outline-danger-100:disabled {
  color: #f1e0e3;
  background-color: transparent;
}

.btn-outline-danger-100:not(:disabled):not(.disabled).active,
.btn-outline-danger-100:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger-100.dropdown-toggle {
  color: #231f20;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}

.btn-outline-danger-100:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(246, 215, 212, 0.5);
}

.btn-outline-success-100 {
  color: #e1f0e8;
  border-color: #e1f0e8;
}

.btn-outline-success-100:hover {
  color: #231f20;
  background-color: #e1f0e8;
  border-color: #e1f0e8;
}

.btn-outline-success-100.focus,
.btn-outline-success-100:focus {
  box-shadow: 0 0 0 0 rgba(222, 242, 213, 0.5);
}

.btn-outline-success-100.disabled,
.btn-outline-success-100:disabled {
  color: #e1f0e8;
  background-color: transparent;
}

.btn-outline-success-100:not(:disabled):not(.disabled).active,
.btn-outline-success-100:not(:disabled):not(.disabled):active,
.show > .btn-outline-success-100.dropdown-toggle {
  color: #231f20;
  background-color: #e1f0e8;
  border-color: #e1f0e8;
}

.btn-outline-success-100:not(:disabled):not(.disabled).active:focus,
.btn-outline-success-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(222, 242, 213, 0.5);
}

.btn-outline-gray {
  color: #607d8b;
  border-color: #607d8b;
}

.btn-outline-gray:hover {
  color: #fff;
  background-color: #607d8b;
  border-color: #607d8b;
}

.btn-outline-gray.focus,
.btn-outline-gray:focus {
  box-shadow: 0 0 0 0 rgba(96, 125, 139, 0.5);
}

.btn-outline-gray.disabled,
.btn-outline-gray:disabled {
  color: #607d8b;
  background-color: transparent;
}

.btn-outline-gray:not(:disabled):not(.disabled).active,
.btn-outline-gray:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #607d8b;
  border-color: #607d8b;
}

.btn-outline-gray:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(96, 125, 139, 0.5);
}

.btn-outline-gray-dark {
  color: #65798f;
  border-color: #65798f;
}

.btn-outline-gray-dark:hover {
  color: #fff;
  background-color: #65798f;
  border-color: #65798f;
}

.btn-outline-gray-dark.focus,
.btn-outline-gray-dark:focus {
  box-shadow: 0 0 0 0 rgba(90, 110, 133, 0.5);
}

.btn-outline-gray-dark.disabled,
.btn-outline-gray-dark:disabled {
  color: #65798f;
  background-color: transparent;
}

.btn-outline-gray-dark:not(:disabled):not(.disabled).active,
.btn-outline-gray-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #65798f;
  border-color: #65798f;
}

.btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(90, 110, 133, 0.5);
}

.btn-outline-blue-100 {
  color: #00b2e3;
  border-color: #00b2e3;
}

.btn-outline-blue-100:hover {
  color: #231f20;
  background-color: #00b2e3;
  border-color: #00b2e3;
}

.btn-outline-blue-100.focus,
.btn-outline-blue-100:focus {
  box-shadow: 0 0 0 0 rgba(140, 182, 255, 0.5);
}

.btn-outline-blue-100.disabled,
.btn-outline-blue-100:disabled {
  color: #00b2e3;
  background-color: transparent;
}

.btn-outline-blue-100:not(:disabled):not(.disabled).active,
.btn-outline-blue-100:not(:disabled):not(.disabled):active,
.show > .btn-outline-blue-100.dropdown-toggle {
  color: #231f20;
  background-color: #00b2e3;
  border-color: #00b2e3;
}

.btn-outline-blue-100:not(:disabled):not(.disabled).active:focus,
.btn-outline-blue-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-blue-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(140, 182, 255, 0.5);
}

.btn-outline-blue-300 {
  color: #5687dd;
  border-color: #5687dd;
}

.btn-outline-blue-300:hover {
  color: #fff;
  background-color: #5687dd;
  border-color: #5687dd;
}

.btn-outline-blue-300.focus,
.btn-outline-blue-300:focus {
  box-shadow: 0 0 0 0 rgba(86, 135, 221, 0.5);
}

.btn-outline-blue-300.disabled,
.btn-outline-blue-300:disabled {
  color: #5687dd;
  background-color: transparent;
}

.btn-outline-blue-300:not(:disabled):not(.disabled).active,
.btn-outline-blue-300:not(:disabled):not(.disabled):active,
.show > .btn-outline-blue-300.dropdown-toggle {
  color: #fff;
  background-color: #5687dd;
  border-color: #5687dd;
}

.btn-outline-blue-300:not(:disabled):not(.disabled).active:focus,
.btn-outline-blue-300:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-blue-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(86, 135, 221, 0.5);
}

.btn-outline-blue-500 {
  color: #293f96;
  border-color: #293f96;
}

.btn-outline-blue-500:hover {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-outline-blue-500.focus,
.btn-outline-blue-500:focus {
  box-shadow: 0 0 0 0 rgba(12, 92, 171, 0.5);
}

.btn-outline-blue-500.disabled,
.btn-outline-blue-500:disabled {
  color: #293f96;
  background-color: transparent;
}

.btn-outline-blue-500:not(:disabled):not(.disabled).active,
.btn-outline-blue-500:not(:disabled):not(.disabled):active,
.show > .btn-outline-blue-500.dropdown-toggle {
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.btn-outline-blue-500:not(:disabled):not(.disabled).active:focus,
.btn-outline-blue-500:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-blue-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(12, 92, 171, 0.5);
}

.btn-outline-blue-700 {
  color: #113c78;
  border-color: #113c78;
}

.btn-outline-blue-700:hover {
  color: #fff;
  background-color: #113c78;
  border-color: #113c78;
}

.btn-outline-blue-700.focus,
.btn-outline-blue-700:focus {
  box-shadow: 0 0 0 0 rgba(17, 60, 120, 0.5);
}

.btn-outline-blue-700.disabled,
.btn-outline-blue-700:disabled {
  color: #113c78;
  background-color: transparent;
}

.btn-outline-blue-700:not(:disabled):not(.disabled).active,
.btn-outline-blue-700:not(:disabled):not(.disabled):active,
.show > .btn-outline-blue-700.dropdown-toggle {
  color: #fff;
  background-color: #113c78;
  border-color: #113c78;
}

.btn-outline-blue-700:not(:disabled):not(.disabled).active:focus,
.btn-outline-blue-700:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-blue-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(17, 60, 120, 0.5);
}

.btn-outline-blue-900 {
  color: #000e4e;
  border-color: #000e4e;
}

.btn-outline-blue-900:hover {
  color: #fff;
  background-color: #000e4e;
  border-color: #000e4e;
}

.btn-outline-blue-900.focus,
.btn-outline-blue-900:focus {
  box-shadow: 0 0 0 0 rgba(0, 14, 78, 0.5);
}

.btn-outline-blue-900.disabled,
.btn-outline-blue-900:disabled {
  color: #000e4e;
  background-color: transparent;
}

.btn-outline-blue-900:not(:disabled):not(.disabled).active,
.btn-outline-blue-900:not(:disabled):not(.disabled):active,
.show > .btn-outline-blue-900.dropdown-toggle {
  color: #fff;
  background-color: #000e4e;
  border-color: #000e4e;
}

.btn-outline-blue-900:not(:disabled):not(.disabled).active:focus,
.btn-outline-blue-900:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-blue-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 14, 78, 0.5);
}

.btn-outline-green-100 {
  color: #83ed83;
  border-color: #83ed83;
}

.btn-outline-green-100:hover {
  color: #231f20;
  background-color: #83ed83;
  border-color: #83ed83;
}

.btn-outline-green-100.focus,
.btn-outline-green-100:focus {
  box-shadow: 0 0 0 0 rgba(131, 237, 131, 0.5);
}

.btn-outline-green-100.disabled,
.btn-outline-green-100:disabled {
  color: #83ed83;
  background-color: transparent;
}

.btn-outline-green-100:not(:disabled):not(.disabled).active,
.btn-outline-green-100:not(:disabled):not(.disabled):active,
.show > .btn-outline-green-100.dropdown-toggle {
  color: #231f20;
  background-color: #83ed83;
  border-color: #83ed83;
}

.btn-outline-green-100:not(:disabled):not(.disabled).active:focus,
.btn-outline-green-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-green-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(131, 237, 131, 0.5);
}

.btn-outline-green-300 {
  color: #148750;
  border-color: #148750;
}

.btn-outline-green-300:hover {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-outline-green-300.focus,
.btn-outline-green-300:focus {
  box-shadow: 0 0 0 0 rgba(78, 168, 13, 0.5);
}

.btn-outline-green-300.disabled,
.btn-outline-green-300:disabled {
  color: #148750;
  background-color: transparent;
}

.btn-outline-green-300:not(:disabled):not(.disabled).active,
.btn-outline-green-300:not(:disabled):not(.disabled):active,
.show > .btn-outline-green-300.dropdown-toggle {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-outline-green-300:not(:disabled):not(.disabled).active:focus,
.btn-outline-green-300:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-green-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(78, 168, 13, 0.5);
}

.btn-outline-green-500 {
  color: #148750;
  border-color: #148750;
}

.btn-outline-green-500:hover {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-outline-green-500.focus,
.btn-outline-green-500:focus {
  box-shadow: 0 0 0 0 rgba(7, 137, 38, 0.5);
}

.btn-outline-green-500.disabled,
.btn-outline-green-500:disabled {
  color: #148750;
  background-color: transparent;
}

.btn-outline-green-500:not(:disabled):not(.disabled).active,
.btn-outline-green-500:not(:disabled):not(.disabled):active,
.show > .btn-outline-green-500.dropdown-toggle {
  color: #fff;
  background-color: #148750;
  border-color: #148750;
}

.btn-outline-green-500:not(:disabled):not(.disabled).active:focus,
.btn-outline-green-500:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-green-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(7, 137, 38, 0.5);
}

.btn-outline-green-700 {
  color: #116b40;
  border-color: #116b40;
}

.btn-outline-green-700:hover {
  color: #fff;
  background-color: #116b40;
  border-color: #116b40;
}

.btn-outline-green-700.focus,
.btn-outline-green-700:focus {
  box-shadow: 0 0 0 0 rgba(0, 90, 0, 0.5);
}

.btn-outline-green-700.disabled,
.btn-outline-green-700:disabled {
  color: #116b40;
  background-color: transparent;
}

.btn-outline-green-700:not(:disabled):not(.disabled).active,
.btn-outline-green-700:not(:disabled):not(.disabled):active,
.show > .btn-outline-green-700.dropdown-toggle {
  color: #fff;
  background-color: #116b40;
  border-color: #116b40;
}

.btn-outline-green-700:not(:disabled):not(.disabled).active:focus,
.btn-outline-green-700:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-green-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 90, 0, 0.5);
}

.btn-outline-green-900 {
  color: #0f6039;
  border-color: #0f6039;
}

.btn-outline-green-900:hover {
  color: #fff;
  background-color: #0f6039;
  border-color: #0f6039;
}

.btn-outline-green-900.focus,
.btn-outline-green-900:focus {
  box-shadow: 0 0 0 0 rgba(0, 47, 0, 0.5);
}

.btn-outline-green-900.disabled,
.btn-outline-green-900:disabled {
  color: #0f6039;
  background-color: transparent;
}

.btn-outline-green-900:not(:disabled):not(.disabled).active,
.btn-outline-green-900:not(:disabled):not(.disabled):active,
.show > .btn-outline-green-900.dropdown-toggle {
  color: #fff;
  background-color: #0f6039;
  border-color: #0f6039;
}

.btn-outline-green-900:not(:disabled):not(.disabled).active:focus,
.btn-outline-green-900:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-green-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 47, 0, 0.5);
}

.btn-outline-red-100 {
  color: #ff9678;
  border-color: #ff9678;
}

.btn-outline-red-100:hover {
  color: #231f20;
  background-color: #ff9678;
  border-color: #ff9678;
}

.btn-outline-red-100.focus,
.btn-outline-red-100:focus {
  box-shadow: 0 0 0 0 rgba(255, 150, 120, 0.5);
}

.btn-outline-red-100.disabled,
.btn-outline-red-100:disabled {
  color: #ff9678;
  background-color: transparent;
}

.btn-outline-red-100:not(:disabled):not(.disabled).active,
.btn-outline-red-100:not(:disabled):not(.disabled):active,
.show > .btn-outline-red-100.dropdown-toggle {
  color: #231f20;
  background-color: #ff9678;
  border-color: #ff9678;
}

.btn-outline-red-100:not(:disabled):not(.disabled).active:focus,
.btn-outline-red-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-red-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 150, 120, 0.5);
}

.btn-outline-red-300 {
  color: #ff644b;
  border-color: #ff644b;
}

.btn-outline-red-300:hover {
  color: #fff;
  background-color: #ff644b;
  border-color: #ff644b;
}

.btn-outline-red-300.focus,
.btn-outline-red-300:focus {
  box-shadow: 0 0 0 0 rgba(255, 100, 75, 0.5);
}

.btn-outline-red-300.disabled,
.btn-outline-red-300:disabled {
  color: #ff644b;
  background-color: transparent;
}

.btn-outline-red-300:not(:disabled):not(.disabled).active,
.btn-outline-red-300:not(:disabled):not(.disabled):active,
.show > .btn-outline-red-300.dropdown-toggle {
  color: #fff;
  background-color: #ff644b;
  border-color: #ff644b;
}

.btn-outline-red-300:not(:disabled):not(.disabled).active:focus,
.btn-outline-red-300:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-red-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 100, 75, 0.5);
}

.btn-outline-red-500 {
  color: #ea123c;
  border-color: #ea123c;
}

.btn-outline-red-500:hover {
  color: #fff;
  background-color: #ea123c;
  border-color: #ea123c;
}

.btn-outline-red-500.focus,
.btn-outline-red-500:focus {
  box-shadow: 0 0 0 0 rgba(228, 41, 32, 0.5);
}

.btn-outline-red-500.disabled,
.btn-outline-red-500:disabled {
  color: #ea123c;
  background-color: transparent;
}

.btn-outline-red-500:not(:disabled):not(.disabled).active,
.btn-outline-red-500:not(:disabled):not(.disabled):active,
.show > .btn-outline-red-500.dropdown-toggle {
  color: #fff;
  background-color: #ea123c;
  border-color: #ea123c;
}

.btn-outline-red-500:not(:disabled):not(.disabled).active:focus,
.btn-outline-red-500:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-red-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(228, 41, 32, 0.5);
}

.btn-outline-red-700 {
  color: #a90000;
  border-color: #a90000;
}

.btn-outline-red-700:hover {
  color: #fff;
  background-color: #a90000;
  border-color: #a90000;
}

.btn-outline-red-700.focus,
.btn-outline-red-700:focus {
  box-shadow: 0 0 0 0 rgba(169, 0, 0, 0.5);
}

.btn-outline-red-700.disabled,
.btn-outline-red-700:disabled {
  color: #a90000;
  background-color: transparent;
}

.btn-outline-red-700:not(:disabled):not(.disabled).active,
.btn-outline-red-700:not(:disabled):not(.disabled):active,
.show > .btn-outline-red-700.dropdown-toggle {
  color: #fff;
  background-color: #a90000;
  border-color: #a90000;
}

.btn-outline-red-700:not(:disabled):not(.disabled).active:focus,
.btn-outline-red-700:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-red-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(169, 0, 0, 0.5);
}

.btn-outline-red-900 {
  color: #730000;
  border-color: #730000;
}

.btn-outline-red-900:hover {
  color: #fff;
  background-color: #730000;
  border-color: #730000;
}

.btn-outline-red-900.focus,
.btn-outline-red-900:focus {
  box-shadow: 0 0 0 0 rgba(115, 0, 0, 0.5);
}

.btn-outline-red-900.disabled,
.btn-outline-red-900:disabled {
  color: #730000;
  background-color: transparent;
}

.btn-outline-red-900:not(:disabled):not(.disabled).active,
.btn-outline-red-900:not(:disabled):not(.disabled):active,
.show > .btn-outline-red-900.dropdown-toggle {
  color: #fff;
  background-color: #730000;
  border-color: #730000;
}

.btn-outline-red-900:not(:disabled):not(.disabled).active:focus,
.btn-outline-red-900:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-red-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(115, 0, 0, 0.5);
}

.btn-outline-background-100 {
  color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-outline-background-100:hover {
  color: #231f20;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-outline-background-100.focus,
.btn-outline-background-100:focus {
  box-shadow: 0 0 0 0 rgba(239, 243, 249, 0.5);
}

.btn-outline-background-100.disabled,
.btn-outline-background-100:disabled {
  color: #f0f0f0;
  background-color: transparent;
}

.btn-outline-background-100:not(:disabled):not(.disabled).active,
.btn-outline-background-100:not(:disabled):not(.disabled):active,
.show > .btn-outline-background-100.dropdown-toggle {
  color: #231f20;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn-outline-background-100:not(:disabled):not(.disabled).active:focus,
.btn-outline-background-100:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-background-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(239, 243, 249, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #293f96;
  text-decoration: none;
}

.btn-link:hover {
  color: #0083ad;
  text-decoration: underline !important;
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline !important;
  box-shadow: none;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #607d8b;
  pointer-events: none;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='button'].btn-block,
input[type='reset'].btn-block,
input[type='submit'].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 51;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #231f20;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  display: none;
}

.dropleft .dropdown-toggle:before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #cfd8dc;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #231f20;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #060e14;
  text-decoration: none;
  background-color: #eceff1;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #293f96;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #607d8b;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #607d8b;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #231f20;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  // z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split:after,
.dropright .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  // z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  // z-index: 4;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append,
.input-group-prepend {
  display: flex;
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  // z-index: 2;
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  // z-index: 3;
}

.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231f20;
  text-align: center;
  white-space: nowrap;
  background-color: #cfd8dc;
  border: 1px solid #c1c8cc;
  border-radius: 0.5rem;
}

.input-group-text input[type='checkbox'],
.input-group-text input[type='radio'] {
  margin-top: 0;
}

.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  // z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #148750;
  border-color: #293f96;
  background-color: #fff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 2px #00b2e3;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #c1c8cc;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  color: #fff;
  background-color: #74b5f5;
  border-color: #74b5f5;
}

.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  color: #607d8b;
}

.custom-control-input:disabled ~ .custom-control-label:before,
.custom-control-input[disabled] ~ .custom-control-label:before {
  background-color: #cfd8dc;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label:before {
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #78909c;
}

.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
}

.custom-control-label:after {
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 0.5rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23078926' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  border-color: #293f96;
  background-color: #293f96;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4'%3E%3Cpath stroke='%23078926' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: rgba(12, 92, 171, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: rgba(12, 92, 171, 0.5);
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23078926'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: rgba(12, 92, 171, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label:before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label:after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #78909c;
  border-radius: 0.5rem;
  transition:
    transform 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: rgba(12, 92, 171, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #231f20;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23455A64' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #c1c8cc;
  border-radius: 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #c1c8cc;
  outline: 0;
  box-shadow: 0 0 0 0 transparent;
}

.custom-select:focus::-ms-value {
  color: #231f20;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #607d8b;
  background-color: #cfd8dc;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #231f20;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  display: inline-block;
  margin-bottom: 0;
}

.custom-file,
.custom-file-input {
  position: relative;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}

.custom-file-input {
  // z-index: 2;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #c1c8cc;
  box-shadow: 0 0 0 2px #00b2e3;
}

.custom-file-input:disabled ~ .custom-file-label,
.custom-file-input[disabled] ~ .custom-file-label {
  background-color: #cfd8dc;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: 'Browse';
}

.custom-file-input ~ .custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  left: 0;
  // z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #c1c8cc;
  border-radius: 0.5rem;
}

.custom-file-label,
.custom-file-label:after {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #231f20;
}

.custom-file-label:after {
  bottom: 0;
  // z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  content: 'Browse';
  background-color: #cfd8dc;
  border-left: inherit;
  border-radius: 0 0.5rem 0.5rem 0;
}

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow:
    0 0 0 1px #f0f0f0,
    0 0 0 2px #00b2e3;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow:
    0 0 0 1px #f0f0f0,
    0 0 0 2px #00b2e3;
}

.custom-range:focus::-ms-thumb {
  box-shadow:
    0 0 0 1px #f0f0f0,
    0 0 0 2px #00b2e3;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #293f96;
  border: 0;
  border-radius: 1rem;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #74b5f5;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #cfd8dc;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #293f96;
  border: 0;
  border-radius: 1rem;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #74b5f5;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #cfd8dc;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #293f96;
  border: 0;
  border-radius: 1rem;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #74b5f5;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower,
.custom-range::-ms-fill-upper {
  background-color: #cfd8dc;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #78909c;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #78909c;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #78909c;
}

.custom-control-label:before,
.custom-file-label,
.custom-select {
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label:before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #607d8b;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #cfd8dc;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #cfd8dc;
}

.nav-tabs .nav-link.disabled {
  color: #607d8b;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #65798f;
  background-color: #fff;
  border-color: #cfd8dc #cfd8dc #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.5rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #293f96;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;

  &[hidden] {
    display: none;
  }
}

.navbar {
  position: relative;
  padding: 0.5rem 1rem;
}

.navbar,
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat 50%;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: hsla(0, 0%, 100%, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: hsla(0, 0%, 100%, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text,
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px);
}

.card-header-tabs {
  margin-bottom: -0.75rem;
  border-bottom: 0;
}

.card-header-pills,
.card-header-tabs {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-bottom,
.card-img-top {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}

.card-deck .card {
  margin-bottom: 16px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -16px;
    margin-left: -16px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 16px;
    margin-bottom: 0;
    margin-left: 16px;
  }
}

.card-group > .card {
  margin-bottom: 16px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #cfd8dc;
  border-radius: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #607d8b;
  content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: underline;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #607d8b;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.5rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #293f96;
  background-color: #fff;
  border: 1px solid #b0bec5;
}

.page-link:hover {
  // z-index: 2;
  color: #165c96;
  text-decoration: none;
  background-color: #cfd8dc;
  border-color: #b0bec5;
}

.page-link:focus {
  // z-index: 3;
  outline: 0;
  box-shadow: none;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.page-item.active .page-link {
  // z-index: 3;
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.page-item.disabled .page-link {
  color: #607d8b;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #b0bec5;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #293f96;
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #09427b;
}

a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(12, 92, 171, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #607d8b;
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #4b626d;
}

a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(96, 125, 139, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #148750;
}

a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #11220a;
}

a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(37, 74, 21, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #293f96;
}

a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #09427b;
}

a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(12, 92, 171, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #c73800;
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: #fff;
  background-color: #2d1c05;
}

a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(91, 57, 10, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ab0a3d;
}

a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #4f0504;
}

a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(128, 8, 6, 0.5);
}

.badge-light {
  color: #231f20;
  background-color: #f0f0f0;
}

a.badge-light:focus,
a.badge-light:hover {
  color: #231f20;
  background-color: #b1d4ff;
}

a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(228, 240, 255, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #455a64;
}

a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #303f46;
}

a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(69, 90, 100, 0.5);
}

.badge-info-100 {
  color: #231f20;
  background-color: #e5ebf1;
}

a.badge-info-100:focus,
a.badge-info-100:hover {
  color: #231f20;
  background-color: #a1d5ed;
}

a.badge-info-100.focus,
a.badge-info-100:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(204, 232, 245, 0.5);
}

.badge-warning-100 {
  color: #231f20;
  background-color: #f3ebe0;
}

a.badge-warning-100:focus,
a.badge-warning-100:hover {
  color: #231f20;
  background-color: #f1e6aa;
}

a.badge-warning-100.focus,
a.badge-warning-100:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(248, 243, 214, 0.5);
}

.badge-danger-100 {
  color: #231f20;
  background-color: #f1e0e3;
}

a.badge-danger-100:focus,
a.badge-danger-100:hover {
  color: #231f20;
  background-color: #edb0aa;
}

a.badge-danger-100.focus,
a.badge-danger-100:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(246, 215, 212, 0.5);
}

.badge-success-100 {
  color: #231f20;
  background-color: #e1f0e8;
}

a.badge-success-100:focus,
a.badge-success-100:hover {
  color: #231f20;
  background-color: #bfe6ae;
}

a.badge-success-100.focus,
a.badge-success-100:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(222, 242, 213, 0.5);
}

.badge-gray {
  color: #fff;
  background-color: #607d8b;
}

a.badge-gray:focus,
a.badge-gray:hover {
  color: #fff;
  background-color: #4b626d;
}

a.badge-gray.focus,
a.badge-gray:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(96, 125, 139, 0.5);
}

.badge-gray-dark {
  color: #fff;
  background-color: #65798f;
}

a.badge-gray-dark:focus,
a.badge-gray-dark:hover {
  color: #fff;
  background-color: #455567;
}

a.badge-gray-dark.focus,
a.badge-gray-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(90, 110, 133, 0.5);
}

.badge-blue-100 {
  color: #231f20;
  background-color: #00b2e3;
}

a.badge-blue-100:focus,
a.badge-blue-100:hover {
  color: #231f20;
  background-color: #5996ff;
}

a.badge-blue-100.focus,
a.badge-blue-100:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(140, 182, 255, 0.5);
}

.badge-blue-300 {
  color: #fff;
  background-color: #5687dd;
}

a.badge-blue-300:focus,
a.badge-blue-300:hover {
  color: #fff;
  background-color: #2c69d4;
}

a.badge-blue-300.focus,
a.badge-blue-300:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(86, 135, 221, 0.5);
}

.badge-blue-500 {
  color: #fff;
  background-color: #293f96;
}

a.badge-blue-500:focus,
a.badge-blue-500:hover {
  color: #fff;
  background-color: #09427b;
}

a.badge-blue-500.focus,
a.badge-blue-500:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(12, 92, 171, 0.5);
}

.badge-blue-700 {
  color: #fff;
  background-color: #113c78;
}

a.badge-blue-700:focus,
a.badge-blue-700:hover {
  color: #fff;
  background-color: #0b264b;
}

a.badge-blue-700.focus,
a.badge-blue-700:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(17, 60, 120, 0.5);
}

.badge-blue-900 {
  color: #fff;
  background-color: #000e4e;
}

a.badge-blue-900:focus,
a.badge-blue-900:hover {
  color: #fff;
  background-color: #00051b;
}

a.badge-blue-900.focus,
a.badge-blue-900:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 14, 78, 0.5);
}

.badge-green-100 {
  color: #231f20;
  background-color: #83ed83;
}

a.badge-green-100:focus,
a.badge-green-100:hover {
  color: #231f20;
  background-color: #56e756;
}

a.badge-green-100.focus,
a.badge-green-100:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(131, 237, 131, 0.5);
}

.badge-green-300 {
  color: #fff;
  background-color: #148750;
}

a.badge-green-300:focus,
a.badge-green-300:hover {
  color: #fff;
  background-color: #387909;
}

a.badge-green-300.focus,
a.badge-green-300:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(78, 168, 13, 0.5);
}

.badge-green-500 {
  color: #fff;
  background-color: #148750;
}

a.badge-green-500:focus,
a.badge-green-500:hover {
  color: #fff;
  background-color: #055819;
}

a.badge-green-500.focus,
a.badge-green-500:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(7, 137, 38, 0.5);
}

.badge-green-700 {
  color: #fff;
  background-color: #116b40;
}

a.badge-green-700:focus,
a.badge-green-700:hover {
  color: #fff;
  background-color: #002700;
}

a.badge-green-700.focus,
a.badge-green-700:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 90, 0, 0.5);
}

.badge-green-900 {
  color: #fff;
  background-color: #0f6039;
}

a.badge-green-900:focus,
a.badge-green-900:hover {
  color: #fff;
  background-color: #231f20;
}

a.badge-green-900.focus,
a.badge-green-900:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 47, 0, 0.5);
}

.badge-red-100 {
  color: #231f20;
  background-color: #ff9678;
}

a.badge-red-100:focus,
a.badge-red-100:hover {
  color: #231f20;
  background-color: #ff6e45;
}

a.badge-red-100.focus,
a.badge-red-100:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 150, 120, 0.5);
}

.badge-red-300 {
  color: #fff;
  background-color: #ff644b;
}

a.badge-red-300:focus,
a.badge-red-300:hover {
  color: #fff;
  background-color: #ff3818;
}

a.badge-red-300.focus,
a.badge-red-300:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(255, 100, 75, 0.5);
}

.badge-red-500 {
  color: #fff;
  background-color: #ea123c;
}

a.badge-red-500:focus,
a.badge-red-500:hover {
  color: #fff;
  background-color: #ba1e17;
}

a.badge-red-500.focus,
a.badge-red-500:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(228, 41, 32, 0.5);
}

.badge-red-700 {
  color: #fff;
  background-color: #a90000;
}

a.badge-red-700:focus,
a.badge-red-700:hover {
  color: #fff;
  background-color: #760000;
}

a.badge-red-700.focus,
a.badge-red-700:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(169, 0, 0, 0.5);
}

.badge-red-900 {
  color: #fff;
  background-color: #730000;
}

a.badge-red-900:focus,
a.badge-red-900:hover {
  color: #fff;
  background-color: #400000;
}

a.badge-red-900.focus,
a.badge-red-900:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(115, 0, 0, 0.5);
}

.badge-background-100 {
  color: #231f20;
  background-color: #f0f0f0;
}

a.badge-background-100:focus,
a.badge-background-100:hover {
  color: #231f20;
  background-color: #e5ebf1;
}

a.badge-background-100.focus,
a.badge-background-100:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(239, 243, 249, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #cfd8dc;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid;
  border-radius: 0.5rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #063059;
  background-color: #cedeee;
  border-color: #bbd1e7;
}

.alert-primary hr {
  border-top-color: #a8c4e0;
}

.alert-primary .alert-link {
  color: #031629;
}

.alert-secondary {
  color: #324148;
  background-color: #dfe5e8;
  border-color: #d2dbdf;
}

.alert-secondary hr {
  border-top-color: #c3cfd4;
}

.alert-secondary .alert-link {
  color: #1d262a;
}

.alert-success hr {
  border-top-color: #b5c1af;
}

.alert-success .alert-link {
  color: #231f20;
}

.alert-info hr {
  border-top-color: #a8c4e0;
}

.alert-info .alert-link {
  color: #031629;
}

.alert-warning hr {
  border-top-color: #c7bcab;
}

.alert-warning .alert-link {
  color: #010100;
}

.alert-danger hr {
  border-top-color: #d2a9a8;
}

.alert-danger .alert-link {
  color: #120101;
}

.alert-light {
  color: #777d85;
  background-color: #fafcff;
  border-color: #f7fbff;
}

.alert-light hr {
  border-top-color: #deeeff;
}

.alert-light .alert-link {
  color: #5f646a;
}

.alert-dark {
  color: #242f34;
  background-color: #dadee0;
  border-color: #cbd1d4;
}

.alert-dark hr {
  border-top-color: #bdc5c8;
}

.alert-dark .alert-link {
  color: #0f1416;
}

.alert-info-100 {
  color: #6a797f;
  background-color: #f5fafd;
  border-color: #f1f9fc;
}

.alert-info-100 hr {
  border-top-color: #dcf0f8;
}

.alert-info-100 .alert-link {
  color: #535f63;
}

.alert-warning-100 {
  color: #817e6f;
  background-color: #fefdf7;
  border-color: #fdfcf4;
}

.alert-warning-100 hr {
  border-top-color: #f9f6de;
}

.alert-warning-100 .alert-link {
  color: #666357;
}

.alert-danger-100 {
  color: #80706e;
  background-color: #fdf7f6;
  border-color: #fcf4f3;
}

.alert-danger-100 hr {
  border-top-color: #f7e1df;
}

.alert-danger-100 .alert-link {
  color: #655856;
}

.alert-success-100 {
  color: #737e6f;
  background-color: #f8fcf7;
  border-color: #f6fbf3;
}

.alert-success-100 hr {
  border-top-color: #e8f5e0;
}

.alert-success-100 .alert-link {
  color: #5a6357;
}

.alert-gray {
  color: #324148;
  background-color: #dfe5e8;
  border-color: #d2dbdf;
}

.alert-gray hr {
  border-top-color: #c3cfd4;
}

.alert-gray .alert-link {
  color: #1d262a;
}

.alert-gray-dark {
  color: #2f3945;
  background-color: #dee2e7;
  border-color: #d1d6dd;
}

.alert-gray-dark hr {
  border-top-color: #c2c9d2;
}

.alert-gray-dark .alert-link {
  color: #1a2027;
}

.alert-blue-100 {
  color: #495f85;
  background-color: #e8f0ff;
  border-color: #dfebff;
}

.alert-blue-100 hr {
  border-top-color: #c6dbff;
}

.alert-blue-100 .alert-link {
  color: #374764;
}

.alert-blue-300 {
  color: #2d4673;
  background-color: #dde7f8;
  border-color: #d0ddf5;
}

.alert-blue-300 hr {
  border-top-color: #bbcef1;
}

.alert-blue-300 .alert-link {
  color: #1f304e;
}

.alert-blue-500 {
  color: #063059;
  background-color: #cedeee;
  border-color: #bbd1e7;
}

.alert-blue-500 hr {
  border-top-color: #a8c4e0;
}

.alert-blue-500 .alert-link {
  color: #031629;
}

.alert-blue-700 {
  color: #091f3e;
  background-color: #cfd8e4;
  border-color: #bcc8d9;
}

.alert-blue-700 hr {
  border-top-color: #acbbd0;
}

.alert-blue-700 .alert-link {
  color: #030911;
}

.alert-blue-900 {
  color: #000729;
  background-color: #cccfdc;
  border-color: #b8bccd;
}

.alert-blue-900 hr {
  border-top-color: #a9aec2;
}

.alert-blue-900 .alert-link {
  color: #231f20;
}

.alert-green-100 {
  color: #447b44;
  background-color: #e6fbe6;
  border-color: #dcfadc;
}

.alert-green-100 hr {
  border-top-color: #c6f7c6;
}

.alert-green-100 .alert-link {
  color: #325a32;
}

.alert-green-300 {
  color: #295707;
  background-color: #dceecf;
  border-color: #cde7bb;
}

.alert-green-300 hr {
  border-top-color: #bfe0a8;
}

.alert-green-300 .alert-link {
  color: #132803;
}

.alert-green-500 {
  color: #044714;
  background-color: #cde7d4;
  border-color: #badec2;
}

.alert-green-500 hr {
  border-top-color: #a9d6b3;
}

.alert-green-500 .alert-link {
  color: #011706;
}

.alert-green-700 {
  color: #0f6039;
  background-color: #ccdecc;
  border-color: #b8d1b8;
}

.alert-green-700 hr {
  border-top-color: #a9c7a9;
}

.alert-green-700 .alert-link {
  color: #231f20;
}

.alert-green-900 {
  color: #001800;
  background-color: #ccd5cc;
  border-color: #b8c5b8;
}

.alert-green-900 hr {
  border-top-color: #aabaaa;
}

.alert-green-900 .alert-link {
  color: #231f20;
}

.alert-red-100 {
  color: #854e3e;
  background-color: #ffeae4;
  border-color: #ffe2d9;
}

.alert-red-100 hr {
  border-top-color: #ffcfc0;
}

.alert-red-100 .alert-link {
  color: #623a2e;
}

.alert-red-300 {
  color: #853427;
  background-color: #ffe0db;
  border-color: #ffd4cd;
}

.alert-red-300 hr {
  border-top-color: #ffbeb4;
}

.alert-red-300 .alert-link {
  color: #5e251b;
}

.alert-red-500 {
  color: #771511;
  background-color: #fad4d2;
  border-color: #f7c3c1;
}

.alert-red-500 hr {
  border-top-color: #f4adaa;
}

.alert-red-500 .alert-link {
  color: #4a0d0b;
}

.alert-red-700 {
  color: #580000;
  background-color: #ecc;
  border-color: #e7b8b8;
}

.alert-red-700 hr {
  border-top-color: #e1a5a5;
}

.alert-red-700 .alert-link {
  color: #250000;
}

.alert-red-900 {
  color: #3c0000;
  background-color: #e3cccc;
  border-color: #d8b8b8;
}

.alert-red-900 hr {
  border-top-color: #cfa8a8;
}

.alert-red-900 .alert-link {
  color: #090000;
}

.alert-background-100 {
  color: #7c7e81;
  background-color: #fcfdfe;
  border-color: #fbfcfd;
}

.alert-background-100 hr {
  border-top-color: #eaeff5;
}

.alert-background-100 .alert-link {
  color: #636567;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 1rem;
  font-size: 0.75rem;
  background-color: #cfd8dc;
  border-radius: 0.5rem;
}

.progress,
.progress-bar {
  display: flex;
  overflow: hidden;
}

.progress-bar {
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #293f96;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #65798f;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  // z-index: 1;
  color: #65798f;
  text-decoration: none;
  background-color: #eceff1;
}

.list-group-item-action:active {
  color: #231f20;
  background-color: #cfd8dc;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #607d8b;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  // z-index: 2;
  color: #fff;
  background-color: #293f96;
  border-color: #293f96;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #063059;
  background-color: #bbd1e7;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #063059;
  background-color: #a8c4e0;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #063059;
  border-color: #063059;
}

.list-group-item-secondary {
  color: #324148;
  background-color: #d2dbdf;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #324148;
  background-color: #c3cfd4;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #324148;
  border-color: #324148;
}

.list-group-item-success {
  color: #13260b;
  background-color: #c2ccbd;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #13260b;
  background-color: #b5c1af;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #13260b;
  border-color: #13260b;
}

.list-group-item-info {
  color: #063059;
  background-color: #bbd1e7;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #063059;
  background-color: #a8c4e0;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #063059;
  border-color: #063059;
}

.list-group-item-warning {
  color: #2f1e05;
  background-color: #d1c8ba;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #2f1e05;
  background-color: #c7bcab;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #2f1e05;
  border-color: #2f1e05;
}

.list-group-item-danger {
  color: #430403;
  background-color: #dbbab9;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #430403;
  background-color: #d2a9a8;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #430403;
  border-color: #430403;
}

.list-group-item-light {
  color: #777d85;
  background-color: #f7fbff;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #777d85;
  background-color: #deeeff;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #777d85;
  border-color: #777d85;
}

.list-group-item-dark {
  color: #242f34;
  background-color: #cbd1d4;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #242f34;
  background-color: #bdc5c8;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #242f34;
  border-color: #242f34;
}

.list-group-item-info-100 {
  color: #6a797f;
  background-color: #f1f9fc;
}

.list-group-item-info-100.list-group-item-action:focus,
.list-group-item-info-100.list-group-item-action:hover {
  color: #6a797f;
  background-color: #dcf0f8;
}

.list-group-item-info-100.list-group-item-action.active {
  color: #fff;
  background-color: #6a797f;
  border-color: #6a797f;
}

.list-group-item-warning-100 {
  color: #817e6f;
  background-color: #fdfcf4;
}

.list-group-item-warning-100.list-group-item-action:focus,
.list-group-item-warning-100.list-group-item-action:hover {
  color: #817e6f;
  background-color: #f9f6de;
}

.list-group-item-warning-100.list-group-item-action.active {
  color: #fff;
  background-color: #817e6f;
  border-color: #817e6f;
}

.list-group-item-danger-100 {
  color: #80706e;
  background-color: #fcf4f3;
}

.list-group-item-danger-100.list-group-item-action:focus,
.list-group-item-danger-100.list-group-item-action:hover {
  color: #80706e;
  background-color: #f7e1df;
}

.list-group-item-danger-100.list-group-item-action.active {
  color: #fff;
  background-color: #80706e;
  border-color: #80706e;
}

.list-group-item-success-100 {
  color: #737e6f;
  background-color: #f6fbf3;
}

.list-group-item-success-100.list-group-item-action:focus,
.list-group-item-success-100.list-group-item-action:hover {
  color: #737e6f;
  background-color: #e8f5e0;
}

.list-group-item-success-100.list-group-item-action.active {
  color: #fff;
  background-color: #737e6f;
  border-color: #737e6f;
}

.list-group-item-gray {
  color: #324148;
  background-color: #d2dbdf;
}

.list-group-item-gray.list-group-item-action:focus,
.list-group-item-gray.list-group-item-action:hover {
  color: #324148;
  background-color: #c3cfd4;
}

.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #324148;
  border-color: #324148;
}

.list-group-item-gray-dark {
  color: #2f3945;
  background-color: #d1d6dd;
}

.list-group-item-gray-dark.list-group-item-action:focus,
.list-group-item-gray-dark.list-group-item-action:hover {
  color: #2f3945;
  background-color: #c2c9d2;
}

.list-group-item-gray-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2f3945;
  border-color: #2f3945;
}

.list-group-item-blue-100 {
  color: #495f85;
  background-color: #dfebff;
}

.list-group-item-blue-100.list-group-item-action:focus,
.list-group-item-blue-100.list-group-item-action:hover {
  color: #495f85;
  background-color: #c6dbff;
}

.list-group-item-blue-100.list-group-item-action.active {
  color: #fff;
  background-color: #495f85;
  border-color: #495f85;
}

.list-group-item-blue-300 {
  color: #2d4673;
  background-color: #d0ddf5;
}

.list-group-item-blue-300.list-group-item-action:focus,
.list-group-item-blue-300.list-group-item-action:hover {
  color: #2d4673;
  background-color: #bbcef1;
}

.list-group-item-blue-300.list-group-item-action.active {
  color: #fff;
  background-color: #2d4673;
  border-color: #2d4673;
}

.list-group-item-blue-500 {
  color: #063059;
  background-color: #bbd1e7;
}

.list-group-item-blue-500.list-group-item-action:focus,
.list-group-item-blue-500.list-group-item-action:hover {
  color: #063059;
  background-color: #a8c4e0;
}

.list-group-item-blue-500.list-group-item-action.active {
  color: #fff;
  background-color: #063059;
  border-color: #063059;
}

.list-group-item-blue-700 {
  color: #091f3e;
  background-color: #bcc8d9;
}

.list-group-item-blue-700.list-group-item-action:focus,
.list-group-item-blue-700.list-group-item-action:hover {
  color: #091f3e;
  background-color: #acbbd0;
}

.list-group-item-blue-700.list-group-item-action.active {
  color: #fff;
  background-color: #091f3e;
  border-color: #091f3e;
}

.list-group-item-blue-900 {
  color: #000729;
  background-color: #b8bccd;
}

.list-group-item-blue-900.list-group-item-action:focus,
.list-group-item-blue-900.list-group-item-action:hover {
  color: #000729;
  background-color: #a9aec2;
}

.list-group-item-blue-900.list-group-item-action.active {
  color: #fff;
  background-color: #000729;
  border-color: #000729;
}

.list-group-item-green-100 {
  color: #447b44;
  background-color: #dcfadc;
}

.list-group-item-green-100.list-group-item-action:focus,
.list-group-item-green-100.list-group-item-action:hover {
  color: #447b44;
  background-color: #c6f7c6;
}

.list-group-item-green-100.list-group-item-action.active {
  color: #fff;
  background-color: #447b44;
  border-color: #447b44;
}

.list-group-item-green-300 {
  color: #295707;
  background-color: #cde7bb;
}

.list-group-item-green-300.list-group-item-action:focus,
.list-group-item-green-300.list-group-item-action:hover {
  color: #295707;
  background-color: #bfe0a8;
}

.list-group-item-green-300.list-group-item-action.active {
  color: #fff;
  background-color: #295707;
  border-color: #295707;
}

.list-group-item-green-500 {
  color: #044714;
  background-color: #badec2;
}

.list-group-item-green-500.list-group-item-action:focus,
.list-group-item-green-500.list-group-item-action:hover {
  color: #044714;
  background-color: #a9d6b3;
}

.list-group-item-green-500.list-group-item-action.active {
  color: #fff;
  background-color: #044714;
  border-color: #044714;
}

.list-group-item-green-700 {
  color: #0f6039;
  background-color: #b8d1b8;
}

.list-group-item-green-700.list-group-item-action:focus,
.list-group-item-green-700.list-group-item-action:hover {
  color: #0f6039;
  background-color: #a9c7a9;
}

.list-group-item-green-700.list-group-item-action.active {
  color: #fff;
  background-color: #0f6039;
  border-color: #0f6039;
}

.list-group-item-green-900 {
  color: #001800;
  background-color: #b8c5b8;
}

.list-group-item-green-900.list-group-item-action:focus,
.list-group-item-green-900.list-group-item-action:hover {
  color: #001800;
  background-color: #aabaaa;
}

.list-group-item-green-900.list-group-item-action.active {
  color: #fff;
  background-color: #001800;
  border-color: #001800;
}

.list-group-item-red-100 {
  color: #854e3e;
  background-color: #ffe2d9;
}

.list-group-item-red-100.list-group-item-action:focus,
.list-group-item-red-100.list-group-item-action:hover {
  color: #854e3e;
  background-color: #ffcfc0;
}

.list-group-item-red-100.list-group-item-action.active {
  color: #fff;
  background-color: #854e3e;
  border-color: #854e3e;
}

.list-group-item-red-300 {
  color: #853427;
  background-color: #ffd4cd;
}

.list-group-item-red-300.list-group-item-action:focus,
.list-group-item-red-300.list-group-item-action:hover {
  color: #853427;
  background-color: #ffbeb4;
}

.list-group-item-red-300.list-group-item-action.active {
  color: #fff;
  background-color: #853427;
  border-color: #853427;
}

.list-group-item-red-500 {
  color: #771511;
  background-color: #f7c3c1;
}

.list-group-item-red-500.list-group-item-action:focus,
.list-group-item-red-500.list-group-item-action:hover {
  color: #771511;
  background-color: #f4adaa;
}

.list-group-item-red-500.list-group-item-action.active {
  color: #fff;
  background-color: #771511;
  border-color: #771511;
}

.list-group-item-red-700 {
  color: #580000;
  background-color: #e7b8b8;
}

.list-group-item-red-700.list-group-item-action:focus,
.list-group-item-red-700.list-group-item-action:hover {
  color: #580000;
  background-color: #e1a5a5;
}

.list-group-item-red-700.list-group-item-action.active {
  color: #fff;
  background-color: #580000;
  border-color: #580000;
}

.list-group-item-red-900 {
  color: #3c0000;
  background-color: #d8b8b8;
}

.list-group-item-red-900.list-group-item-action:focus,
.list-group-item-red-900.list-group-item-action:hover {
  color: #3c0000;
  background-color: #cfa8a8;
}

.list-group-item-red-900.list-group-item-action.active {
  color: #fff;
  background-color: #3c0000;
  border-color: #3c0000;
}

.list-group-item-background-100 {
  color: #7c7e81;
  background-color: #fbfcfd;
}

.list-group-item-background-100.list-group-item-action:focus,
.list-group-item-background-100.list-group-item-action:hover {
  color: #7c7e81;
  background-color: #eaeff5;
}

.list-group-item-background-100.list-group-item-action.active {
  color: #fff;
  background-color: #7c7e81;
  border-color: #7c7e81;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #231f20;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #231f20;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #607d8b;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translateY(-50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered:before {
  display: block;
  height: calc(100vh - 1rem);
  content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #2a394f;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e1e8ee;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e1e8ee;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow:before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^='top'],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^='top'] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^='top'] .arrow:before,
.bs-tooltip-top .arrow:before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #231f20;
}

.bs-tooltip-auto[x-placement^='right'],
.bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^='right'] .arrow:before,
.bs-tooltip-right .arrow:before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #231f20;
}

.bs-tooltip-auto[x-placement^='bottom'],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow:before,
.bs-tooltip-bottom .arrow:before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #231f20;
}

.bs-tooltip-auto[x-placement^='left'],
.bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^='left'] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^='left'] .arrow:before,
.bs-tooltip-left .arrow:before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #231f20;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #231f20;
  border-radius: 0.5rem;
}

.popover {
  top: 0;
  left: 0;
  z-index: 1060;
  max-width: 276px;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover,
.popover .arrow {
  position: absolute;
  display: block;
}

.popover .arrow {
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow:after,
.popover .arrow:before {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^='top'],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^='top'] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='top'] > .arrow:before,
.bs-popover-top > .arrow:before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^='top'] > .arrow:after,
.bs-popover-top > .arrow:after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^='right'],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^='right'] > .arrow,
.bs-popover-right > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^='right'] > .arrow:before,
.bs-popover-right > .arrow:before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^='right'] > .arrow:after,
.bs-popover-right > .arrow:after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^='bottom'],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^='bottom'] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='bottom'] > .arrow:before,
.bs-popover-bottom > .arrow:before {
  top: 0;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^='bottom'] > .arrow:after,
.bs-popover-bottom > .arrow:after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^='bottom'] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^='left'],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^='left'] > .arrow,
.bs-popover-left > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^='left'] > .arrow:before,
.bs-popover-left > .arrow:before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^='left'] > .arrow:after,
.bs-popover-left > .arrow:after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #231f20;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 10px;
}

.carousel-inner:after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  // z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  // z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  // z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators button {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators button {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  // z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.bg-primary {
  background-color: #293f96;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #09427b;
}

.bg-secondary {
  background-color: #607d8b;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #4b626d;
}

.bg-success {
  background-color: #148750;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #11220a;
}

.bg-info {
  background-color: #293f96;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #09427b;
}

.bg-warning {
  background-color: #c73800;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #2d1c05;
}

.bg-danger {
  background-color: #ab0a3d;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #4f0504;
}

.bg-light {
  background-color: #f4f5ff;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #b1d4ff;
}

.bg-dark {
  background-color: #455a64;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #303f46;
}

.bg-info-100 {
  background-color: #e5ebf1;
}

a.bg-info-100:focus,
a.bg-info-100:hover,
button.bg-info-100:focus,
button.bg-info-100:hover {
  background-color: #a1d5ed;
}

.bg-warning-100 {
  background-color: #f3ebe0;
}

a.bg-warning-100:focus,
a.bg-warning-100:hover,
button.bg-warning-100:focus,
button.bg-warning-100:hover {
  background-color: #f1e6aa;
}

.bg-danger-100 {
  background-color: #f1e0e3;
}

a.bg-danger-100:focus,
a.bg-danger-100:hover,
button.bg-danger-100:focus,
button.bg-danger-100:hover {
  background-color: #edb0aa;
}

.bg-success-100 {
  background-color: #e1f0e8;
}

a.bg-success-100:focus,
a.bg-success-100:hover,
button.bg-success-100:focus,
button.bg-success-100:hover {
  background-color: #bfe6ae;
}

.bg-gray {
  background-color: #607d8b;
}

a.bg-gray:focus,
a.bg-gray:hover,
button.bg-gray:focus,
button.bg-gray:hover {
  background-color: #4b626d;
}

.bg-gray-dark {
  background-color: #65798f;
}

a.bg-gray-dark:focus,
a.bg-gray-dark:hover,
button.bg-gray-dark:focus,
button.bg-gray-dark:hover {
  background-color: #455567;
}

.bg-blue-100 {
  background-color: #00b2e3;
}

a.bg-blue-100:focus,
a.bg-blue-100:hover,
button.bg-blue-100:focus,
button.bg-blue-100:hover {
  background-color: #5996ff;
}

.bg-blue-300 {
  background-color: #5687dd;
}

a.bg-blue-300:focus,
a.bg-blue-300:hover,
button.bg-blue-300:focus,
button.bg-blue-300:hover {
  background-color: #2c69d4;
}

.bg-blue-500 {
  background-color: #293f96;
}

a.bg-blue-500:focus,
a.bg-blue-500:hover,
button.bg-blue-500:focus,
button.bg-blue-500:hover {
  background-color: #09427b;
}

.bg-blue-700 {
  background-color: #113c78;
}

a.bg-blue-700:focus,
a.bg-blue-700:hover,
button.bg-blue-700:focus,
button.bg-blue-700:hover {
  background-color: #0b264b;
}

.bg-blue-900 {
  background-color: #000e4e;
}

a.bg-blue-900:focus,
a.bg-blue-900:hover,
button.bg-blue-900:focus,
button.bg-blue-900:hover {
  background-color: #00051b;
}

.bg-green-100 {
  background-color: #83ed83;
}

a.bg-green-100:focus,
a.bg-green-100:hover,
button.bg-green-100:focus,
button.bg-green-100:hover {
  background-color: #56e756;
}

.bg-green-300 {
  background-color: #148750;
}

a.bg-green-300:focus,
a.bg-green-300:hover,
button.bg-green-300:focus,
button.bg-green-300:hover {
  background-color: #387909;
}

.bg-green-500 {
  background-color: #148750;
}

a.bg-green-500:focus,
a.bg-green-500:hover,
button.bg-green-500:focus,
button.bg-green-500:hover {
  background-color: #055819;
}

.bg-green-700 {
  background-color: #116b40;
}

a.bg-green-700:focus,
a.bg-green-700:hover,
button.bg-green-700:focus,
button.bg-green-700:hover {
  background-color: #002700;
}

.bg-green-900 {
  background-color: #0f6039;
}

a.bg-green-900:focus,
a.bg-green-900:hover,
button.bg-green-900:focus,
button.bg-green-900:hover {
  background-color: #231f20;
}

.bg-red-100 {
  background-color: #ff9678;
}

a.bg-red-100:focus,
a.bg-red-100:hover,
button.bg-red-100:focus,
button.bg-red-100:hover {
  background-color: #ff6e45;
}

.bg-red-300 {
  background-color: #ff644b;
}

a.bg-red-300:focus,
a.bg-red-300:hover,
button.bg-red-300:focus,
button.bg-red-300:hover {
  background-color: #ff3818;
}

.bg-red-500 {
  background-color: #ea123c;
}

a.bg-red-500:focus,
a.bg-red-500:hover,
button.bg-red-500:focus,
button.bg-red-500:hover {
  background-color: #ba1e17;
}

.bg-red-700 {
  background-color: #a90000;
}

a.bg-red-700:focus,
a.bg-red-700:hover,
button.bg-red-700:focus,
button.bg-red-700:hover {
  background-color: #760000;
}

.bg-red-900 {
  background-color: #730000;
}

a.bg-red-900:focus,
a.bg-red-900:hover,
button.bg-red-900:focus,
button.bg-red-900:hover {
  background-color: #400000;
}

.bg-background-100 {
  background-color: #f0f0f0;
}

a.bg-background-100:focus,
a.bg-background-100:hover,
button.bg-background-100:focus,
button.bg-background-100:hover {
  background-color: #e5ebf1;
}

.bg-white {
  background-color: #fff;
}

.bg-transparent {
  background-color: transparent;
}

.border {
  border: 1px solid #e1e8ee;
}

.border-top {
  border-top: 1px solid #e1e8ee;
}

.border-right {
  border-right: 1px solid #e1e8ee;
}

.border-bottom {
  border-bottom: 1px solid #e1e8ee;
}

.border-left {
  border-left: 1px solid #e1e8ee;
}

.border-top-0 {
  border-top: 0;
}

.border-right-0 {
  border-right: 0;
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-left-0 {
  border-left: 0;
}

.border-primary {
  border-color: #293f96;
}

.border-secondary {
  border-color: #607d8b;
}

.border-success {
  border-color: #148750;
}

.border-info {
  border-color: #293f96;
}

.border-warning {
  border-color: #c73800;
}

.border-danger {
  border-color: #ab0a3d;
}

.border-light {
  border-color: #f0f0f0;
}

.border-dark {
  border-color: #455a64;
}

.border-info-100 {
  border-color: #e5ebf1;
}

.border-warning-100 {
  border-color: #f3ebe0;
}

.border-danger-100 {
  border-color: #f1e0e3;
}

.border-success-100 {
  border-color: #e1f0e8;
}

.border-gray {
  border-color: #607d8b;
}

.border-gray-dark {
  border-color: #65798f;
}

.border-blue-100 {
  border-color: #00b2e3;
}

.border-blue-300 {
  border-color: #5687dd;
}

.border-blue-500 {
  border-color: #293f96;
}

.border-blue-700 {
  border-color: #113c78;
}

.border-blue-900 {
  border-color: #000e4e;
}

.border-green-100 {
  border-color: #83ed83;
}

.border-green-300 {
  border-color: #148750;
}

.border-green-500 {
  border-color: #148750;
}

.border-green-700 {
  border-color: #116b40;
}

.border-green-900 {
  border-color: #0f6039;
}

.border-red-100 {
  border-color: #ff9678;
}

.border-red-300 {
  border-color: #ff644b;
}

.border-red-500 {
  border-color: #ea123c;
}

.border-red-700 {
  border-color: #a90000;
}

.border-red-900 {
  border-color: #730000;
}

.border-background-100 {
  border-color: #f0f0f0;
}

.border-white {
  border-color: #fff;
}

.rounded-sm {
  border-radius: 0.25rem;
}

.rounded {
  border-radius: 0.5rem;
}

.rounded-top {
  border-top-left-radius: 0.5rem;
}

.rounded-right,
.rounded-top {
  border-top-right-radius: 0.5rem;
}

.rounded-bottom,
.rounded-right {
  border-bottom-right-radius: 0.5rem;
}

.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 0.5rem;
}

.rounded-left {
  border-top-left-radius: 0.5rem;
}

.rounded-lg {
  border-radius: 0.3rem;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: 50rem;
}

.rounded-0 {
  border-radius: 0;
}

.clearfix:after {
  display: block;
  clear: both;
  content: '';
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive:before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left;
  }

  .float-sm-right {
    float: right;
  }

  .float-sm-none {
    float: none;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left;
  }

  .float-md-right {
    float: right;
  }

  .float-md-none {
    float: none;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left;
  }

  .float-lg-right {
    float: right;
  }

  .float-lg-none {
    float: none;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left;
  }

  .float-xl-right {
    float: right;
  }

  .float-xl-none {
    float: none;
  }
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.fixed-top {
  top: 0;
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  // z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    // z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mw-100 {
  max-width: 100%;
}

.mh-100 {
  max-height: 100%;
}

.min-vw-100 {
  min-width: 100vw;
}

.min-vh-100 {
  min-height: 100vh;
}

.vw-100 {
  width: 100vw;
}

.vh-100 {
  height: 100vh;
}

.stretched-link:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: transparent;
}

.text-monospace {
  font-family:
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    Liberation Mono,
    Courier New,
    monospace;
}

.text-justify {
  text-align: justify;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left;
  }

  .text-sm-right {
    text-align: right;
  }

  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left;
  }

  .text-lg-right {
    text-align: right;
  }

  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left;
  }

  .text-xl-right {
    text-align: right;
  }

  .text-xl-center {
    text-align: center;
  }
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 500;
}

.font-weight-bolder {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.text-white {
  color: #fff;
}

.text-primary {
  color: #293f96;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #165c96;
}

.text-secondary {
  color: #607d8b;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #41545e;
}

.text-success {
  color: #148750;
}

a.text-success:focus,
a.text-success:hover {
  color: #070e04;
}

.text-info {
  color: #293f96;
}

a.text-info:focus,
a.text-info:hover {
  color: #165c96;
}

.text-warning {
  color: #c73800;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #160e02;
}

.text-danger {
  color: #ab0a3d;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #370303;
}

.text-light {
  color: #f0f0f0;
}

a.text-light:focus,
a.text-light:hover {
  color: #98c6ff;
}

.text-dark {
  color: #455a64;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #263137;
}

.text-info-100 {
  color: #e5ebf1;
}

a.text-info-100:focus,
a.text-info-100:hover {
  color: #8ccbe8;
}

.text-warning-100 {
  color: #f3ebe0;
}

a.text-warning-100:focus,
a.text-warning-100:hover {
  color: #ede095;
}

.text-danger-100 {
  color: #f1e0e3;
}

a.text-danger-100:focus,
a.text-danger-100:hover {
  color: #e99c95;
}

.text-success-100 {
  color: #e1f0e8;
}

a.text-success-100:focus,
a.text-success-100:hover {
  color: #b0e09b;
}

.text-gray {
  color: #607d8b;
}

a.text-gray:focus,
a.text-gray:hover {
  color: #41545e;
}

.text-gray-dark {
  color: #483d3f;
}

a.text-gray-dark:focus,
a.text-gray-dark:hover {
  color: #3b4857;
}

.text-blue-100 {
  color: #00b2e3;
}

a.text-blue-100:focus,
a.text-blue-100:hover {
  color: #4085ff;
}

.text-blue-300 {
  color: #5687dd;
}

a.text-blue-300:focus,
a.text-blue-300:hover {
  color: #275ec0;
}

.text-blue-500 {
  color: #293f96;
}

a.text-blue-500:focus,
a.text-blue-500:hover {
  color: #165c96;
}

.text-blue-700 {
  color: #113c78;
}

a.text-blue-700:focus,
a.text-blue-700:hover {
  color: #081a35;
}

.text-blue-900 {
  color: #000e4e;
}

a.text-blue-900:focus,
a.text-blue-900:hover {
  color: #000002;
}

.text-green-100 {
  color: #83ed83;
}

a.text-green-100:focus,
a.text-green-100:hover {
  color: #40e340;
}

.text-green-300 {
  color: #148750;
}

a.text-green-300:focus,
a.text-green-300:hover {
  color: #2d6108;
}

.text-green-500 {
  color: #148750;
}

a.text-green-500:focus,
a.text-green-500:hover {
  color: #034012;
}

.text-green-700 {
  color: #116b40;
}

a.text-green-700:focus,
a.text-green-700:hover {
  color: #000e00;
}

.text-green-900 {
  color: #0f6039;
}

a.text-green-900:focus,
a.text-green-900:hover {
  color: #231f20;
}

.text-red-100 {
  color: #ff9678;
}

a.text-red-100:focus,
a.text-red-100:hover {
  color: #ff5b2c;
}

.text-red-300 {
  color: #ff644b;
}

a.text-red-300:focus,
a.text-red-300:hover {
  color: #fe2300;
}

.text-red-500 {
  color: #ea123c;
}

a.text-red-500:focus,
a.text-red-500:hover {
  color: #a41a14;
}

.text-red-700 {
  color: #a90000;
}

a.text-red-700:focus,
a.text-red-700:hover {
  color: #5d0000;
}

.text-red-900 {
  color: #730000;
}

a.text-red-900:focus,
a.text-red-900:hover {
  color: #270000;
}

.text-background-100 {
  color: #f0f0f0;
}

a.text-background-100:focus,
a.text-background-100:hover {
  color: #b7c9e4;
}

.text-body {
  color: #231f20;
}

.text-muted {
  color: #293f96;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5);
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none;
}

.text-break {
  word-break: break-word;
  overflow-wrap: break-word;
}

.text-reset {
  color: inherit;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@media print {
  *,
  :after,
  :before {
    text-shadow: none;
    box-shadow: none;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  pre {
    white-space: pre-wrap;
  }

  blockquote,
  pre {
    border: 1px solid #78909c;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  .container,
  body {
    min-width: 992px;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #231f20;
  }

  .table {
    border-collapse: collapse;
  }

  .table td,
  .table th {
    background-color: #fff;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #b0bec5;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #e1e8ee;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #e1e8ee;
  }
}

/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
:after,
:before {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1500px;
  }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1200px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1500px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 7px;
  padding-left: 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-24,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-24 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.d-none {
  display: none;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none;
  }

  .d-sm-inline {
    display: inline;
  }

  .d-sm-inline-block {
    display: inline-block;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-table {
    display: table;
  }

  .d-sm-table-row {
    display: table-row;
  }

  .d-sm-table-cell {
    display: table-cell;
  }

  .d-sm-flex {
    display: flex;
  }

  .d-sm-inline-flex {
    display: inline-flex;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none;
  }

  .d-md-inline {
    display: inline;
  }

  .d-md-inline-block {
    display: inline-block;
  }

  .d-md-block {
    display: block;
  }

  .d-md-table {
    display: table;
  }

  .d-md-table-row {
    display: table-row;
  }

  .d-md-table-cell {
    display: table-cell;
  }

  .d-md-flex {
    display: flex;
  }

  .d-md-inline-flex {
    display: inline-flex;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none;
  }

  .d-lg-inline {
    display: inline;
  }

  .d-lg-inline-block {
    display: inline-block;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-table {
    display: table;
  }

  .d-lg-table-row {
    display: table-row;
  }

  .d-lg-table-cell {
    display: table-cell;
  }

  .d-lg-flex {
    display: flex;
  }

  .d-lg-inline-flex {
    display: inline-flex;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none;
  }

  .d-xl-inline {
    display: inline;
  }

  .d-xl-inline-block {
    display: inline-block;
  }

  .d-xl-block {
    display: block;
  }

  .d-xl-table {
    display: table;
  }

  .d-xl-table-row {
    display: table-row;
  }

  .d-xl-table-cell {
    display: table-cell;
  }

  .d-xl-flex {
    display: flex;
  }

  .d-xl-inline-flex {
    display: inline-flex;
  }
}

@media print {
  .d-print-none {
    display: none;
  }

  .d-print-inline {
    display: inline;
  }

  .d-print-inline-block {
    display: inline-block;
  }

  .d-print-block {
    display: block;
  }

  .d-print-table {
    display: table;
  }

  .d-print-table-row {
    display: table-row;
  }

  .d-print-table-cell {
    display: table-cell;
  }

  .d-print-flex {
    display: flex;
  }

  .d-print-inline-flex {
    display: inline-flex;
  }
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row;
  }

  .flex-sm-column {
    flex-direction: column;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-sm-wrap {
    flex-wrap: wrap;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .flex-sm-fill {
    flex: 1 1 auto;
  }

  .flex-sm-grow-0 {
    flex-grow: 0;
  }

  .flex-sm-grow-1 {
    flex-grow: 1;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }

  .justify-content-sm-start {
    justify-content: flex-start;
  }

  .justify-content-sm-end {
    justify-content: flex-end;
  }

  .justify-content-sm-center {
    justify-content: center;
  }

  .justify-content-sm-between {
    justify-content: space-between;
  }

  .justify-content-sm-around {
    justify-content: space-around;
  }

  .align-items-sm-start {
    align-items: flex-start;
  }

  .align-items-sm-end {
    align-items: flex-end;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .align-items-sm-baseline {
    align-items: baseline;
  }

  .align-items-sm-stretch {
    align-items: stretch;
  }

  .align-content-sm-start {
    align-content: flex-start;
  }

  .align-content-sm-end {
    align-content: flex-end;
  }

  .align-content-sm-center {
    align-content: center;
  }

  .align-content-sm-between {
    align-content: space-between;
  }

  .align-content-sm-around {
    align-content: space-around;
  }

  .align-content-sm-stretch {
    align-content: stretch;
  }

  .align-self-sm-auto {
    align-self: auto;
  }

  .align-self-sm-start {
    align-self: flex-start;
  }

  .align-self-sm-end {
    align-self: flex-end;
  }

  .align-self-sm-center {
    align-self: center;
  }

  .align-self-sm-baseline {
    align-self: baseline;
  }

  .align-self-sm-stretch {
    align-self: stretch;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row;
  }

  .flex-md-column {
    flex-direction: column;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .flex-md-fill {
    flex: 1 1 auto;
  }

  .flex-md-grow-0 {
    flex-grow: 0;
  }

  .flex-md-grow-1 {
    flex-grow: 1;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1;
  }

  .justify-content-md-start {
    justify-content: flex-start;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .justify-content-md-center {
    justify-content: center;
  }

  .justify-content-md-between {
    justify-content: space-between;
  }

  .justify-content-md-around {
    justify-content: space-around;
  }

  .align-items-md-start {
    align-items: flex-start;
  }

  .align-items-md-end {
    align-items: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .align-items-md-baseline {
    align-items: baseline;
  }

  .align-items-md-stretch {
    align-items: stretch;
  }

  .align-content-md-start {
    align-content: flex-start;
  }

  .align-content-md-end {
    align-content: flex-end;
  }

  .align-content-md-center {
    align-content: center;
  }

  .align-content-md-between {
    align-content: space-between;
  }

  .align-content-md-around {
    align-content: space-around;
  }

  .align-content-md-stretch {
    align-content: stretch;
  }

  .align-self-md-auto {
    align-self: auto;
  }

  .align-self-md-start {
    align-self: flex-start;
  }

  .align-self-md-end {
    align-self: flex-end;
  }

  .align-self-md-center {
    align-self: center;
  }

  .align-self-md-baseline {
    align-self: baseline;
  }

  .align-self-md-stretch {
    align-self: stretch;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row;
  }

  .flex-lg-column {
    flex-direction: column;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-lg-wrap {
    flex-wrap: wrap;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .flex-lg-fill {
    flex: 1 1 auto;
  }

  .flex-lg-grow-0 {
    flex-grow: 0;
  }

  .flex-lg-grow-1 {
    flex-grow: 1;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }

  .justify-content-lg-start {
    justify-content: flex-start;
  }

  .justify-content-lg-end {
    justify-content: flex-end;
  }

  .justify-content-lg-center {
    justify-content: center;
  }

  .justify-content-lg-between {
    justify-content: space-between;
  }

  .justify-content-lg-around {
    justify-content: space-around;
  }

  .align-items-lg-start {
    align-items: flex-start;
  }

  .align-items-lg-end {
    align-items: flex-end;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .align-items-lg-baseline {
    align-items: baseline;
  }

  .align-items-lg-stretch {
    align-items: stretch;
  }

  .align-content-lg-start {
    align-content: flex-start;
  }

  .align-content-lg-end {
    align-content: flex-end;
  }

  .align-content-lg-center {
    align-content: center;
  }

  .align-content-lg-between {
    align-content: space-between;
  }

  .align-content-lg-around {
    align-content: space-around;
  }

  .align-content-lg-stretch {
    align-content: stretch;
  }

  .align-self-lg-auto {
    align-self: auto;
  }

  .align-self-lg-start {
    align-self: flex-start;
  }

  .align-self-lg-end {
    align-self: flex-end;
  }

  .align-self-lg-center {
    align-self: center;
  }

  .align-self-lg-baseline {
    align-self: baseline;
  }

  .align-self-lg-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row;
  }

  .flex-xl-column {
    flex-direction: column;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xl-wrap {
    flex-wrap: wrap;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .flex-xl-fill {
    flex: 1 1 auto;
  }

  .flex-xl-grow-0 {
    flex-grow: 0;
  }

  .flex-xl-grow-1 {
    flex-grow: 1;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }

  .justify-content-xl-start {
    justify-content: flex-start;
  }

  .justify-content-xl-end {
    justify-content: flex-end;
  }

  .justify-content-xl-center {
    justify-content: center;
  }

  .justify-content-xl-between {
    justify-content: space-between;
  }

  .justify-content-xl-around {
    justify-content: space-around;
  }

  .align-items-xl-start {
    align-items: flex-start;
  }

  .align-items-xl-end {
    align-items: flex-end;
  }

  .align-items-xl-center {
    align-items: center;
  }

  .align-items-xl-baseline {
    align-items: baseline;
  }

  .align-items-xl-stretch {
    align-items: stretch;
  }

  .align-content-xl-start {
    align-content: flex-start;
  }

  .align-content-xl-end {
    align-content: flex-end;
  }

  .align-content-xl-center {
    align-content: center;
  }

  .align-content-xl-between {
    align-content: space-between;
  }

  .align-content-xl-around {
    align-content: space-around;
  }

  .align-content-xl-stretch {
    align-content: stretch;
  }

  .align-self-xl-auto {
    align-self: auto;
  }

  .align-self-xl-start {
    align-self: flex-start;
  }

  .align-self-xl-end {
    align-self: flex-end;
  }

  .align-self-xl-center {
    align-self: center;
  }

  .align-self-xl-baseline {
    align-self: baseline;
  }

  .align-self-xl-stretch {
    align-self: stretch;
  }
}

.m-0 {
  margin: 0;
}

.mt-0,
.my-0 {
  margin-top: 0;
}

.mr-0,
.mx-0 {
  margin-right: 0;
}

.mb-0,
.my-0 {
  margin-bottom: 0;
}

.ml-0,
.mx-0 {
  margin-left: 0;
}

.m-1 {
  margin: 0.25rem;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.mt-3,
.my-3 {
  margin-top: 1rem;
}

.mr-3,
.mx-3 {
  margin-right: 1rem;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem;
}

.ml-3,
.mx-3 {
  margin-left: 1rem;
}

.m-4 {
  margin: 1.5rem;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem;
}

.m-5 {
  margin: 3rem;
}

.mt-5,
.my-5 {
  margin-top: 3rem;
}

.mr-5,
.mx-5 {
  margin-right: 3rem;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem;
}

.ml-5,
.mx-5 {
  margin-left: 3rem;
}

.p-0 {
  padding: 0;
}

.pt-0,
.py-0 {
  padding-top: 0;
}

.pr-0,
.px-0 {
  padding-right: 0;
}

.pb-0,
.py-0 {
  padding-bottom: 0;
}

.pl-0,
.px-0 {
  padding-left: 0;
}

.p-1 {
  padding: 0.25rem;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.pt-3,
.py-3 {
  padding-top: 1rem;
}

.pr-3,
.px-3 {
  padding-right: 1rem;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem;
}

.pl-3,
.px-3 {
  padding-left: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem;
}

.p-5 {
  padding: 3rem;
}

.pt-5,
.py-5 {
  padding-top: 3rem;
}

.pr-5,
.px-5 {
  padding-right: 3rem;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem;
}

.pl-5,
.px-5 {
  padding-left: 3rem;
}

.m-n1 {
  margin: -0.25rem;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem;
}

.m-n2 {
  margin: -0.5rem;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem;
}

.m-n3 {
  margin: -1rem;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem;
}

.m-n4 {
  margin: -1.5rem;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem;
}

.m-n5 {
  margin: -3rem;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem;
}

.m-auto {
  margin: auto;
}

.mt-auto,
.my-auto {
  margin-top: auto;
}

.mr-auto,
.mx-auto {
  margin-right: auto;
}

.mb-auto,
.my-auto {
  margin-bottom: auto;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0;
  }

  .m-sm-1 {
    margin: 0.25rem;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem;
  }

  .m-sm-2 {
    margin: 0.5rem;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem;
  }

  .m-sm-3 {
    margin: 1rem;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem;
  }

  .m-sm-4 {
    margin: 1.5rem;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem;
  }

  .m-sm-5 {
    margin: 3rem;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem;
  }

  .p-sm-0 {
    padding: 0;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0;
  }

  .p-sm-1 {
    padding: 0.25rem;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem;
  }

  .p-sm-2 {
    padding: 0.5rem;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem;
  }

  .p-sm-3 {
    padding: 1rem;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem;
  }

  .p-sm-4 {
    padding: 1.5rem;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem;
  }

  .p-sm-5 {
    padding: 3rem;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem;
  }

  .m-sm-n1 {
    margin: -0.25rem;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem;
  }

  .m-sm-n2 {
    margin: -0.5rem;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem;
  }

  .m-sm-n3 {
    margin: -1rem;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem;
  }

  .m-sm-n4 {
    margin: -1.5rem;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem;
  }

  .m-sm-n5 {
    margin: -3rem;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem;
  }

  .m-sm-auto {
    margin: auto;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0;
  }

  .m-md-1 {
    margin: 0.25rem;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem;
  }

  .m-md-2 {
    margin: 0.5rem;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem;
  }

  .m-md-3 {
    margin: 1rem;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem;
  }

  .m-md-4 {
    margin: 1.5rem;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem;
  }

  .m-md-5 {
    margin: 3rem;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem;
  }

  .p-md-0 {
    padding: 0;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0;
  }

  .p-md-1 {
    padding: 0.25rem;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem;
  }

  .p-md-2 {
    padding: 0.5rem;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem;
  }

  .p-md-3 {
    padding: 1rem;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem;
  }

  .p-md-4 {
    padding: 1.5rem;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem;
  }

  .p-md-5 {
    padding: 3rem;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem;
  }

  .m-md-n1 {
    margin: -0.25rem;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem;
  }

  .m-md-n2 {
    margin: -0.5rem;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem;
  }

  .m-md-n3 {
    margin: -1rem;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem;
  }

  .m-md-n4 {
    margin: -1.5rem;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem;
  }

  .m-md-n5 {
    margin: -3rem;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem;
  }

  .m-md-auto {
    margin: auto;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0;
  }

  .m-lg-1 {
    margin: 0.25rem;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem;
  }

  .m-lg-2 {
    margin: 0.5rem;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem;
  }

  .m-lg-3 {
    margin: 1rem;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem;
  }

  .m-lg-4 {
    margin: 1.5rem;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem;
  }

  .m-lg-5 {
    margin: 3rem;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem;
  }

  .p-lg-0 {
    padding: 0;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0;
  }

  .p-lg-1 {
    padding: 0.25rem;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem;
  }

  .p-lg-2 {
    padding: 0.5rem;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem;
  }

  .p-lg-3 {
    padding: 1rem;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem;
  }

  .p-lg-4 {
    padding: 1.5rem;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem;
  }

  .p-lg-5 {
    padding: 3rem;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem;
  }

  .m-lg-n1 {
    margin: -0.25rem;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem;
  }

  .m-lg-n2 {
    margin: -0.5rem;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem;
  }

  .m-lg-n3 {
    margin: -1rem;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem;
  }

  .m-lg-n4 {
    margin: -1.5rem;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem;
  }

  .m-lg-n5 {
    margin: -3rem;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem;
  }

  .m-lg-auto {
    margin: auto;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0;
  }

  .m-xl-1 {
    margin: 0.25rem;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem;
  }

  .m-xl-2 {
    margin: 0.5rem;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem;
  }

  .m-xl-3 {
    margin: 1rem;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem;
  }

  .m-xl-4 {
    margin: 1.5rem;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem;
  }

  .m-xl-5 {
    margin: 3rem;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem;
  }

  .p-xl-0 {
    padding: 0;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0;
  }

  .p-xl-1 {
    padding: 0.25rem;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem;
  }

  .p-xl-2 {
    padding: 0.5rem;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem;
  }

  .p-xl-3 {
    padding: 1rem;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem;
  }

  .p-xl-4 {
    padding: 1.5rem;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem;
  }

  .p-xl-5 {
    padding: 3rem;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem;
  }

  .m-xl-n1 {
    margin: -0.25rem;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem;
  }

  .m-xl-n2 {
    margin: -0.5rem;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem;
  }

  .m-xl-n3 {
    margin: -1rem;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem;
  }

  .m-xl-n4 {
    margin: -1.5rem;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem;
  }

  .m-xl-n5 {
    margin: -3rem;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem;
  }

  .m-xl-auto {
    margin: auto;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto;
  }
}

@media only screen {
  [data-simplebar] {
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  .simplebar-wrapper,
  [data-simplebar] {
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
  }

  .simplebar-wrapper {
    overflow: hidden;
  }

  .simplebar-mask {
    direction: inherit;
    overflow: hidden;
    width: auto;
    height: auto;
    // z-index: 0;
  }

  .simplebar-mask,
  .simplebar-offset {
    position: absolute;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .simplebar-offset {
    direction: inherit;
    box-sizing: inherit;
    resize: none;
    -webkit-overflow-scrolling: touch;
  }

  .simplebar-content {
    direction: inherit;
    box-sizing: border-box;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    visibility: visible;
    overflow: scroll;
    max-width: 100%;
    max-height: 100%;
  }

  .simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none;
  }

  .simplebar-height-auto-observer-wrapper {
    box-sizing: inherit;
    height: 100%;
    width: inherit;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    // z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    flex-grow: inherit;
    flex-shrink: 0;
    flex-basis: 0;
  }

  .simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    // z-index: -1;
  }

  .simplebar-track {
    // z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  [data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all;
  }

  .simplebar-scrollbar {
    position: absolute;
    right: 2px;
    width: 7px;
    min-height: 10px;
  }

  .simplebar-scrollbar:before {
    position: absolute;
    content: '';
    background: #231f20;
    border-radius: 7px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: 0.5;
    transition: opacity 0s linear;
  }

  .simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px;
  }

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px;
  }

  .simplebar-track.simplebar-horizontal {
    left: 0;
    height: 11px;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto;
  }

  [data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0;
  }

  .hs-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .simplebar-content {
    height: 100%;
  }

  .simplebar-track:after {
    display: none;
  }

  .simplebar-track .simplebar-scrollbar {
    // z-index: 2;
  }

  .simplebar-track .simplebar-scrollbar.simplebar-hover {
    transition: background 0.3s ease;
  }

  .simplebar-track .simplebar-scrollbar.simplebar-hover:before {
    background: #7d7d7d;
  }

  .simplebar-track .simplebar-scrollbar:before {
    background: #c1c1c1;
    opacity: 1;
    transition: background 0.3s ease;
  }

  span.multiselect-native-select {
    position: relative;
  }

  span.multiselect-native-select select {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px -1px -1px -3px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    left: 50%;
    top: 30px;
  }

  .multiselect.dropdown-toggle:after {
    display: none;
  }

  .multiselect-container {
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .multiselect-container .multiselect-reset .input-group {
    width: 93%;
  }

  .multiselect-container .multiselect-filter > .fa-search {
    // z-index: 1;
    padding-left: 0.75rem;
  }

  .multiselect-container .multiselect-filter > input.multiselect-search {
    border: none;
    border-bottom: 1px solid #d3d3d3;
    padding-left: 2rem;
    margin-left: -1.625rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .multiselect-container .multiselect-filter > input.multiselect-search:focus {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .multiselect-container .multiselect-filter > .multiselect-moz-clear-filter {
    margin-left: -1.5rem;
    display: none;
  }

  .multiselect-container .multiselect-option.multiselect-group-option-indented {
    padding-left: 1.75rem;
  }

  .multiselect-container .multiselect-all,
  .multiselect-container .multiselect-group,
  .multiselect-container .multiselect-option {
    padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  }

  .multiselect-container .multiselect-all .form-check-label,
  .multiselect-container .multiselect-all.dropdown-item,
  .multiselect-container .multiselect-all.dropdown-toggle,
  .multiselect-container .multiselect-group .form-check-label,
  .multiselect-container .multiselect-group.dropdown-item,
  .multiselect-container .multiselect-group.dropdown-toggle,
  .multiselect-container .multiselect-option .form-check-label,
  .multiselect-container .multiselect-option.dropdown-item,
  .multiselect-container .multiselect-option.dropdown-toggle {
    cursor: pointer;
  }

  .multiselect-container .multiselect-all.active:not(.multiselect-active-item-fallback),
  .multiselect-container .multiselect-all:not(.multiselect-active-item-fallback):active,
  .multiselect-container .multiselect-group.active:not(.multiselect-active-item-fallback),
  .multiselect-container .multiselect-group:not(.multiselect-active-item-fallback):active,
  .multiselect-container .multiselect-option.active:not(.multiselect-active-item-fallback),
  .multiselect-container .multiselect-option:not(.multiselect-active-item-fallback):active {
    background-color: #d3d3d3;
    color: #231f20;
  }

  .multiselect-container .multiselect-all .form-check,
  .multiselect-container .multiselect-group .form-check,
  .multiselect-container .multiselect-option .form-check {
    padding: 0 5px 0 20px;
  }

  .multiselect-container .multiselect-all:focus,
  .multiselect-container .multiselect-group:focus,
  .multiselect-container .multiselect-option:focus {
    outline: 0;
  }

  .form-inline .multiselect-container span.form-check {
    padding: 3px 20px 3px 40px;
  }

  .input-group.input-group-sm > .multiselect-native-select .multiselect {
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 0.25rem 1.75rem 0.25rem 0.5rem;
    height: calc(1.5em + 0.5rem + 2px);
  }

  .input-group > .multiselect-native-select {
    flex: 1 1 auto;
    width: 1%;
  }

  .input-group > .multiselect-native-select > div.btn-group {
    width: 100%;
  }

  .input-group > .multiselect-native-select:not(:first-child) .multiselect {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group > .multiselect-native-select:not(:last-child) .multiselect {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .multiselect.dropdown-toggle {
    display: block;
    width: 100%;
    text-align: left;
  }

  .multiselect.dropdown-toggle .multiselect-selected-text {
    flex-grow: 1;
  }

  .multiselect-native-select .btn-group {
    display: block;
  }

  .multiselect-native-select .btn-group.show .multiselect.dropdown-toggle {
    border-color: #293f96;
  }

  .multiselect-native-select .dropdown-menu {
    min-width: 100%;
    margin-top: 8px;
    overflow: hidden;
    border-color: #293f96;
  }

  .multiselect-native-select .multiselect-all {
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid #e1e8ee;
  }

  .multiselect-native-select .multiselect-all .form-check-label {
    font-weight: inherit;
    color: #231f20;
  }

  .multiselect-native-select .dropdown-item .form-check .form-check-input,
  .multiselect-native-select .multiselect-option .form-check .form-check-input {
    opacity: 0;
  }

  .multiselect-native-select .dropdown-item .form-check .form-check-label,
  .multiselect-native-select .multiselect-option .form-check .form-check-label {
    position: relative;
  }

  .multiselect-native-select .dropdown-item .form-check .form-check-label:before,
  .multiselect-native-select .multiselect-option .form-check .form-check-label:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -20px;
    width: 12px;
    height: 12px;
    background: no-repeat 50% 50%;
    background-size: contain;
    vertical-align: middle;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxMyI+PHBhdGggZD0iTTYgMTNjLS4zIDAtLjUtLjEtLjctLjNsLTUtNWMtLjQtLjQtLjQtMSAwLTEuNHMxLS40IDEuNCAwTDYgMTAuNiAxNi4zLjNjLjQtLjQgMS0uNCAxLjQgMHMuNCAxIDAgMS40bC0xMSAxMWMtLjIuMi0uNC4zLS43LjN6IiBmaWxsPSIjMDc4OTI2Ii8+PC9zdmc+);
    display: inline-block;
    opacity: 0;
  }

  .multiselect-native-select .dropdown-item,
  .multiselect-native-select .dropdown-item:focus {
    color: #293f96;
    text-decoration: none;
  }

  .multiselect-native-select .dropdown-item:focus:hover,
  .multiselect-native-select .dropdown-item:hover {
    background-color: #f0f0f0;
  }

  .multiselect-container .multiselect-all.active:not(.multiselect-active-item-fallback),
  .multiselect-container .multiselect-all:not(.multiselect-active-item-fallback):active,
  .multiselect-container .multiselect-group.active:not(.multiselect-active-item-fallback),
  .multiselect-container .multiselect-group:not(.multiselect-active-item-fallback):active,
  .multiselect-container .multiselect-option.active:not(.multiselect-active-item-fallback),
  .multiselect-container .multiselect-option:not(.multiselect-active-item-fallback):active {
    background: 0 0;
  }

  .multiselect-native-select .multiselect-option.active .form-check-label:before {
    display: inline-block;
    opacity: 1;
  }

  body,
  html {
    font-size: 14px;
    background-color: #fff;
  }

  body,
  body > .section {
    overflow-x: hidden;
  }

  h1 {
    color: #231f20;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 1rem;
    margin-bottom: 1.5rem;
  }

  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    padding-top: 0;
  }

  .small {
    font-size: 90%;
  }

  .display-5 {
    font-weight: 300;
    line-height: 1.2;
    font-size: 2rem;
  }

  .feather {
    width: 20px;
    height: 20px;
  }

  .feather--big {
    width: 28px;
    height: 28px;
  }
}

@media only screen and (max-width: 576px) {
  .no-mobile-border {
    border: 0;
  }
}

@media only screen and (max-width: 768px) {
  .no-md-border {
    border: 0;
  }
}

@media only screen and (max-width: 576px) {
  .no-mobile-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen {
  .text-decoration-underline {
    text-decoration: underline;
  }
}

@media only screen {
  .hide,
  .hide-on-desktop {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hide-on-desktop {
    display: block;
  }
}

@media only screen {
  div.show-on-mobile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  div.show-on-mobile {
    display: block;
  }
}

@media only screen {
  span.show-on-mobile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  span.show-on-mobile {
    display: inline-block;
  }
}

@media only screen {
  .dropdown-menu {
    min-width: 15rem;
  }
}

@media only screen {
  .dropdown-item {
    padding: 0.5rem 1.5rem;
  }
}

@media only screen {
  .flex-shrink-0 {
    flex-shrink: 0;
  }
}

@media only screen {
  svg {
    vertical-align: top;
  }
}

@media only screen {
  .page-title svg {
    vertical-align: middle;
  }
}

@media only screen {
  .card .card-header.bg-primary {
    border-bottom: 0;
  }

  .card .card-header.bg-primary.content-header > a {
    transition:
      color 0.3s ease,
      background 0.3s ease;
    border-radius: calc(0.5rem - 1px) 0 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .card .card-header.bg-primary.content-header > a {
    border-radius: 0;
  }
}

@media only screen {
  .card .card-header.bg-primary.content-header > a:hover {
    color: #293f96;
    background: #e6f0fc;
  }

  .card .card-header.bg-primary.content-header > a:hover svg,
  .card .card-header.bg-primary.content-header > a:hover svg * {
    color: #293f96;
  }
}

@media only screen {
  .overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen {
  .password-checker {
    margin-top: 10px;
  }

  .password-checker-fill {
    background-color: #f0f0f0;
    width: 100%;
    height: 4px;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
  }

  .password-checker-fill > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .password-checker-label {
    margin-top: 4px;
    font-style: italic;
    font-size: 12px;
  }
}

@media only screen {
  .link-block {
    display: block;
    transition: background 0.3s ease-in-out;
  }

  .link-block,
  .link-block:hover {
    color: inherit;
    text-decoration: none;
  }

  .link-block:hover {
    background-color: #f4f5ff;
  }
}

@media only screen {
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
}

@media only screen {
  .nowrap {
    white-space: nowrap;
  }
}

@media only screen {
  .btn:focus {
    box-shadow: none;
  }

  .btn:active {
    box-shadow: none;
  }
}

@media only screen and (max-width: 576px) {
  .row-wrapper .row .btn {
    display: block;
    width: 100%;
    float: none;
    text-align: center;
    margin-right: 0;
  }
}

@media only screen and (max-width: 576px) {
  .row-wrapper .row .btn a.btn-link,
  .row-wrapper .row a.btn-link {
    text-align: left;
  }
}

@media only screen {
  .btn-primary,
  .btn-primary:focus,
  .btn-primary:visited {
    background-color: #148750;
    border-color: #148750;
  }

  .btn-primary:hover {
    background-color: #116b40 !important;
    border-color: #116b40 !important;
  }

  .btn-primary:active {
    background-color: #0f6039 !important;
  }
}

@media only screen {
  .btn-secondary {
    background-color: #fff;
    border: 1px solid #c1c8cc;
    color: #231f20;
  }

  .btn-secondary.border-0 {
    border: 0;
  }

  .btn-secondary:focus,
  .btn-secondary:visited {
    background-color: #fff;
    border: 1px solid #e1e8ee;
    color: #231f20;
  }

  .btn-secondary:hover {
    border-color: #293f96;
    background-color: #e6f0fc;
  }

  .btn-secondary:active {
    border-color: #000e4e;
    background-color: #293f96;
    color: #fff;
  }
}

@media only screen {
  .btn-light,
  .btn-light:focus,
  .btn-light:hover,
  .btn-light:visited {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
  }

  .btn-light:active {
    background-color: #f0f0f0;
  }
}

@media only screen and (max-width: 768px) {
  .btn-stretch-on-tablet-and-mobile {
    width: 100%;
    margin-top: 12px;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 576px) {
  .btn-stretch-on-mobile {
    width: 100%;
    margin-top: 12px;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media only screen {
  .btn.disabled,
  .btn:disabled {
    opacity: 1;
    background-color: #e1e8ee;
    border-color: #e1e8ee;
    color: #483d3f;
  }

  .btn.disabled.btn-link,
  .btn:disabled.btn-link {
    background: 0 0;
    border-color: transparent;
    color: #483d3f;
  }
}

@media only screen {
  .navbar {
    flex-wrap: nowrap;
  }

  .navbar .navbar-toggler {
    width: 40px;
    height: 40px;
    padding: 0;
    outline: 0;
  }

  .navbar .navbar-toggler[aria-expanded='true'] {
    background: #293f96;
  }

  .navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
}

@media only screen {
  .top .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@media only screen and (min-width: 992px) {
  .top .dropdown-menu {
    top: 49px;
    border-top: 2px solid #ea123c;
  }
}

@media only screen {
  .top .dropdown-menu .dropdown-item {
    background: 0 0;
    position: relative;
    padding-right: 32px;
  }

  .top .dropdown-menu .dropdown-item.active,
  .top .dropdown-menu .dropdown-item:hover {
    color: #293f96;
  }

  .top .dropdown-menu .dropdown-item .feather-chevron-right {
    position: absolute;
    top: 9px;
    right: 13px;
    width: 16px;
    height: 16px;
  }

  .top .dropdown-menu .dropdown-item .feather-chevron-left {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 4px;
  }
}

@media only screen {
  #navbarNav .navbar-nav {
    margin: 0 -4px;
  }
}

@media only screen and (max-width: 992px) {
  #navbarNav .navbar-nav {
    border-top: 1px solid #e1e8ee;
    padding-bottom: 16px;
  }
}

@media only screen {
  #navbarNav .navbar-nav .nav-item {
    position: relative;
    margin: 0 4px;
  }
}

@media only screen and (max-width: 992px) {
  #navbarNav .navbar-nav .nav-item {
    margin: 0 5px;
  }
}

@media only screen {
  #navbarNav .navbar-nav .nav-item:after {
    content: '';
    position: absolute;
    left: 0.5rem;
    right: 0.5rem;
    bottom: -17px;
    background-color: #f16752;
    height: 2px;
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  #navbarNav .navbar-nav .nav-item:after {
    display: none;
  }
}

@media only screen {
  #navbarNav .navbar-nav .nav-item.active .nav-link {
    color: #293f96;
  }

  #navbarNav .navbar-nav .nav-item.active:after {
    display: block;
  }
}

@media only screen {
  #navbarNav .navbar-nav .nav-item .nav-link {
    color: #231f20;
  }

  #navbarNav .navbar-nav .nav-item .nav-link:hover {
    color: #293f96;
  }
}

@media only screen {
  #navbarNav .navbar-nav .nav-item.dropdown.show .nav-link svg {
    transform: rotate(180deg);
  }
}

@media only screen and (max-width: 992px) {
  #navbarNav .navbar-nav .nav-item.dropdown .nav-link svg {
    float: right;
  }
}

@media only screen {
  #navbarNav .navbar-nav .nav-item.dropdown .nav-link:after {
    display: none;
  }
}

@media only screen {
  #navbarNav .navbar-nav .nav-item.dropdown .dropdown-item:hover {
    color: #293f96;
    background: 0 0;
  }
}

@media only screen {
  #navbarNav.show .nav-item {
    text-align: left;
  }

  #navbarNav.show .nav-item.active:before {
    content: '';
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ea123c;
    height: 50%;
    width: 3px;
  }
}

@media only screen and (min-width: 992px) {
  #navbarNav.show .nav-item.active:before {
    display: none;
  }
}

@media only screen {
  #navbarNav.show .dropdown-menu {
    border: none;
    padding-left: 0;
    padding-right: 0;
  }

  #navbarNav.show .dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem;
  }

  #navbarNav.show .dropdown-menu .dropdown-item.active {
    background: 0 0;
    color: #293f96;
  }
}

@media only screen {
  .navbar-account .rounded-circle {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-account .rounded-circle {
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }
}

@media only screen {
  .navbar-account-desktop {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .navbar-account-desktop {
    display: none;
  }
}

@media only screen {
  .navbar-account-desktop .dropdown.show .nav-link svg {
    transform: rotate(180deg);
  }
}

@media only screen and (min-width: 992px) {
  .navbar-account-desktop .dropdown .dropdown-menu {
    top: 57px;
  }
}

@media only screen {
  .navbar-account-mobile {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .navbar-account-mobile {
    display: block;
  }

  .navbar-account-mobile .rounded-circle {
    width: 48px;
    height: 48px;
  }

  .navbar-account-mobile .navbar-account__info div {
    font-size: 13px;
  }

  .navbar-account-mobile > a {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }

  .navbar-account-mobile > a strong {
    color: #483d3f;
  }

  .navbar-account-mobile > a[aria-expanded='true'] svg {
    transform: rotate(180deg);
  }

  .navbar-account-mobile a {
    padding: 16px;
    text-decoration: none;
    border-top: 1px solid #e1e8ee;
    color: #231f20;
  }

  .navbar-account-mobile a:hover {
    color: #293f96;
  }
}

@media only screen {
  .navbar-account-mobile .nav-item {
    list-style: none;
  }

  .navbar-account-mobile .nav-item .nav-link {
    padding: 8px 0;
    border: 0;
  }
}

@media only screen {
  .navbar-account .dropdown-toggle {
    padding-left: 0;
    padding-right: 0;
    color: rgba(0, 0, 0, 0.5);
  }

  .navbar-account .dropdown-toggle:after {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  #navbarNav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff none repeat scroll 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    // z-index: 10;
  }

  #navbarNav ul {
    padding: 0.5rem 1rem;
  }
}

@media only screen and (max-width: 992px) {
  .navbar-date {
    flex-grow: 1;
    text-align: right;
  }
}

@media only screen {
  .navbar-date br {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .navbar-date br {
    display: inline-block;
  }
}

@media only screen and (max-width: 576px) {
  .navbar-date span {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .dropdown-item,
  .nav-item {
    font-size: 14px;
  }
}

@media only screen {
  .datagrid-no-tables {
    display: table;
    width: 100%;
    margin-bottom: 1rem;
    color: #231f20;
    overflow: hidden;
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables {
    border-radius: 0;
  }
}

@media only screen {
  .col-6.navtabs-datagrid__table {
    border-radius: 0.5rem 0 0 0;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1200px) {
  .col-6.navtabs-datagrid__table {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .col-6.navtabs-datagrid__table
    .datagrid-no-tables
    .datagrid-no-tables-body
    .datagrid-no-tables-row:last-child
    .datagrid-no-tables-cell:last-child {
    border-radius: 0 0 0.5rem 0;
  }
}

@media only screen and (max-width: 768px) {
  .col-6.navtabs-datagrid__table,
  .col-6.navtabs-datagrid__table
    .datagrid-no-tables
    .datagrid-no-tables-body
    .datagrid-no-tables-row:last-child
    .datagrid-no-tables-cell:last-child {
    border-radius: 0;
  }
}

@media only screen {
  .col-6.navtabs-datagrid__table
    .datagrid-no-tables
    .datagrid-no-tables-body
    .datagrid-no-tables-row:last-child
    .datagrid-no-tables-cell:first-child {
    border-radius: 0 0 0 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .col-6.navtabs-datagrid__table
    .datagrid-no-tables
    .datagrid-no-tables-body
    .datagrid-no-tables-row:last-child
    .datagrid-no-tables-cell:first-child {
    border-radius: 0;
  }
}

@media only screen {
  .datagrid-no-tables:last-child {
    margin-bottom: 0;
  }
}

@media only screen {
  .datagrid-no-tables svg {
    vertical-align: middle;
  }
}

@media only screen {
  .datagrid-no-tables-header {
    display: table-header-group;
    background-color: #293f96;
    color: #fff;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large .datagrid-no-tables-header {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-medium .datagrid-no-tables-header,
  .datagrid-no-tables-small .datagrid-no-tables-header {
    display: none;
  }
}

@media only screen {
  .datagrid-no-tables-header-light {
    background: #f0f0f0;
    color: #231f20;
    font-weight: 500;
  }

  .datagrid-no-tables-header-light .datagrid-no-tables-row .datagrid-no-tables-cell {
    border-bottom: 1px solid #e1e8ee;
    border-top: 1px solid #e1e8ee;
  }

  .datagrid-no-tables-header-light .datagrid-no-tables-row .datagrid-no-tables-cell:first-child {
    border-left: 1px solid #e1e8ee;
  }

  .datagrid-no-tables-header-light .datagrid-no-tables-row .datagrid-no-tables-cell:last-child {
    border-right: 1px solid #e1e8ee;
  }
}

@media only screen {
  .datagrid-no-tables-header .datagrid-no-tables-cell {
    vertical-align: bottom;
    border-bottom: 2px solid #8ab4ff;
    border-top: 0;
    white-space: nowrap;
  }

  .datagrid-no-tables-header .datagrid-no-tables-cell:first-child {
    border-left: 1px solid #293f96;
  }
}

@media only screen and (max-width: 1200px) {
  .datagrid-no-tables-header .datagrid-no-tables-cell:last-child {
    border-right: 1px solid #293f96;
  }
}

@media only screen {
  .navtabs-datagrid-force-full-width .datagrid-no-tables-cell:last-child {
    border-right: 1px solid #e1e8ee;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-datagrid-force-full-width .datagrid-no-tables-cell:last-child {
    border-right: 0;
  }
}

@media only screen {
  .datagrid-no-tables-header .datagrid-no-tables-cell:last-child {
    border-right: 1px solid #293f96;
  }
}

@media only screen {
  .datagrid-no-tables-header .datagrid-no-tables-cell .arrow .feather {
    width: 16px;
    height: 16px;
    position: relative;
    top: -1px;
  }
}

@media only screen {
  .datagrid-no-tables-header .datagrid-no-tables-cell .feather-info {
    position: relative;
    top: -1px;
  }
}

@media only screen {
  .datagrid-no-tables-header .datagrid-no-tables-cell.sortable {
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }

  .datagrid-no-tables-header .datagrid-no-tables-cell.sortable .arrow {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .datagrid-no-tables-header .datagrid-no-tables-cell.sortable .arrow svg {
    transition: transform 0.3s ease;
  }

  .datagrid-no-tables-header .datagrid-no-tables-cell.sortable.active .arrow,
  .datagrid-no-tables-header .datagrid-no-tables-cell.sortable:hover .arrow {
    opacity: 1;
  }

  .datagrid-no-tables-header .datagrid-no-tables-cell.sortable.active:hover .arrow svg,
  .datagrid-no-tables-header .datagrid-no-tables-cell.sortable.reverse .arrow svg {
    transform: rotate(180deg);
  }

  .datagrid-no-tables-header .datagrid-no-tables-cell.sortable.reverse:hover .arrow svg {
    transform: none;
  }
}

@media only screen {
  .datagrid-no-tables-footer {
    display: table-footer-group;
    color: #231f20;
    font-weight: 500;
  }

  .datagrid-no-tables-footer .datagrid-no-tables-row:last-child .datagrid-no-tables-cell {
    background: #f0f0f0;
    overflow: hidden;
  }

  .datagrid-no-tables-footer .datagrid-no-tables-row:last-child .datagrid-no-tables-cell:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  .datagrid-no-tables-footer .datagrid-no-tables-row:last-child .datagrid-no-tables-cell:last-child {
    border-bottom-right-radius: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-footer .datagrid-no-tables-row {
    background: #f0f0f0;
  }
}

@media only screen {
  .datagrid-no-tables-row {
    display: table-row;
    transition: background 0.3s ease-in-out;
  }

  .datagrid-no-tables-body .datagrid-no-tables-row:hover {
    background-color: #f0f0f0;
    color: inherit;
  }

  .list-group .list-group-item:not(.active) {
    transition: background 0.3s ease-in-out;
  }

  .list-group .list-group-item:not(.active):hover {
    background-color: #f0f0f0;
    color: inherit;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large .datagrid-no-tables-row {
    display: block;
    padding: 8px 0.75rem;
    border: 1px solid #e1e8ee;
  }

  .datagrid-no-tables-large .datagrid-no-tables-row .hidden-arrow {
    display: block;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-medium .datagrid-no-tables-row {
    display: block;
    padding: 8px 0.75rem;
    border: 1px solid #e1e8ee;
  }

  .datagrid-no-tables-medium .datagrid-no-tables-row .hidden-arrow {
    display: block;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-small .datagrid-no-tables-row {
    display: block;
    padding: 8px 0.75rem;
    border: 1px solid #e1e8ee;
  }

  .datagrid-no-tables-small .datagrid-no-tables-row .hidden-arrow {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large .datagrid-no-tables-row + .datagrid-no-tables-row,
  .datagrid-no-tables-medium .datagrid-no-tables-row + .datagrid-no-tables-row,
  .datagrid-no-tables-small .datagrid-no-tables-row + .datagrid-no-tables-row {
    border-top-width: 0;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large {
    border-radius: 0;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large,
  .datagrid-no-tables-medium .datagrid-no-tables-row:first-child,
  .datagrid-no-tables-small .datagrid-no-tables-row:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large .datagrid-no-tables-row:last-child,
  .datagrid-no-tables-medium .datagrid-no-tables-row:last-child,
  .datagrid-no-tables-small .datagrid-no-tables-row:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media only screen {
  .datagrid-no-tables-row.list-group-item {
    cursor: pointer;
    position: relative;
    transition: background 0.3s ease-in-out;
    color: #231f20;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-row.list-group-item {
    border-radius: 0;
  }
}

@media only screen {
  .datagrid-no-tables-row.list-group-item.active,
  .datagrid-no-tables-row.list-group-item:hover {
    background-color: #f0f0f0;
    color: inherit;
  }
}

@media only screen and (max-width: 1200px) {
  .datagrid-no-tables-row.list-group-item.active {
    background: 0 0;
    border-color: rgba(0, 0, 0, 0.125);
    color: inherit;
  }
}

@media only screen {
  .datagrid-no-tables-row.list-group-item.active .datagrid-no-tables-cell:first-child:after {
    opacity: 1;
  }
}

@media only screen {
  .datagrid-no-tables-row.list-group-item .datagrid-no-tables-cell {
    position: relative;
  }

  .datagrid-no-tables-row.list-group-item .datagrid-no-tables-cell:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -1px;
    bottom: 0;
    width: 2px;
    background-color: #293f96;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 1200px) {
  .datagrid-no-tables-row.list-group-item .datagrid-no-tables-cell:after {
    display: none;
  }
}

@media only screen {
  .datagrid-no-tables-has-footer .datagrid-no-tables-body .datagrid-no-tables-row:last-child .datagrid-no-tables-cell {
    border-radius: 0;
  }
}

@media only screen {
  .datagrid-no-tables-no-border .datagrid-no-tables-header {
    background: 0 0;
    color: #231f20;
    font-weight: 500;
  }

  .datagrid-no-tables-no-border .datagrid-no-tables-header .datagrid-no-tables-row .datagrid-no-tables-cell {
    border-bottom: 1px solid #e1e8ee;
    border-top: none;
  }

  .datagrid-no-tables-no-border
    .datagrid-no-tables-header
    .datagrid-no-tables-row
    .datagrid-no-tables-cell:first-child {
    border-left: none;
  }

  .datagrid-no-tables-no-border .datagrid-no-tables-header .datagrid-no-tables-row .datagrid-no-tables-cell:last-child {
    border-right: none;
  }

  .datagrid-no-tables-no-border .datagrid-no-tables-cell:first-child {
    border-left: none;
  }

  .datagrid-no-tables-no-border .datagrid-no-tables-cell:last-child {
    border-right: none;
  }

  .datagrid-no-tables-no-border .datagrid-no-tables-footer .datagrid-no-tables-cell {
    background: 0 0;
    border-bottom: none;
  }
}

@media only screen {
  .datagrid-no-tables a.datagrid-no-tables-cell {
    text-decoration: none;
    color: #231f20;
  }
}

@media only screen {
  .datagrid-no-tables-cell {
    display: table-cell;
    padding: 0.75rem;
    vertical-align: top;
    border-bottom: 1px solid #e1e8ee;
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-cell .feather-info.hide-on-desktop {
    display: inline-block;
    margin-left: -32px;
    vertical-align: top;
    margin-right: 8px;
    // z-index: 2;
    position: relative;
  }
}

@media only screen {
  .datagrid-no-tables-cell .hidden-arrow {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

@media only screen {
  .aanbieden-wrapper .datagrid-no-tables-cell[data-title='Status'] {
    vertical-align: middle;
  }
}

@media only screen {
  .drafts-wrapper .datagrid-no-tables-large .datagrid-no-tables-cell[data-title='Acties'] {
    vertical-align: middle;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .drafts-wrapper .datagrid-no-tables-large .datagrid-no-tables-cell[data-title='Acties'] {
    text-align: left;
  }
}

@media only screen {
  .drafts-wrapper .datagrid-no-tables-medium .datagrid-no-tables-cell[data-title='Acties'] {
    vertical-align: middle;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .drafts-wrapper .datagrid-no-tables-medium .datagrid-no-tables-cell[data-title='Acties'] {
    text-align: left;
  }
}

@media only screen {
  .drafts-wrapper .datagrid-no-tables-small .datagrid-no-tables-cell[data-title='Acties'] {
    vertical-align: middle;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .drafts-wrapper .datagrid-no-tables-small .datagrid-no-tables-cell[data-title='Acties'] {
    text-align: left;
  }
}

@media only screen {
  .datagrid-no-tables-cell[data-title='Functie'],
  .datagrid-no-tables-cell[data-title='Functie'] * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }

  .datagrid-no-tables-large .datagrid-no-tables-cell[data-title='Functie'],
  .datagrid-no-tables-large .datagrid-no-tables-cell[data-title='Functie'] * {
    max-width: none;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-medium .datagrid-no-tables-cell[data-title='Functie'],
  .datagrid-no-tables-medium .datagrid-no-tables-cell[data-title='Functie'] *,
  .datagrid-no-tables-small .datagrid-no-tables-cell[data-title='Functie'],
  .datagrid-no-tables-small .datagrid-no-tables-cell[data-title='Functie'] * {
    max-width: none;
    white-space: normal;
  }
}

@media only screen {
  .datagrid-no-tables-cell:first-child {
    border-left: 1px solid #e1e8ee;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large .datagrid-no-tables-cell:first-child {
    border-left: 0;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-medium .datagrid-no-tables-cell:first-child,
  .datagrid-no-tables-small .datagrid-no-tables-cell:first-child {
    border-left: 0;
  }
}

@media only screen {
  .datagrid-no-tables-cell:last-child {
    border-right: 1px solid #e1e8ee;
  }

  .aanbieden-wrapper .datagrid-no-tables-cell:last-child {
    text-align: center;
  }

  .navtabs-datagrid__table .datagrid-no-tables-cell:last-child {
    border-right: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid__table .datagrid-no-tables-cell:last-child {
    border-right: 1px solid #e1e8ee;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large .navtabs-datagrid__table .datagrid-no-tables-cell:last-child {
    border-right: 0;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-medium .navtabs-datagrid__table .datagrid-no-tables-cell:last-child,
  .datagrid-no-tables-small .navtabs-datagrid__table .datagrid-no-tables-cell:last-child {
    border-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large .datagrid-no-tables-cell:last-child {
    border-right: 0;
    text-align: left;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-medium .datagrid-no-tables-cell:last-child,
  .datagrid-no-tables-small .datagrid-no-tables-cell:last-child {
    border-right: 0;
    text-align: left;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large .datagrid-no-tables-cell {
    display: block;
    padding: 4px 24px 4px 100px;
    position: relative;
    border: 0;
  }

  .datagrid-no-tables-large .datagrid-no-tables-cell:before {
    position: absolute;
    left: 0;
    top: 3px;
    width: 92px;
    content: attr(data-title);
    overflow: hidden;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-medium .datagrid-no-tables-cell {
    display: block;
    padding: 4px 24px 4px 100px;
    position: relative;
    border: 0;
  }

  .datagrid-no-tables-medium .datagrid-no-tables-cell:before {
    position: absolute;
    left: 0;
    top: 3px;
    width: 84px;
    content: attr(data-title);
    overflow: hidden;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-small .datagrid-no-tables-cell {
    display: block;
    padding: 4px 24px 4px 100px;
    position: relative;
    border: 0;
  }

  .datagrid-no-tables-small .datagrid-no-tables-cell:before {
    position: absolute;
    left: 0;
    top: 3px;
    width: 84px;
    content: attr(data-title);
    overflow: hidden;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-large.datagrid-no-tables-no-thead .datagrid-no-tables-cell {
    padding: 4px;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-medium.datagrid-no-tables-no-thead .datagrid-no-tables-cell,
  .datagrid-no-tables-small.datagrid-no-tables-no-thead .datagrid-no-tables-cell {
    padding: 4px;
  }
}

@media only screen {
  .datagrid-no-tables-body {
    display: table-row-group;
  }

  .datagrid-no-tables-body + .datagrid-no-tables-body {
    border-top: 2px solid #e1e8ee;
  }
}

@media only screen {
  .datagrid-no-tables .small {
    font-size: 90%;
  }
}

@media only screen and (max-width: 576px) {
  .datagrid-no-tables-hide-on-mobile-m,
  .datagrid-no-tables-hide-on-mobile-s {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables-hide-on-mobile-l,
  .tables-uren .datagrid-no-tables-cell.toggle-on-tablet-off.hide-on-mobile {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .btn-action svg {
    width: 18px;
    height: 18px;
  }
}

@media only screen {
  .btn-action.active svg {
    fill: #293f96;
  }
}

@media only screen {
  .btn-action.active:hover svg {
    fill: #0083ad;
  }
}

@media only screen {
  .nav-pills.nav {
    height: 100%;
  }

  .nav-pills.nav.nav-negative {
    margin: 0 -21px;
  }

  .nav-pills.nav[aria-orientation='vertical'] .nav-link svg {
    opacity: 1;
  }

  .nav-pills.nav .nav-link {
    position: relative;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0;
  }

  .nav-pills.nav .nav-link:before {
    content: '';
    display: none;
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .nav-pills.nav .nav-link svg {
    color: inherit;
    opacity: 0.5;
  }
}

@media only screen and (min-width: 900px) {
  .nav-pills.nav .nav-link svg {
    margin-right: 8px;
  }
}

@media only screen {
  .nav-pills.nav .nav-link svg * {
    color: inherit;
  }
}

@media only screen {
  .nav-pills.nav .nav-link svg.feather-star {
    fill: #231f20;
  }

  .bg-primary.nav-tabs .nav-link svg.feather-star {
    fill: #fff;
  }
}

@media only screen {
  .nav-pills.nav .nav-link.active {
    color: #231f20;
    background: #f0f0f0;
  }

  .nav-pills.nav .nav-link.active:before {
    display: block;
  }

  .nav-pills.nav .nav-link.active svg {
    opacity: 1;
  }

  .nav-pills.nav .nav-link.active svg.feather-star {
    fill: #293f96;
  }
}

@media only screen and (max-width: 768px) {
  .col-12 .tab-content.pl-4 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen {
  .nav-pills-horizontal .nav-link,
  .nav-tabs .nav-link {
    color: #231f20;
  }
}

@media only screen and (min-width: 900px) {
  .nav-pills-horizontal .nav-link,
  .nav-tabs .nav-link {
    display: flex;
    padding-bottom: 12px;
  }
}

@media only screen {
  .nav-pills-horizontal .nav-link svg,
  .nav-tabs .nav-link svg {
    color: inherit;
    opacity: 0.5;
  }
}

@media only screen and (min-width: 900px) {
  .nav-pills-horizontal .nav-link svg,
  .nav-tabs .nav-link svg {
    margin-right: 8px;
  }
}

@media only screen {
  .nav-pills-horizontal .nav-link svg *,
  .nav-tabs .nav-link svg * {
    color: inherit;
  }
}

@media only screen {
  .nav-pills-horizontal .nav-link svg.feather-star,
  .nav-tabs .nav-link svg.feather-star {
    fill: #231f20;
  }

  .bg-primary.nav-tabs .nav-link svg.feather-star {
    fill: #fff;
  }
}

@media only screen {
  .nav-pills-horizontal .nav-link.active,
  .nav-pills-horizontal .nav-link:hover,
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-link:hover {
    border-color: transparent;
  }

  .nav-pills-horizontal .nav-link.active > div,
  .nav-pills-horizontal .nav-link:hover > div,
  .nav-tabs .nav-link.active > div,
  .nav-tabs .nav-link:hover > div {
    color: inherit;
  }

  .nav-pills-horizontal .nav-link.active svg,
  .nav-pills-horizontal .nav-link:hover svg,
  .nav-tabs .nav-link.active svg,
  .nav-tabs .nav-link:hover svg {
    color: inherit;
    opacity: 1;
  }

  .nav-pills-horizontal .nav-link.active svg.feather-star,
  .nav-pills-horizontal .nav-link:hover svg.feather-star,
  .nav-tabs .nav-link.active svg.feather-star,
  .nav-tabs .nav-link:hover svg.feather-star {
    fill: #293f96;
  }
}

@media only screen {
  .nav-pills-horizontal .nav-link.start-new-group,
  .nav-tabs .nav-link.start-new-group {
    position: relative;
    margin-left: 7px;
  }

  .nav-pills-horizontal .nav-link.start-new-group:before,
  .nav-tabs .nav-link.start-new-group:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 4px;
    bottom: 8px;
    width: 1px;
    background-color: #78909c;
    opacity: 0.5;
  }
}

@media only screen {
  .nav-tabs.bg-primary {
    margin-bottom: 0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile {
    padding-right: 0;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile li a.active,
  .nav-tabs.bg-primary.drop-tabs-mobile li a:hover {
    background: 0 0;
    color: #fff;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile > li:first-child {
    display: flex;
    margin: 0 -8px;
    position: relative;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile > li:first-child .nav-link {
    border: 0;
    border-right: 1px solid #fff;
    line-height: 22px;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile > li:first-child .nav-link svg {
    opacity: 1;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile > li:first-child .nav-link.active,
  .nav-tabs.bg-primary.drop-tabs-mobile > li:first-child .nav-link:hover {
    color: #231f20;
    background: #e6f0fc;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile > li:first-child .nav-link.active svg,
  .nav-tabs.bg-primary.drop-tabs-mobile > li:first-child .nav-link:hover svg {
    color: #231f20;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile > li:first-child .nav-link.active {
    color: #231f20;
    background: #fff;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile > li:first-child .nav-link.active svg {
    color: #231f20;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile .nav-link.active {
    padding-left: 26px;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile .nav-item {
    display: none;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile .nav-item--first .nav-link.active {
    padding-left: 1rem;
  }

  .nav-tabs.bg-primary .nav-item {
    margin-bottom: 0;
  }

  .nav-tabs.bg-primary .nav-item.hide-nav {
    display: none;
  }

  .nav-tabs.bg-primary .nav-item.show-nav {
    display: block;
  }

  .nav-tabs.bg-primary .nav-item.nav-item-not-available .nav-link {
    color: hsla(0, 0%, 100%, 0.5);
  }

  .nav-tabs.bg-primary .nav-item.nav-item-not-available .nav-link.active,
  .nav-tabs.bg-primary .nav-item.nav-item-not-available .nav-link:hover {
    background: 0 0;
    color: hsla(0, 0%, 100%, 0.5);
  }

  .nav-tabs.bg-primary .dropdown svg {
    color: #fff;
    margin-right: 0;
  }

  .nav-tabs.bg-primary .dropdown.show {
    background: #fff;
  }

  .nav-tabs.bg-primary .dropdown.show svg {
    color: #293f96;
  }

  .nav-tabs.bg-primary .dropdown .dropdown-menu {
    margin-top: 1px;
  }

  .nav-tabs.bg-primary .nav-link {
    color: #fff;
    border-color: transparent;
    border-radius: 0;
    padding: 1rem;
    font-weight: 500;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile .nav-link {
    font-size: 1.429rem;
    line-height: 1.2;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile .back-mobile a {
    display: flex;
    font-size: 14px;
    line-height: 1.5;
    align-items: center;
  }

  .nav-tabs.bg-primary.drop-tabs-mobile .back-mobile a svg {
    margin-right: 0;
  }

  .nav-tabs.bg-primary .nav-link.dropdown-toggle {
    position: relative;
    background: #113c78;
    border: 0;
  }

  .nav-tabs.bg-primary .nav-link.dropdown-toggle:hover,
  .nav-tabs.bg-primary .nav-link.dropdown-toggle[aria-expanded='true'] {
    background: 0 0;
    color: #fff;
  }

  .nav-tabs.bg-primary .nav-link.dropdown-toggle .dropdown-amount {
    position: absolute;
    top: 2px;
    left: -8px;
    background: #148750;
    color: #fff;
    width: 16px;
    height: 16px;
    font-size: 10px;
    border-radius: 50%;
    text-align: center;
    line-height: 16px;
  }

  .nav-tabs.bg-primary .nav-link.active,
  .nav-tabs.bg-primary .nav-link:hover {
    color: #231f20;
    background: #e6f0fc;
  }

  .dropdown .nav-tabs.bg-primary .nav-link.active,
  .dropdown .nav-tabs.bg-primary .nav-link:hover {
    background: 0 0;
  }

  .nav-tabs.bg-primary .nav-link.active {
    color: #231f20;
    background: #fff;
  }

  .dropdown .nav-tabs.bg-primary .nav-link.active {
    background: 0 0;
  }
}

@media only screen {
  .nav.nav-pills.nav-pills-horizontal .nav-link {
    border-radius: 0.5rem;
  }

  .nav.nav-pills.nav-pills-horizontal .nav-link:before {
    display: none;
  }
}

@media only screen {
  .navtabs-icons {
    flex-wrap: nowrap;
    position: relative;
    // z-index: 2;
  }
}

@media only screen and (min-width: 900px) {
  .navtabs-icons__label {
    margin-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-icons__label {
    font-size: 12px;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-icons .nav-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-icons .nav-link:not(.active) {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-icons .nav-link:not(.active) .navtabs-icons__label {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .navtabs-datagrid-searchable__toolbar--has-button {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 576px) {
  .navtabs-datagrid-searchable__toolbar--has-button .navtabs-datagrid-searchable__toolbar-table {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .navtabs-datagrid-searchable__toolbar--has-button .navtabs-datagrid-searchable__toolbar-button {
    width: 100%;
    margin-top: 8px;
  }
}

@media only screen {
  .navtabs-datagrid-searchable__toolbar--has-button .navtabs-datagrid-searchable__toolbar-button .btn:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 576px) {
  .navtabs-datagrid-searchable__toolbar--has-button .navtabs-datagrid-searchable__toolbar-button .btn {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    box-sizing: border-box;
  }
}

@media only screen {
  .navtabs-datagrid-searchable__toolbar > div {
    padding: 4px 0.75rem;
  }
}

@media only screen {
  .navtabs-datagrid-searchable__toolbar-table {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-datagrid-searchable__toolbar-table {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-datagrid-searchable__toolbar-table > div {
    width: 100%;
  }
}

@media only screen {
  .navtabs-datagrid-searchable__toolbar-table .input-group input {
    width: 300px;
    padding-right: 48px;
    box-sizing: border-box;
    border-radius: 0.25rem;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-datagrid-searchable__toolbar-table .input-group input {
    width: auto;
  }
}

@media only screen {
  .navtabs-datagrid-searchable__toolbar-table .input-group .input-group-append {
    margin-left: -43px;
    position: relative;
    // z-index: 9;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid__extend .navtabs-datagrid-searchable__toolbar-table {
    width: 50%;
  }
}

@media only screen {
  .navtabs-datagrid-force-full-width .navtabs-datagrid-searchable__toolbar-table {
    width: 50%;
  }
}

@media only screen {
  .navtabs-datagrid-searchable__toolbar .filters-toggle svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-datagrid-searchable__toolbar-search {
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-datagrid-searchable__toolbar-search .search {
    flex-grow: 1;
    margin-right: 0;
  }
}

@media only screen {
  .navtabs-datagrid-searchable__toolbar-active .btn {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-datagrid-searchable__toolbar-active {
    display: none;
  }
}

@media only screen {
  .navtabs-datagrid-searchable__toolbar-content {
    display: none;
    width: 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid-searchable__toolbar-content {
    display: flex;
  }
}

@media only screen {
  .navtabs-datagrid-force-full-width .navtabs-datagrid-searchable__toolbar-content {
    display: flex;
  }
}

@media only screen {
  .navtabs-datagrid-searchable__toolbar-back {
    padding-left: 0;
    padding-right: 0;
  }

  .navtabs-datagrid-searchable__toolbar-back svg {
    width: 16px;
    height: 16px;
    margin-top: -2px;
    vertical-align: middle;
  }
}

@media only screen {
  .navtabs-datagrid-searchable__wrapper {
    position: relative;
    padding-left: 0;
    transition: padding 0.3s ease-in-out;
  }

  .navtabs-datagrid-searchable__wrapper.toggled {
    padding-left: calc(1.5rem + 220px);
  }

  .navtabs-datagrid-searchable__wrapper.toggled .navtabs-datagrid-searchable__filters {
    left: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid-searchable__wrapper.toggled {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid-static .navtabs-datagrid-searchable__wrapper.toggled {
    padding-left: calc(1.5rem + 220px);
  }
}

@media only screen {
  .navtabs-datagrid-force-full-width .navtabs-datagrid-searchable__wrapper.toggled {
    padding-left: 0;
  }

  .navtabs-datagrid-force-full-width .navtabs-datagrid-searchable__wrapper.toggled .navtabs-datagrid__table {
    padding-left: calc(1.5rem + 220px);
  }

  .navtabs-datagrid-force-full-width
    .navtabs-datagrid-searchable__wrapper.toggled
    .navtabs-datagrid__table
    .table-wrapper {
    left: calc(1.5rem + 220px);
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid-searchable__wrapper.toggled .aanbieden-wrapper,
  .navtabs-datagrid-searchable__wrapper.toggled .navtabs-datagrid__table {
    padding-left: calc(1.5rem + 220px);
  }
}

@media only screen {
  .navtabs-datagrid-searchable__wrapper .navtabs-datagrid-searchable__filters {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(-1.5rem + -220px);
    width: 220px;
    transition: left 0.3s ease-in-out;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
    z-index: 2;
  }
}

@media only screen {
  .navtabs-datagrid-searchable .navtabs-datagrid__table {
    transition: padding 0.3s ease-in-out;
    position: relative;
  }

  .navtabs-datagrid-searchable .navtabs-datagrid__table .datagrid-no-tables {
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0;
  }

  .navtabs-datagrid-searchable .navtabs-datagrid__table .table-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: left 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid-searchable .navtabs-datagrid__table .table-wrapper {
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}

@media only screen {
  .navtabs-datagrid-searchable .navtabs-datagrid__table__scroll-container {
    max-width: 100%;
    max-height: 100%;
  }
}

@media only screen {
  .navtabs-datagrid-searchable .navtabs-datagrid__content {
    transition: opacity 0.6s ease-in-out;
  }

  .navtabs-datagrid-searchable .navtabs-datagrid__content.bg-light {
    border-radius: 0 0.5rem 0.5rem 0;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid-searchable .navtabs-datagrid__content.bg-light {
    border-radius: 0.5rem;
  }
}

@media only screen {
  .tab-content.bg-light {
    border-radius: 0.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid-searchable .navtabs-datagrid__content {
    opacity: 0;
    max-height: none;
  }
}

@media only screen {
  .navtabs-datagrid-searchable .navtabs-datagrid__content .tab-content {
    position: relative;
    // z-index: 1;
  }
}

@media only screen {
  .navtabs-datagrid-searchable .navtabs-datagrid__content .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // z-index: 0;
    text-align: center;
  }

  .navtabs-datagrid-searchable .navtabs-datagrid__content .placeholder .feather {
    margin-bottom: 4px;
    width: 80px;
    height: 80px;
    opacity: 0.4;
    color: #293f96;
  }

  .navtabs-datagrid-searchable .navtabs-datagrid__content .placeholder > div {
    opacity: 0.8;
    color: #293f96;
  }
}

@media only screen {
  .navtabs-datagrid {
    overflow: hidden;
  }

  .navtabs-datagrid__extend {
    transition: transform 0.6s ease-in-out;
  }

  .navtabs-datagrid__extend.toggled {
    transform: translateX(-50%);
  }

  .navtabs-datagrid__extend.toggled .navtabs-datagrid__content {
    opacity: 1;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid__extend {
    width: 200%;
  }
}

@media only screen {
  .navtabs-datagrid-force-full-width .navtabs-datagrid__extend {
    width: 200%;
  }
}

@media only screen and (min-width: 1200px) {
  .navtabs-datagrid-back {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid-default .navtabs-datagrid__extend > .row > .col-6:first-child {
    padding-right: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .navtabs-datagrid-default .navtabs-datagrid__extend > .row > .col-6:last-child {
    padding-left: 0;
  }
}

@media only screen {
  .navtabs-datagrid-force-full-width .navtabs-datagrid__table,
  .navtabs-datagrid-static .navtabs-datagrid__table {
    overflow: hidden;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .navtabs-datagrid-force-full-width .navtabs-datagrid__table,
  .navtabs-datagrid-static .navtabs-datagrid__table {
    border-radius: 0;
  }
}

@media only screen {
  .navtabs-datagrid-force-full-width
    .datagrid-no-tables-body
    .datagrid-no-tables-row:last-child
    .datagrid-no-tables-cell:first-child,
  .navtabs-datagrid-static
    .datagrid-no-tables-body
    .datagrid-no-tables-row:last-child
    .datagrid-no-tables-cell:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  .navtabs-datagrid-force-full-width
    .datagrid-no-tables-body
    .datagrid-no-tables-row:last-child
    .datagrid-no-tables-cell:last-child,
  .navtabs-datagrid-static
    .datagrid-no-tables-body
    .datagrid-no-tables-row:last-child
    .datagrid-no-tables-cell:last-child {
    border-bottom-right-radius: 0.5rem;
  }
}

@media only screen {
  .custom-checkbox.custom-control,
  .custom-radio.custom-control {
    padding-left: 2.5rem;
  }

  .custom-checkbox .custom-control-label:after,
  .custom-checkbox .custom-control-label:before,
  .custom-radio .custom-control-label:after,
  .custom-radio .custom-control-label:before {
    top: 0;
    left: -2.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media only screen {
  .custom-switch.custom-control {
    padding-left: 3.5rem;
  }

  .custom-switch .custom-control-label:before {
    top: 0;
    left: -3.5rem;
    width: 2.5rem;
    height: 1.5rem;
    border-radius: 1rem;
  }

  .custom-switch .custom-control-label:after {
    top: 3px;
    left: calc(-3.5rem + 3px);
    width: calc(1rem + 1px);
    height: calc(1rem + 1px);
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label:after {
    transform: translateX(1rem);
    background-color: #293f96;
  }
}

@media only screen {
  .custom-checkbox .custom-control-label:before {
    border-radius: 0.2rem;
  }
}

@media only screen {
  .form-control,
  .multiselect.dropdown-toggle {
    border-radius: 0.25rem;
  }

  .form-control:hover,
  .multiselect.dropdown-toggle:hover {
    border-color: #293f96;
  }

  .form-control:disabled,
  .multiselect.dropdown-toggle:disabled {
    border-color: #b0bec5;
  }

  .form-control.is-valid,
  .multiselect.dropdown-toggle.is-valid {
    background-color: #e1f0e8;
    border-color: #148750;
  }

  .form-control.is-valid::-webkit-input-placeholder,
  .multiselect.dropdown-toggle.is-valid::-webkit-input-placeholder {
    color: #148750;
  }

  .form-control.is-valid::-moz-placeholder,
  .multiselect.dropdown-toggle.is-valid::-moz-placeholder {
    color: #148750;
  }

  .form-control.is-valid:-ms-input-placeholder,
  .multiselect.dropdown-toggle.is-valid:-ms-input-placeholder {
    color: #148750;
  }

  .form-control.is-valid::-ms-input-placeholder,
  .multiselect.dropdown-toggle.is-valid::-ms-input-placeholder {
    color: #148750;
  }

  .form-control.is-valid::placeholder,
  .multiselect.dropdown-toggle.is-valid::placeholder {
    color: #148750;
  }

  .form-control.is-invalid,
  .multiselect.dropdown-toggle.is-invalid {
    background-color: #f1e0e3;
    border-color: #ab0a3d;
  }

  .form-control.is-invalid::-webkit-input-placeholder,
  .multiselect.dropdown-toggle.is-invalid::-webkit-input-placeholder {
    color: #ab0a3d;
  }

  .form-control.is-invalid::-moz-placeholder,
  .multiselect.dropdown-toggle.is-invalid::-moz-placeholder {
    color: #ab0a3d;
  }

  .form-control.is-invalid:-ms-input-placeholder,
  .multiselect.dropdown-toggle.is-invalid:-ms-input-placeholder {
    color: #ab0a3d;
  }

  .form-control.is-invalid::-ms-input-placeholder,
  .multiselect.dropdown-toggle.is-invalid::-ms-input-placeholder {
    color: #ab0a3d;
  }

  .form-control.is-invalid::placeholder,
  .multiselect.dropdown-toggle.is-invalid::placeholder {
    color: #ab0a3d;
  }
}

@media only screen {
  :disabled,
  :disabled + label {
    opacity: 0.5;
  }
}

@media only screen {
  .upload-file {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .upload-file input {
    padding: 56px 40px;
    height: auto;
  }

  .upload-file label {
    text-align: center;
    border-style: dashed;
    height: auto;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    margin-bottom: 0;
  }

  .upload-file label svg {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

@media only screen {
  .toggle-password .feather-eye-off,
  .upload-file label:after {
    display: none;
  }

  .toggle-password.password-shown .feather-eye-off {
    display: inline-block;
  }

  .toggle-password.password-shown .feather-eye {
    display: none;
  }
}

@media only screen {
  .form-icon-left {
    position: relative;
  }

  .form-icon-left input {
    padding-left: 36px;
  }

  .form-icon-left-icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 768px) {
  .application-info-header {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .application-info-title {
    width: 100%;
    margin: 0 0 24px;
  }

  .application-info-title:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .application-info-buttons .btn {
    min-width: 0;
  }
}

@media only screen {
  .modal .modal-header {
    background-color: #293f96;
    color: #fff;
    padding: 16px 24px;
  }
}

@media only screen and (max-width: 576px) {
  .modal .modal-dialog {
    align-items: stretch;
  }
}

@media only screen {
  .modal .modal-content {
    border: 0;
  }
}

@media only screen {
  .modal .modal-body {
    color: #231f20;
    padding: 24px;
  }

  .modal .modal-body .container-fluid,
  .modal .modal-body .container-lg,
  .modal .modal-body .container-md,
  .modal .modal-body .container-sm,
  .modal .modal-body .container-xl {
    padding: 0;
  }

  .modal .modal-body p:last-child {
    margin-bottom: 0;
  }
}

@media only screen {
  .modal .modal-footer {
    padding: 20px 24px;
    border-top: 0;
  }

  .modal .modal-footer .btn:last-child {
    margin-right: 0;
  }
}

@media only screen {
  .modal .close {
    color: #483d3f;
  }

  .modal .close span {
    margin-right: -7px;
  }
}

@media only screen {
  .alert {
    position: relative;
    padding-right: 40px;
    transition: opacity 0.3s ease;
  }

  .alert .close-alert {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
  }

  .alert-fixed {
    position: fixed;
    top: 85px;
    left: 50%;
    transform: translate(-50%);
    // z-index: 999999999;
    max-width: 600px;
    width: calc(100% - 32px);
  }
}

@media only screen {
  .alert-button {
    display: flex;
    align-items: center;
    padding-right: 1.25rem;
  }

  .alert-button .btn {
    margin-left: auto;
  }
}

@media only screen {
  .input-error {
    background-color: #f1e0e3;
    border: 1px solid #ab0a3d;
  }

  .input-error:active,
  .input-error:focus,
  .input-error:hover {
    box-shadow: 0 0 7px rgba(219, 15, 15, 0.18);
    background-color: #f1e0e3;
    border: 1px solid #ab0a3d;
  }
}

@media only screen {
  .invalid-field {
    color: #ab0a3d;
    font-size: 13px;
    line-height: 22px;
    margin-top: 8px;
  }
}

@media only screen {
  .upload-file .input-error:active + label,
  .upload-file .input-error:focus + label,
  .upload-file .input-error:hover + label {
    box-shadow: 0 0 7px rgba(219, 15, 15, 0.18);
    background-color: #f1e0e3;
    border: 1px dashed #ab0a3d;
  }

  .upload-file .input-error + label {
    background-color: #f1e0e3;
    border: 1px dashed #ab0a3d;
  }
}

@media only screen {
  .custom-radio .custom-radio-hidden-content {
    display: none;
  }

  .custom-radio .custom-control-input:checked ~ .custom-radio-hidden-content {
    display: block;
  }
}

@media only screen {
  .switch-account {
    display: none;
  }
}

@media only screen {
  .status-row {
    display: flex;
  }
}

@media only screen and (max-width: 992px) {
  .status-row {
    flex-wrap: wrap;
  }
}

@media only screen {
  .status-row-item {
    margin-left: 24px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 992px) {
  .status-row-item {
    width: calc(50% - 24px);
    margin-left: 0;
    margin-bottom: 24px;
  }

  .status-row-item:nth-child(2n) {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .status-row-item {
    width: 100%;
    margin-left: 0;
  }

  .status-row-item:last-child {
    margin-bottom: 0;
  }
}

@media only screen {
  .status-row-item--doing .status-row-icon {
    background: #293f96;
    color: #fff;
  }
}

@media only screen {
  .status-row-item--completed .status-row-icon {
    background: #148750;
    color: #fff;
  }
}

@media only screen {
  .status-row-item:first-child {
    margin-left: 0;
  }
}

@media only screen {
  .status-row-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    background: #f0f0f0;
    margin-right: 16px;
    flex: 24px 0 0;
  }

  .status-row-icon svg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    color: #fff;
    opacity: 1;
  }
}

@media only screen {
  .aanbieden-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .aanbieden-form-wrapper {
    max-width: 700px;
  }
}

@media only screen {
  .overview-aanbieden-back {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }

  .overview-aanbieden-back svg {
    width: 16px;
    height: 16px;
    margin-top: -2px;
    vertical-align: middle;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-aanbieden-back {
    padding-left: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .overview-aanbieden-back {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-aanbieden .overview-aanbieden-content-right .application-info-header {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .overview-aanbieden .overview-aanbieden-content-right .application-info-header {
    border-radius: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-aanbieden .overview-aanbieden-content-right.overview-aanbieden-content-result .application-info-header {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .overview-aanbieden .overview-aanbieden-content-right.overview-aanbieden-content-result .application-info-header {
    border-radius: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-aanbieden {
    overflow: hidden;
    margin: 0 -12px;
  }

  .overview-aanbieden > .overview-aanbieden-extend {
    width: 200%;
    transition: transform 0.6s ease-in-out;
  }

  .overview-aanbieden > .overview-aanbieden-extend.toggled {
    transform: translateX(-50%);
  }

  .overview-aanbieden > .overview-aanbieden-extend > .row {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .overview-aanbieden {
    margin: 0;
  }

  .overview-aanbieden > .overview-aanbieden-extend > .row > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }

  .overview-aanbieden > .overview-aanbieden-extend > .row > [class*='col-'] > .rounded {
    border-radius: 0;
  }
}

@media only screen {
  .overview-aanbieden-nav {
    margin-left: -164px;
    text-align: center;
    width: 100%;
    flex-grow: 1;
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-aanbieden-nav {
    display: block;
  }
}

@media only screen and (max-width: 400px) {
  .overview-aanbieden-nav {
    margin-left: 0;
    text-align: right;
  }
}

@media only screen {
  .overview-aanbieden-nav-item {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 2px;
    border: 1px solid #293f96;
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;
  }

  .overview-aanbieden-nav-item.active {
    background: #293f96;
  }
}

@media only screen {
  .aanbieden-form-row textarea {
    min-height: 170px;
  }
}

@media only screen and (max-width: 400px) {
  .aanbieden-form-row h3 + a span {
    display: none;
  }
}

@media only screen {
  .overview-aanvragen .navtabs-datagrid__table {
    min-height: 800px;
  }
}

@media only screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
}

@media only screen {
  .contract-period {
    min-width: 200px;
    float: right;
  }
}

@media only screen and (max-width: 992px) {
  .contract-period {
    min-width: 0;
    float: none;
  }
}

@media only screen and (max-width: 768px) {
  .container-hide-padding-mobile {
    width: auto;
    padding-right: 0;
    padding-left: 0;
    margin-right: -1px;
    margin-left: -1px;
  }

  .container-hide-padding-mobile .navtabs-datagrid-searchable .navtabs-datagrid__content {
    border-radius: 0;
  }

  .container-hide-padding-mobile > .card {
    border: 0;
    border-radius: 0;
  }

  .container-hide-padding-mobile > .card .nav-tabs.bg-primary {
    border-radius: 0;
  }

  .container-hide-padding-mobile > .card .card-header {
    border-radius: 0;
    border: 0;
  }
}

@media only screen and (max-width: 768px) {
  .card-hide-padding-mobile {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .card-hide-b-radius-mobile,
  .card-hide-b-radius-mobile > .card-header {
    border-radius: 0;
  }
}

@media only screen and (max-width: 768px) {
  .card-hide-border-mobile,
  .card-hide-border-mobile > .card-header,
  .col-12.col-md-4.border-right {
    border: 0;
  }

  .col-12.col-md-4.border-right .pr-4 {
    padding-right: 0;
  }
}

@media only screen {
  .inzetten-wrapper {
    width: 100%;
  }

  .inzetten-wrapper .datagrid-no-tables .datagrid-no-tables-header .datagrid-no-tables-cell {
    vertical-align: middle;
  }
}

@media only screen {
  .block-item {
    text-decoration: none;
  }

  .block-item,
  .block-item:hover {
    color: #293f96;
  }

  .block-item:disabled,
  .block-item[disabled] {
    position: relative;
  }

  .block-item:disabled:before,
  .block-item[disabled]:before {
    content: '';
    opacity: 0.7;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #e1e8ee;
  }
}

@media only screen {
  .data-steps .nav-link {
    color: #65798f;
  }

  .data-steps .nav-link.active {
    color: #293f96;
    font-weight: 700;
    opacity: 1;
  }

  .data-steps .nav-link.active span {
    background: #293f96;
    color: #fff;
  }

  .data-steps .nav-link:before {
    display: none;
  }

  .data-steps .nav-link span {
    display: inline-block;
    border: 1px solid red;
    width: 21px;
    height: 21px;
    line-height: 21px;
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    background: #f0f0f0;
    position: relative;
  }

  .data-steps .nav-link.completed {
    opacity: 1;
  }

  .data-steps .nav-link.completed span {
    background: #148750;
    color: #fff;
  }

  .data-steps .nav-link.completed span svg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    color: #fff;
    opacity: 1;
  }

  .data-steps .nav-link.skipped {
    opacity: 1;
  }

  .data-steps .nav-link.skipped span {
    background: 0 0;
  }

  .data-steps .nav-link.skipped span svg {
    opacity: 1;
  }
}

@media only screen {
  .data-steps[aria-orientation='vertical'] .nav-link {
    padding-left: 34px;
  }

  .data-steps[aria-orientation='vertical'] .nav-link span {
    position: absolute;
    top: 7px;
    left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .blocks-wrapper .border-right {
    border-right: 0;
  }
}

@media only screen and (max-width: 992px) {
  .user-row .user-row-image {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .user-row .border-right {
    border-right: 0;
  }
}

@media only screen {
  .user-row .row {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .user-row .row > div[class^='col-'] {
    display: none;
  }

  .user-row .row > div[class^='col-']:first-child {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .user-row .row > div[class^='col-']:nth-child(3) {
    display: none;
  }
}

@media only screen {
  .overview-contracten .navtabs-datagrid__table {
    min-height: 650px;
  }

  .overview-contracten .navtabs-datagrid-searchable__toolbar-table .input-group input {
    width: 220px;
  }
}

@media only screen {
  .kandidaten-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .overview-betaalvoorstellen .datagrid-no-tables-large .datagrid-no-tables-cell {
    padding-left: 140px;
  }

  .overview-betaalvoorstellen .datagrid-no-tables-large .datagrid-no-tables-cell:before {
    width: 120px;
  }
}

@media only screen {
  .betaalvoorstellen-wrapper {
    width: 100%;
  }
}

@media only screen {
  .overview-uren .datagrid-no-tables-cell.toggle-on-tablet-off {
    display: table-cell;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-uren .datagrid-no-tables-cell.toggle-on-tablet-off {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .overview-uren .datagrid-no-tables-cell.toggle-on-tablet-off {
    display: block;
  }
}

@media only screen {
  .overview-uren .datagrid-no-tables-cell.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-uren .datagrid-no-tables-cell.toggle-on-tablet-on {
    display: table-cell;
  }
}

@media only screen and (max-width: 768px) {
  .overview-uren .datagrid-no-tables-cell.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen {
  .overview-uren div.toggle-on-tablet-off {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-uren div.toggle-on-tablet-off {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .overview-uren div.toggle-on-tablet-off {
    display: block;
  }
}

@media only screen {
  .overview-uren div.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-uren div.toggle-on-tablet-on {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .overview-uren div.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen {
  .overview-uren span.toggle-on-tablet-off {
    display: inline-block;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-uren span.toggle-on-tablet-off {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .overview-uren span.toggle-on-tablet-off {
    display: inline-block;
  }
}

@media only screen {
  .overview-uren span.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-uren span.toggle-on-tablet-on {
    display: inline-block;
  }
}

@media only screen and (max-width: 768px) {
  .overview-uren span.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .overview-uren .datagrid-no-tables-large .datagrid-no-tables-cell {
    padding-left: 160px;
  }

  .overview-uren .datagrid-no-tables-large .datagrid-no-tables-cell:before {
    width: 120px;
  }
}

@media only screen {
  .overview-uren .navtabs-datagrid-searchable__toolbar .navtabs-datagrid-searchable__toolbar-active {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .overview-uren .navtabs-datagrid-searchable__toolbar .navtabs-datagrid-searchable__toolbar-active {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 1100px) {
  .overview-uren .navtabs-datagrid-searchable__toolbar--has-button .navtabs-datagrid-searchable__toolbar-table {
    display: block;
  }
}

@media only screen and (max-width: 1100px) {
  .overview-uren .navtabs-datagrid-searchable__toolbar--has-button .navtabs-datagrid-searchable__toolbar-table > div {
    width: 100%;
  }
}

@media only screen {
  .overview-uren .navtabs-datagrid-searchable__toolbar--has-button .navtabs-datagrid-searchable__toolbar-active {
    display: block;
  }
}

@media only screen and (max-width: 1100px) {
  .overview-uren .navtabs-datagrid-searchable__toolbar--has-button .navtabs-datagrid-searchable__toolbar-active {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 1100px) {
  .overview-uren .navtabs-datagrid-searchable__toolbar--has-button .navtabs-datagrid-searchable__toolbar-button .btn {
    float: left;
  }
}

@media only screen and (max-width: 1100px) {
  .overview-uren
    .navtabs-datagrid-searchable__toolbar--has-button
    .navtabs-datagrid-searchable__toolbar-button
    .download-overview:only-child {
    float: left;
    width: auto;
  }
}

@media only screen and (max-width: 1100px) {
  .overview-uren
    .navtabs-datagrid-searchable__toolbar--has-button
    .navtabs-datagrid-searchable__toolbar-button
    .download-overview--move {
    float: right;
    margin-top: 8px;
    clear: both;
  }
}

@media only screen {
  .tables-uren .text-truncate {
    vertical-align: top;
  }

  .tables-uren .datagrid-no-tables-cell.toggle-on-tablet-off {
    display: table-cell;
  }
}

@media only screen and (max-width: 1200px) {
  .tables-uren .datagrid-no-tables-cell.toggle-on-tablet-off {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .tables-uren .datagrid-no-tables-cell.toggle-on-tablet-off {
    display: block;
  }
}

@media only screen {
  .tables-uren .datagrid-no-tables-cell.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .tables-uren .datagrid-no-tables-cell.toggle-on-tablet-on {
    display: table-cell;
  }
}

@media only screen and (max-width: 768px) {
  .tables-uren .datagrid-no-tables-cell.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen {
  .tables-uren div.toggle-on-tablet-off {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .tables-uren div.toggle-on-tablet-off {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .tables-uren div.toggle-on-tablet-off {
    display: block;
  }
}

@media only screen {
  .tables-uren div.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .tables-uren div.toggle-on-tablet-on {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .tables-uren div.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen {
  .tables-uren span.toggle-on-tablet-off {
    display: inline-block;
  }
}

@media only screen and (max-width: 1200px) {
  .tables-uren span.toggle-on-tablet-off {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .tables-uren span.toggle-on-tablet-off {
    display: inline-block;
  }
}

@media only screen {
  .tables-uren span.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .tables-uren span.toggle-on-tablet-on {
    display: inline-block;
  }
}

@media only screen and (max-width: 768px) {
  .tables-uren span.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen {
  .tables-uren-input {
    width: 64px;
  }
}

@media only screen {
  .tables-uren-weekend > div,
  .tables-uren-weekend > input {
    opacity: 0.7;
  }
}

@media only screen and (max-width: 768px) {
  .tables-uren .tables-uren-add .datagrid-no-tables-cell {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .tables-uren .tables-uren-note {
    display: flex;
    padding: 0;
  }

  .tables-uren .tables-uren-note .datagrid-no-tables-cell {
    padding: 0;
  }

  .tables-uren .tables-uren-note .datagrid-no-tables-cell:first-child {
    flex: 0 0 140px;
  }

  .tables-uren .tables-uren-note .datagrid-no-tables-cell:last-child {
    flex-grow: 1;
  }

  .tables-uren .tables-uren-note span {
    display: none;
  }
}

@media only screen {
  .tables-uren .tables-uren-note-filler {
    background-color: #fff;
    margin-top: -1px;
    padding: 1rem;
    padding-top: 0;
    white-space: normal;
    transition: background 0.3s ease-in-out;
  }
}

@media only screen {
  .tables-uren .datagrid-no-tables-body .datagrid-no-tables-row:hover {
    background-color: #fff;
    color: #231f20;
  }
}

@media only screen {
  .tables-uren .datagrid-no-tables-cell {
    white-space: nowrap;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 768px) {
  .tables-uren .datagrid-no-tables-cell {
    padding-left: 140px;
  }

  .tables-uren .datagrid-no-tables-cell:before {
    top: 0;
    padding-top: 0.8rem;
    width: 120px;
  }
}

@media only screen {
  .uren-tools {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 992px) {
  .uren-tools {
    margin-bottom: 0.75rem;
  }
}

@media only screen and (max-width: 992px) {
  .uren-tools-date {
    width: 100%;
    margin: 0 0 1rem;
  }
}

@media only screen and (max-width: 380px) {
  .uren-tools-date {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 992px) {
  .uren-tools-date-select {
    flex-grow: 1;
  }
}

@media only screen and (max-width: 380px) {
  .uren-tools-date-select {
    width: 100%;
    margin-right: 0;
    text-align: center;
  }

  .uren-tools-date-select .pagination {
    width: 100%;
  }

  .uren-tools-date-select .pagination .page-item:nth-child(2) {
    flex-grow: 1;
  }
}

@media only screen and (max-width: 380px) {
  .uren-tools-date-current {
    width: 100%;
    margin-top: 12px;
  }
}

@media only screen and (max-width: 992px) {
  .uren-tools-buttons {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 380px) {
  .uren-tools-buttons {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 380px) {
  .uren-tools-buttons > div {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-onkosten.navtabs-datagrid .navtabs-datagrid__extend .col-4,
  .overview-onkosten.navtabs-datagrid .navtabs-datagrid__extend .col-8 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media only screen {
  .table-onkosten .datagrid-no-tables-row.list-group-item .datagrid-no-tables-cell:after {
    display: none;
  }

  .table-onkosten .datagrid-no-tables-cell > .d-inline-block {
    vertical-align: top;
  }
}

@media only screen {
  .table-onkosten-mobiel .datagrid-no-tables-cell.toggle-on-tablet-off {
    display: table-cell;
  }
}

@media only screen and (max-width: 1200px) {
  .table-onkosten-mobiel .datagrid-no-tables-cell.toggle-on-tablet-off {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .table-onkosten-mobiel .datagrid-no-tables-cell.toggle-on-tablet-off {
    display: block;
  }
}

@media only screen {
  .table-onkosten-mobiel .datagrid-no-tables-cell.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .table-onkosten-mobiel .datagrid-no-tables-cell.toggle-on-tablet-on {
    display: table-cell;
  }
}

@media only screen and (max-width: 768px) {
  .table-onkosten-mobiel .datagrid-no-tables-cell.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen {
  .table-onkosten-mobiel div.toggle-on-tablet-off {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .table-onkosten-mobiel div.toggle-on-tablet-off {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .table-onkosten-mobiel div.toggle-on-tablet-off {
    display: block;
  }
}

@media only screen {
  .table-onkosten-mobiel div.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .table-onkosten-mobiel div.toggle-on-tablet-on {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .table-onkosten-mobiel div.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen {
  .table-onkosten-mobiel span.toggle-on-tablet-off {
    display: inline-block;
  }
}

@media only screen and (max-width: 1200px) {
  .table-onkosten-mobiel span.toggle-on-tablet-off {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .table-onkosten-mobiel span.toggle-on-tablet-off {
    display: inline-block;
  }
}

@media only screen {
  .table-onkosten-mobiel span.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .table-onkosten-mobiel span.toggle-on-tablet-on {
    display: inline-block;
  }
}

@media only screen and (max-width: 768px) {
  .table-onkosten-mobiel span.toggle-on-tablet-on {
    display: none;
  }
}

@media only screen {
  .table-onkosten-mobiel-input {
    width: 64px;
  }
}

@media only screen {
  .table-onkosten-mobiel-weekend > div,
  .table-onkosten-mobiel-weekend > input {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 768px) {
  .table-onkosten-mobiel .table-onkosten-mobiel-add .datagrid-no-tables-cell {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .table-onkosten-mobiel .table-onkosten-mobiel-note {
    display: flex;
    padding: 0;
  }

  .table-onkosten-mobiel .table-onkosten-mobiel-note .datagrid-no-tables-cell {
    padding: 0;
  }

  .table-onkosten-mobiel .table-onkosten-mobiel-note .datagrid-no-tables-cell:first-child {
    flex: 0 0 140px;
  }

  .table-onkosten-mobiel .table-onkosten-mobiel-note .datagrid-no-tables-cell:last-child {
    flex-grow: 1;
  }

  .table-onkosten-mobiel .table-onkosten-mobiel-note span {
    display: none;
  }
}

@media only screen {
  .table-onkosten-mobiel .table-onkosten-mobiel-note-filler {
    background-color: #fff;
    margin-top: -1px;
    padding: 1rem;
    padding-top: 0;
    white-space: normal;
    transition: background 0.3s ease-in-out;
  }
}

@media only screen {
  .table-onkosten-mobiel .datagrid-no-tables-body .datagrid-no-tables-row:hover {
    background-color: #fff;
    color: #231f20;
  }
}

@media only screen {
  .table-onkosten-mobiel .datagrid-no-tables-cell {
    white-space: nowrap;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 768px) {
  .table-onkosten-mobiel .datagrid-no-tables-cell {
    padding-left: 140px;
  }

  .table-onkosten-mobiel .datagrid-no-tables-cell:before {
    top: 0;
    padding-top: 0.8rem;
    width: 120px;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables.table-todo {
    margin-top: -1px;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables.table-todo .datagrid-no-tables-row .datagrid-no-tables-cell:first-child .feather {
    display: none;
  }
}

@media only screen {
  .datagrid-no-tables.table-todo .datagrid-no-tables-row .datagrid-no-tables-cell:last-child {
    padding-right: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .datagrid-no-tables.table-todo.datagrid-no-tables-large .datagrid-no-tables-cell {
    padding-left: 148px;
  }
}

@media only screen {
  .datagrid-no-tables.table-todo.datagrid-no-tables-large .datagrid-no-tables-cell:before {
    width: 132px;
  }
}

@media only screen {
  .no-actions {
    min-height: 330px;
    position: relative;
  }
}

@media only screen and (max-width: 992px) {
  .no-actions {
    min-height: 160px;
  }
}

@media only screen {
  .no-actions .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // z-index: 0;
    text-align: center;
  }

  .no-actions .placeholder .feather {
    margin-bottom: 4px;
    width: 80px;
    height: 80px;
    opacity: 0.4;
  }

  .no-actions .placeholder > div {
    opacity: 0.8;
  }
}

@media only screen {
  .table-matches .hidden-arrow {
    display: none;
    position: absolute;
    bottom: 0;
    right: 4px;
  }
}

@media only screen and (max-width: 768px) {
  .table-matches .hidden-arrow {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .table-matches .row .col-6 {
    padding-right: 28px;
  }
}

@media only screen {
  .table-matches .row .col-6:last-child {
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .mijn-inzet-info {
    position: relative;
    padding-right: 128px;
  }
}

@media only screen and (max-width: 768px) {
  .mijn-inzet-info-tag {
    position: absolute;
    top: 2px;
    right: 1.5rem;
  }
}

@media only screen {
  .mijn-inzet-info--absolute {
    position: relative;
    padding-right: 128px;
  }

  .mijn-inzet-info--absolute-tag {
    position: absolute;
    top: -3px;
    right: 1.5rem;
  }
}

@media only screen and (max-width: 992px) {
  div .dasboard-actions-pull-left {
    margin-left: -38%;
  }
}

@media only screen and (max-width: 992px) {
  div .dasboard-actions-pull-left:first-child {
    margin-top: 170px;
  }
}

@media only screen and (max-width: 880px) {
  div .dasboard-actions-pull-left {
    margin-left: -39%;
  }
}

@media only screen and (max-width: 768px) {
  div .dasboard-actions-pull-left {
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  div .dasboard-actions-pull-left:first-child {
    margin-top: 1.5rem;
  }
}

@media only screen {
  .fullscreen-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-height: 500px) {
  .fullscreen-wrapper {
    position: static;
  }
}

@media only screen {
  .language-options-flag {
    width: 28px;
    height: 18px;
    display: inline-block;
    background: url(/assets/img/flags/sprite-languages.svg) no-repeat;
  }

  .language-options-flag--nl {
    background-position: 0 0;
  }

  .language-options-flag--en {
    background-position: -50px 0;
  }
}

@media only screen {
  .modal-introduction .modal-dialog {
    // z-index: 9999;
  }
}

@media only screen and (max-width: 832px) {
  .modal-introduction .modal-dialog {
    margin-right: 16px;
    margin-left: 16px;
  }
}

@media only screen {
  .modal-introduction .modal-backdrop {
    // z-index: 999;
  }
}

@media only screen {
  .modal-introduction .modal-content {
    overflow: hidden;
    position: relative;
  }

  .modal-introduction .modal-content:before {
    content: '';
    display: block;
    background: url(/media/img/brainnet-icon-top.svg) no-repeat 0 0;
    background-size: contain;
    position: absolute;
    top: -12%;
    left: -20%;
    width: 70%;
    height: 0;
    opacity: 0.04;
    padding-bottom: 70%;
  }
}

@media only screen {
  .modal-introduction p:last-child {
    margin-bottom: 0;
  }
}

@media only screen {
  .modal-introduction-image img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 124px;
  }
}

@media only screen and (max-width: 600px) {
  .modal-introduction-image img {
    max-height: 100px;
  }
}

@media only screen {
  .modal-introduction-close {
    position: absolute;
    top: 12px;
    right: 16px;
    font-size: 1rem;
    font-weight: 400;
  }

  .modal-introduction-close svg {
    margin-top: -1px;
  }

  .modal-introduction-close span {
    margin: 0;
    display: flex;
    align-items: center;
  }
}

@media only screen {
  .modal-introduction-content {
    min-height: 340px;
  }
}

@media only screen {
  .modal-introduction .modal-introduction-nav {
    display: flex;
    align-items: center;
  }

  .modal-introduction .modal-introduction-nav .carousel-indicators {
    flex-grow: 1;
    margin: 0;
  }
}

@media only screen and (max-width: 600px) {
  .modal-introduction .modal-introduction-nav .carousel-indicators {
    flex-grow: 1;
  }

  .modal-introduction .modal-introduction-nav .carousel-indicators button {
    display: none;
  }
}

@media only screen {
  .modal-introduction .modal-introduction-nav .carousel-control-next,
  .modal-introduction .modal-introduction-nav .carousel-control-prev,
  .modal-introduction .modal-introduction-nav .carousel-indicators {
    position: static;
  }
}

@media only screen {
  .modal-introduction .modal-introduction-nav .carousel-control-next,
  .modal-introduction .modal-introduction-nav .carousel-control-prev {
    width: 98px;
    white-space: nowrap;
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .modal-introduction .modal-introduction-nav .carousel-control-next,
  .modal-introduction .modal-introduction-nav .carousel-control-prev {
    width: auto;
  }
}

@media only screen {
  .modal-introduction .modal-introduction-nav .carousel-control-prev {
    display: block;
    text-align: left;
    margin-left: -8px;
  }

  .modal-introduction .modal-introduction-nav .carousel-control-prev-icon {
    background: 0 0;
  }
}

@media only screen {
  .modal-introduction .modal-introduction-nav .carousel-control-next {
    display: block;
    text-align: right;
    margin-right: -8px;
  }

  .modal-introduction .modal-introduction-nav .carousel-control-next-icon {
    background: 0 0;
  }
}

@media only screen {
  .modal-introduction .modal-introduction-nav .carousel-indicators button {
    border: 1px solid #293f96;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    box-sizing: border-box;
    opacity: 0.25;
  }

  .modal-introduction .modal-introduction-nav .carousel-indicators button.active {
    background-color: #293f96;
    border-color: #293f96;
    opacity: 1;
  }
}

[hidden] {
  display: none;
}

.visually-hidden {
  display: none;
}
