.cursor-pointer {
  cursor: pointer;
}

.h-fit-content {
  height: fit-content;
}

.navbar.date .navbar-text {
  color: #483d3f;
}

// end navbar component

// start hours-and-expenses

.hours-and-expense-filters .mr-4.p-4.bg-background-100.rounded {
  display: none !important;
}

thead.datagrid-no-tables-header tr {
  background: #293f96 !important;
  color: #fff;
}

tfoot.datagrid-no-tables-footer {
  background: #f0f0f0;
}

//end hours-and-expenses

// start profile component

.navbar-account .filter-active i-feather svg {
  width: 12px !important;
  height: 12px !important;
}

#navbarNav .navbar-nav .nav-item.dropdown.show .nav-link svg.feather-filter {
  -ms-transform: none;
  transform: none;
}

#navbarNav .navbar-nav .nav-item.dropdown .nav-link svg {
  height: 14px;
  width: 14px;
}

// end profile component

.toast-container .ngx-toastr {
  max-width: 100%;
}

.toast-container .ngx-toastr a {
  color: inherit;
  font-weight: 600;
  text-decoration: underline;
}

.toast-container .ngx-toastr .col-1 {
  -ms-flex: 0 0 45px;
  flex: 0 0 45px;
  max-width: 45px;
}

.toast-container .ngx-toastr .col-10 {
  -ms-flex: 0 0 calc(100% - 90px);
  flex: 0 0 calc(100% - 90px);
  max-width: calc(100% - 90px);
}

// end ngx-toastr

// start faq

ngb-accordion .card-header .btn-link {
  text-align: left !important;
  text-decoration: none !important;
}

accordion-button {
  text-align: left !important;
  text-decoration: none !important;
}

// end faq

// start new styling changes

.bg-light-blue {
  background-color: #f4f5ff;
}

.text-white {
  color: #fff;
}

.btn-non-clickable {
  cursor: default !important;
}

.custom-control-input.bg-white:checked ~ .custom-control-label:before {
  background-color: white;
}

.custom-switch .custom-control-label:hover:before {
  border: 1px solid #483d3f !important;
}

.btn-secondary:focus,
.btn-secondary:visited {
  background-color: #fff !important;
  color: #231f20 !important;
}

.btn-icon {
  padding: 0.375rem;
}

select option {
  background-color: white;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #293f96;
}

select option:checked {
  color: #0083ad !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #f0f0f0;
}

.custom-switch .custom-control-label:before {
  background-color: #ffffff;
}

.custom-switch .custom-control-label:after {
  background-color: #483d3f;
}

.download-overview {
  width: max-content;
}

.custom-switch input:disabled,
.custom-checkbox input:disabled {
  display: none;
}

.custom-control :disabled,
.custom-control :disabled + label {
  opacity: 1 !important;
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #f0f0f0;
}

.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  color: #607d8b;
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label:before,
.custom-control-input[disabled] ~ .custom-control-label:before {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label:hover:before {
  border: 1px solid #f0f0f0 !important;
}

.custom-control-input[disabled] ~ .custom-control-label:after {
  background-color: #f0f0f0;
}

.custom-switch .custom-control-input:checked:disabled ~ .custom-control-label:after {
  background-color: #cccccc;
}

.custom-radio .custom-control-input[disabled] ~ .custom-control-label:after {
  background-color: #f0f0f0;
  border-radius: 20px;
  border: 0px;
}

.custom-radio .custom-control-input[disabled] ~ .custom-control-label:before {
  border: none;
  background: none;
  background-color: #f0f0f0 !important;
}

.input-error {
  border: 1px solid #ab0a3d !important;
}

.form-control:focus {
  color: #231f20;
}

.form.form-light .form-control:focus,
.form.form-light .form-control.input-error {
  background-color: white;
}

.form-control {
  color: #231f20;
  border: 1px solid #a5b0bf;
}

input.custom-control-input[type='radio']:disabled {
  display: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f0f0f0;
  border-color: #f0f0f0 !important;
  color: #cccccc;
  opacity: 1 !important;
}

.input-group input:disabled ~ .input-group-append > .btn,
.input-group input:disabled ~ .input-group-prepend > .btn {
  background-color: #f0f0f0;
  border-color: #f0f0f0 !important;
  opacity: 1 !important;
  cursor: auto;
}

.custom-control-label:after {
  background-position-x: center;
  background-position-y: center;
}

.custom-control .custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  border-color: #c1c8cc;
}

.custom-checkbox .custom-control-label:after {
  background-size: 70%;
}

img.tile-icon {
  height: 28px;
  width: 28px;
}

i-feather.tile-icon svg {
  width: 28px !important;
  height: 28px !important;
}

i-feather.tile-icon {
  stroke-width: 1px !important;
  width: 28px !important;
  height: 28px !important;
  color: #0083ad;
}

.feather-help-circle {
  stroke: #293f96;
}

i-feather#header-tooltip > svg.feather.feather-help-circle {
  stroke: white !important;
}

@media only screen and (max-width: 769px) {
  .card.nav-tabs-has-overview {
    border: 0px !important;
  }
}

@media only screen and (min-width: 768px) {
  .navtabs-datagrid-searchable__toolbar > div {
    padding: 4px 0.2rem;
  }
}

.nav-tabs.bg-primary .nav-link.active,
.nav-tabs.bg-primary .nav-link:hover {
  background: #f0f0f0;
  color: #293f96;
}

.form-control:focus {
  box-shadow: none;
}

.custom-control-label:before {
  border: 1px solid #c1c8cc;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  background-color: #fff;
}

.custom-control-label:hover:before {
  border: 1px solid #483d3f;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #293f96;
  border-color: #483d3f;
  background-color: #f0f0f0;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-6 -6 12 12'%3E%3Ccircle r='6' fill='%23293F96'/%3E%3C/svg%3E") !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23293F96' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
}

.input-error {
  background: none;
}

.input-error:hover {
  border-color: #ab0a3d !important;
}

.input-error:active,
.input-error:focus,
.input-error:hover {
  box-shadow: none;
  background: none;
}

.form-group.read-only .input-group,
.readonly-input-field {
  color: #231f20;
}

.form-control:focus {
  background-color: #f0f0f0;
}

.form-control:hover,
.multiselect.dropdown-toggle:hover {
  border-color: #483d3f;
}

.btn-link {
  text-decoration: underline;
}

.btn-link:focus {
  color: #0083ad;
}

.tooltip.show {
  opacity: 1;
}

.text-gray-dark {
  color: #483d3f !important;
}

.tooltip-inner {
  color: #c73800;
  background-color: #f3ebe0;
  border: 1px solid #c73800;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before,
.bs-tooltip-left .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before,
.bs-tooltip-right .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before {
  border-top-color: #c73800 !important;
  top: calc(100% - 12px);
}

.bg-info-100 {
  font-weight: 500;
  color: #293f96;
}

.bg-warning-100 {
  font-weight: 500;
  color: #c73800;
}

.bg-danger-100 {
  font-weight: 500;
  color: #ab0a3d;
}

.bg-success-100 {
  font-weight: 500;
  color: #483d3f;
}

.alert-success {
  font-weight: 500;
}

a.nav-link.active {
  font-weight: 500;
}

.alert .btn.btn-secondary {
  background-color: #ffffff !important;
  border-color: #483d3f !important;
}

.alert {
  padding-right: 17.5px;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #ffffff !important;
  color: #231f20 !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15) !important;
  box-sizing: border-box;
}

.btn-secondary {
  background-color: #fff !important;
  border: 1px solid #483d3f !important;
  color: #231f20 !important;
}

.btn-secondary:focus,
.btn-secondary:visited,
.btn.btn-secondary:focus,
.btn.btn-secondary:visited {
  border: 1px solid #483d3f !important;
}

li.bullit-point::before {
  content: '\2022';
  font-weight: bolder;
  font-size: 26px;
  line-height: 24px;
  margin-left: -1em;
  color: #c73800;
  padding-right: 5px;
  vertical-align: middle;
  display: inline;
  padding-left: 8px;
}

.brainnet-ul {
  padding-left: 20px;
}

.bullit-point app-money-field .form-group {
  margin-bottom: 0;
}

li.bullit-point span {
  margin-left: 15px;
  display: inline-grid;
}

.btn-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-secondary:not(:disabled):not(.disabled):hover {
  border: 1px solid #483d3f !important;
  background-color: #f0f0f0 !important;
}

.btn-secondary-light,
.btn-secondary-light:focus,
.btn-secondary-light:visited {
  background-color: #fff !important;
  border-color: #293f96 !important;
  color: #293f96 !important;
  //font-weight: 700 !important;
  border-width: 1px !important;
  box-shadow: none !important;
}

.btn-secondary-light:hover,
.btn-secondary-light:active {
  background-color: #293f96 !important;
  color: #fff !important;
  border-color: #293f96 !important;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.7 !important;
  background-color: #f0f0f0 !important;
  border-color: #f0f0f0 !important;
  color: #231f20 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #293f96;
}

.nav-pills.nav .nav-link:before {
  background: #f16752;
}

.block-item:disabled:before,
.block-item[disabled]:before {
  opacity: 0;
}

a.d-block.bg-background-100.p-4.rounded.mb-4.block-item {
  border: 1px solid #f0f0f0 !important;
}

a.d-block.bg-background-100.p-4.rounded.mb-4.block-item:hover {
  background-color: #ffffff !important;
  border: 1px solid #293f96 !important;
  color: #293f96;
}

a.d-block.bg-background-100.p-4.rounded.mb-4.block-item.disabled {
  background-color: #ffffff !important;
  border: 1px dashed #483d3f !important;
  box-sizing: border-box;
  border-radius: 6px;
  color: #483d3f !important;
  opacity: 0.7;
}

a.d-block.bg-background-100.p-4.rounded.mb-4.block-item.disabled[disabled='true']:hover {
  background-color: #ffffff !important;
  border: 1px dashed #483d3f !important;
  box-sizing: border-box;
  border-radius: 6px;
  color: #483d3f !important;
  opacity: 0.7;
}

.d-block.block-item {
  color: #231f20;
}

.d-block.block-item.disabled {
  background-color: white !important;
  color: #a3aac3;
}

.nav-tabs.bg-primary .nav-link {
  color: inherit;
}

.nav-tabs.bg-primary .nav-link.active {
  color: #293f96;
  background: #ffffff !important;
}

.nav-tabs .nav-item.nav-link.active i-feather {
  color: inherit !important;
}

.nav-pills-horizontal .nav-link.active,
.nav-tabs .nav-link.active {
  color: #ffffff;
}

.nav-tabs .nav-item {
  margin-bottom: 0px;
}

.bg-primary {
  background-color: #293f96 !important;
}

.bg-primary * {
  color: #ffffff;
}

.bg-blue {
  background-color: #293f96;
  color: #ffffff;
}

.icon-teal {
  color: #0083ad;
}

// end new styling changes

// start css navbar

.navbar-account-mobile li.nav-item.show.dropdown i-feather svg {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.placeholder > i-feather {
  height: 80px !important;
  width: 80px !important;
}

@media only screen and (min-width: 768px) {
  .navbar-account-desktop {
    display: flex !important;
  }

  #navbarNav.show .nav-item.active:before {
    display: none !important;
  }

  #navbarNav .navbar-nav .nav-item.active:after {
    display: block !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  #navbarNav .navbar-nav .nav-item.dropdown .nav-link svg {
    float: inherit !important;
  }

  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  #navbarNav .navbar-nav .nav-item {
    margin: 0 4px !important;
  }

  .navbar-account-mobile {
    display: none;
  }

  #navbarNav ul {
    padding: 0px !important;
  }

  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .top .dropdown-menu {
    top: 50px;
    border-top: 2px solid #f16752;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  #navbarNav {
    position: inherit;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff none repeat scroll 0 0;
    border-bottom: 0px;
  }

  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  #navbarNav .navbar-nav {
    border-top: 0px solid #e1e8ee;
    padding-bottom: 0px;
  }
}

// end css navbar

.btn.disabled,
.btn:disabled {
  cursor: auto;
}

i-feather.align-middle {
  position: inherit;
}

.invalid-field {
  color: #ab0a3d !important;
}

button.close:focus {
  outline: none;
}

a.disabled {
  cursor: default;
}

.icon-light {
  opacity: 0.5;
}

.icon-light {
  opacity: 0.5;
}

.card .card-header.bg-primary.content-header > a:hover {
  background: inherit !important;
}

.card .card-header.bg-primary.content-header > a:hover svg,
.card .card-header.bg-primary.content-header > a:hover svg * {
  color: #fff !important;
}

.side-by-side {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

i-feather.align-middle {
  position: absolute;
  top: calc(50% - 10px);
}

i-feather.position-top {
  position: absolute;
  top: 2px;
}

i-feather {
  height: 20px !important;
  width: 20px !important;
}

app-job-request-details {
  width: inherit;
}

.navtabs-datagrid-searchable__toolbar-table .input-group {
  width: 300px;
}

// start payment-proposal

app-text-field.invalid-field .form-group .input-group input {
  background-color: #f1e0e3;
  border: 1px solid #ab0a3d;
}

app-text-field#invoice-number .form-group {
  margin-bottom: 0px;
}

app-text-field#invoice-number .form-group .input-group input {
  margin-bottom: 8px;
}

app-text-field#invoice-number .form-group .invalid-field {
  margin-top: 0px;
}

.invalid-field.invoice-number-not-unique {
  margin-top: 0px;
}

// end payment-proposal

// start professional-details
.personal-details-mutation dynamic-form dynamic-form-group div .clearfix {
  display: none !important;
}

.add-address-line {
  margin-top: -15px;
}

// end professional-details

// start css stepper
@media only screen and (min-width: 900px) {
  .nav-pills.nav .nav-link svg {
    margin-right: 0px;
  }
}

// end css stepper

// start css datepicker
@media only screen and (max-width: 400px) {
  .ngb-dp-body {
    transform: translate(70px, 483px) !important;
  }
}

.ngb-dp-day.disabled .text-muted {
  color: #ab0a3d !important;
}

.ngb-dp-month:last-child .ngb-dp-week {
  padding-right: 0.6rem !important;
}

.wider {
  width: 100%;
}

ngb-datepicker-navigation-select.ngb-dp-navigation-select select {
  margin: 0 2px;
}

.ngb-dp-day .btn-light {
  background: none !important;
}

.ngb-dp-day .btn-light:hover {
  background-color: #f0f0f0 !important;
  border-color: #f0f0f0 !important;
}

.btn-light.bg-primary.text-white {
  background: #293f96 !important;
}

.ngb-dp-header {
  background-color: #f4f5ff !important;
}

.ngb-dp-weekdays {
  background-color: #f4f5ff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.ngb-dp-arrow *,
.ngb-dp-arrow::before,
.ngb-dp-arrow::after {
  border: none;
}

.ngb-dp-navigation-select *,
.ngb-dp-navigation-select::before {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.ngb-dp-weekday.small {
  font-size: 100%;
  color: #231f20;
}

.ngb-dp-arrow-btn:focus {
  outline-width: 0px !important;
}

.ngb-dp-arrow-btn:focus {
  outline-width: 0px !important;
}

// end css datepicker

// start css filters

.btn-secondary.filters-toggle {
  display: flex;
}

.btn-secondary.filters-toggle.toggled {
  border-color: #293f96 !important;
  background-color: #293f96 !important;
  color: white !important;
}

.navtabs-datagrid-searchable__toolbar-search {
  display: inherit;
  margin-bottom: 5px;
}

app-tag a.btn.btn-light.mr-1 {
  margin-bottom: 5px;
}

@media only screen and (max-width: 768px) {
  .navtabs-datagrid-searchable__toolbar-active {
    display: block;
  }

  .navtabs-datagrid-searchable__toolbar-active app-tag a {
    margin-top: 5px;
    margin-right: 10px !important;
  }
}

app-filters .mr-4.p-4.bg-background-100.rounded {
  margin-right: 0px !important;
  display: flex;
  flex-direction: column;

  .navtabs-datagrid-searchable__filters perfect-scrollbar {
    height: 100%;
  }
}

// end css filters

/* .ng-star-inserted {
  text-align: left;
} */

// start css vertical-tab
.accordion-item[aria-expanded='true'] i-feather:last-child svg {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  float: right;
}

.accordion-item[aria-expanded='true'] svg:last-child {
  transform: none;
}

// end css vertical-tab

// start css tab-control

@media only screen and (max-width: 768px) {
  app-tab-control.nav-tabs.drop-tabs .nav-item.nav-link div {
    font-size: 1.429rem;
    margin: 0 !important;
  }

  .drop-tabs .navtabs-icons .nav-link:not(.active) {
    display: none;
  }

  .nav-tabs.drop-tabs .nav {
    padding-left: 0 !important;
    background-color: #293f96 !important;
  }

  .nav-tabs li.back-mobile {
    border: 0 !important;
    border-right: 1px solid #fff !important;
    line-height: 22px;
    color: white;
  }

  .nav-tabs li.back-mobile {
    display: block !important;
  }

  .nav-tabs li.back-mobile .nav-link {
    display: block !important;
    height: 100%;
    padding-top: 19px;
  }
}

app-tab-control.nav-tabs.drop-tabs .bg-white.wider .container-xl {
  padding: 0 !important;
}

app-tab-control.nav-tabs.drop-tabs .bg-white.wider {
  padding: 1.5rem !important;
}

.nav-tabs li.back-mobile {
  display: none;
}

.nav-tabs li.back-mobile .nav-link i-feather svg {
  opacity: 1;
}

.nav-tabs li.back-mobile .nav-link i-feather {
  margin-right: 0px;
  color: inherit;
}

.nav-tabs li.back-mobile .nav-link i-feather:hover svg {
  color: #231f20 !important;
}

.nav-pills.nav .nav-link svg {
  color: inherit;
  opacity: 0.5;
}

@media only screen and (min-width: 769px) {
  .blue-header {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  datatable-scroller.datatable-scroll
    datatable-row-wrapper.datatable-row-wrapper:last-child
    datatable-body-row.datatable-body-row {
    border-radius: 0 0 0.5rem 0.5rem;
  }

  datatable-scroller.datatable-scroll {
    border-radius: 0 0 0.5rem 0.5rem;
  }
}

@media only screen and (max-width: 769px) {
  .nav-tabs.drop-tabs {
    margin-left: -16px;
    margin-right: -16px;
    border-radius: 0;
  }

  .blue-header {
    border-radius: 0;
  }
}

.nav-tabs.bg-primary * {
  color: inherit;
}

.nav-tabs.bg-primary .nav.container-xl {
  padding-left: 0.5rem !important;
}

@media only screen and (max-width: 768px) {
  .nav-tabs.bg-primary .nav-link.active {
    background: 0 0 !important;
    color: white;
  }
}

.nav-tabs .nav-link i-feather {
  margin-right: 8px;
}

.nav-tabs .nav-link i-feather svg.feather-star {
  fill: #231f20;
}

//end css tab-control

//begin css filters

//end css filters

//start css picklist
.search-icon svg.feather.feather-search {
  width: 14px !important;
  height: 14px !important;
  margin-right: 8px;
}

//end css picklist

// start css perfect-bar
.scroll-container {
  .ng-scrollbar-thumb {
    opacity: 0.6;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }
}

// end css perfect-bar

//start css data-table

.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  display: none !important;
}

datatable-header-cell {
  height: 100%;
}

.navtabs-datagrid__table {
  min-height: 500px;
}

datatable-body.datatable-body {
  max-height: 750px !important;
  overflow-y: scroll;
}

.datatable-body-cell-label span {
  display: inline-table;
}

span.sort-btn.sort-asc.datatable-icon-up:before,
span.sort-btn.sort-desc.datatable-icon-down:before {
  display: none;
}

i-feather.show-sort-option {
  display: none;
}

.datatable-header-cell.datatable-header-cell div .header-template:hover .show-sort-option {
  display: inline-block;
}

.header-light datatable-header #header-tooltip {
  color: #083f76;
}

.datatable-header-cell.datatable-header-cell div .header-template:hover i-feather.show-sort-option svg {
  transform: rotate(180deg);
}

datatable-header-cell.datatable-header-cell {
  padding: 0px !important;
}

datatable-header-cell.datatable-header-cell div .header-template {
  padding: 10px;
  display: inline-flex;
  align-items: center;
}

span.header-template {
  width: 100%;
  height: 100%;
}

svg.feather.feather-arrow-down,
svg.feather.feather-arrow-up {
  width: 16px;
  height: 16px;
  position: relative;
  transition: transform 0.3s ease;
}

.datatable-header-cell-template-wrap {
  white-space: normal !important;
}

.datatable-header-inner .datatable-row-center {
  width: 100% !important;
}

.navtabs-datagrid__table__scroll-container {
  position: relative;
}

.datatable-body {
  scrollbar-width: none;
  -ms-overflow-style: none !important;
}

datatable-body.datatable-body::-webkit-scrollbar {
  width: 0px !important;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
  width: 100% !important;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  border-bottom: 0px;
}

data-table ngx-datatable {
  border-radius: 0.5rem;
}

data-table.sticky-table ngx-datatable {
  border-radius: 0.5rem 0 0.5rem 0;
}

datatable-summary-row datatable-body-row.datatable-body-row.datatable-row-odd {
  background: #f4f5ff;
  color: #231f20;
  font-weight: 500;
  border-radius: 0 0 0.5rem 0.5rem;
}

datatable-body-row.datatable-body-row.datatable-row-odd:hover {
  background-color: #f4f5ff !important;
  color: inherit !important;
  cursor: default;
}

@media only screen and (max-width: 1200px) {
  .hide-on-tablet-and-mobile {
    display: none !important;
    visibility: hidden !important;
    width: 0px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .hide-on-big-screen {
    display: none !important;
    visibility: hidden !important;
    width: 0px !important;
  }

  data-table.sticky-table
    ngx-datatable
    div
    datatable-body
    datatable-selection
    datatable-scroller
    datatable-row-wrapper
    datatable-body-row
    .datatable-row-center.datatable-row-group {
    border-right: none !important;
  }

  data-table.sticky-table .ngx-datatable.bootstrap .datatable-body .datatable-body-row {
    border-right: none !important;
  }

  data-table.sticky-table
    ngx-datatable
    datatable-body
    datatable-selection
    datatable-scroller.datatable-scroll
    datatable-row-wrapper.datatable-row-wrapper:last-child
    datatable-body-row.datatable-body-row {
    border-radius: 0 0 0 0.5rem;
  }

  data-table.sticky-table ngx-datatable datatable-body datatable-selection datatable-scroller.datatable-scroll {
    border-right: 0px;
    border-radius: 0 0 0m 0.5rem;
  }
}

.header-light ngx-datatable div datatable-header {
  background: #f0f0f0;
  color: #293f96;
  font-weight: 500;
  border: 1px solid #e1e8ee !important;
}

datatable-body-row.datatable-body-row.datatable-row-odd {
  background: white;
}

datatable-body-row {
  cursor: pointer;
}

.datatable-header {
  display: table-header-group;
  background-color: #293f96;
  color: #fff;
}

.navtabs-datagrid-searchable .navtabs-datagrid__table__scroll-container {
  overflow: hidden;
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .datatable-body.datatable-body {
    font-size: 13px;
  }

  .datatable-header-cell {
    font-size: 13px;
  }

  data-table.sticky-table ngx-datatable {
    border-radius: 0.5rem 0 0 0 !important;
  }
}

.ngx-datatable.bootstrap {
  font-size: 14px;
}

datatable-scroller.datatable-scroll {
  border-left: 1px solid #e1e8ee;
  border-right: 1px solid #e1e8ee;
  /*     border-radius: .5rem !important; */
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  vertical-align: top;
  border-bottom: 1px solid #e1e8ee;
  /*     border-left   : 1px solid #e1e8ee;
    border-right       : 1px solid #e1e8ee; */
  border-top: 0px;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: white;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even:hover,
.ngx-datatable.bootstrap datatable-body-row.datatable-body-row.datatable-row-odd:hover,
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #f4f5ff;
  color: inherit !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #f4f5ff;
  color: inherit !important;
}

span.datatable-cell.small.text-gray-dark {
  font-size: 90%;
}

.datatable-icon {
  margin-top: -4px;
  vertical-align: middle;
  margin-left: 7px;
}

button.btn.btn-secondary.min-w-100.float-right.mr-1.mb-2:hover:before {
  background-color: red;
}

@media only screen and (max-width: 768px) {
  .ngx-datatable.bootstrap .datatable-summary-row .datatable-body-row .datatable-body-cell {
    font-weight: 400;
  }

  .ngx-datatable {
    border-radius: 0px;
  }

  proposal-search-buttons.button-set button {
    float: none !important;
  }

  a.nav-item.nav-link.text-center.border-bottom-0.active {
    display: block;
  }

  datatable-row-wrapper.datatable-row-wrapper {
    width: inherit;
  }

  datatable-body.datatable-body::-webkit-scrollbar-thumb {
    background: transparent !important;
    border-radius: 4px !important;
  }

  datatable-body-cell.datatable-body-cell.sort-active {
    height: fit-content !important;
  }

  .datatable-row-center.datatable-row-group {
    height: fit-content !important;
  }

  .ngx-datatable .datatable-body .datatable-body-row > div {
    display: block !important;
  }

  datatable-body-row.datatable-body-row {
    width: 100% !important;
  }

  .ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 4px 0px;
  }

  datatable-body-row.datatable-body-row {
    padding: 10px;
  }

  datatable-body-cell.datatable-body-cell.sort-active {
    width: 100% !important;
    max-width: 100% !important;
  }

  datatable-header.datatable-header {
    display: none !important;
  }

  .ngx-datatable.bootstrap .datatable-body .datatable-body-row {
    border-bottom: 0px;
  }

  datatable-body-row.datatable-body-row:first-child {
    border-top: 1px solid #e1e8ee !important;
  }

  .ngx-datatable .datatable-body .datatable-row-wrapper:last-child {
    border-bottom: 1px solid #e1e8ee !important;
  }

  .ngx-datatable.bootstrap .datatable-body .datatable-body-row {
    border-left: 0px;
    border-right: 0px;
  }

  datatable-scroller.datatable-scroll {
    width: 100% !important;
  }

  .datatable-body-cell-label span:before {
    display: table-cell;
    width: 140px;
    content: attr(title);
    overflow: hidden;
    font-weight: 700;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 769px) {
  .ngx-datatable.bootstrap .datatable-body .datatable-body-row.active .datatable-row-center.datatable-row-group:before,
  .ngx-datatable.bootstrap
    .datatable-body
    .datatable-row-wrapper
    .datatable-body-row
    .datatable-row-center.datatable-row-group:hover:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2px;
    background-color: #f16752;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-row-center.datatable-row-group:hover {
    font-weight: 500;
  }

  .datatable-row-center.datatable-row-group:hover data-table.sticky-table ngx-datatable {
    border-radius: 0.5rem 0 0.5rem 0 !important;
  }

  datatable-scroller.datatable-scroll,
  datatable-scroller.datatable-scroll,
  datatable-row-wrapper.datatable-row-wrapper,
  datatable-body-row.datatable-body-row.active.datatable-row-even,
  .datatable-row-center.datatable-row-group,
  .datatable-row-center.datatable-row-group,
  datatable-body-row.datatable-body-row.datatable-row-odd,
  datatable-body-row.datatable-body-row.datatable-row-even {
    width: 100% !important;
  }

  .hide-on-table-and-desktop {
    display: none;
  }
}

//end css data-table

//start remove spinners - (number-field)
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

//end remove spinners

//start context-refresher
i-feather.reset-context-img .feather {
  height: 14px !important;
  width: 14px !important;
}

//start context-refresher

.dropdown-menu.show {
  margin-top: 0;
}

.input-error ~ .input-group-append {
  background-color: white !important;
}

.input-error ~ .input-group-append .btn.btn-input,
.input-error:hover ~ .input-group-append .btn.btn-input {
  border-color: #ab0a3d;
  border-left: none;
}

.input-error ~ .input-group-prepend .btn.btn-input,
.input-error:hover ~ .input-group-prepend .btn.btn-input {
  border-color: #ab0a3d;
  border-right: none;
}

.input-group-prepend .btn.btn-input {
  border-right: none;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.input-with-prepend {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-non-clickable {
  cursor: auto;
}

input:focus ~ .input-group-append .btn.btn-input,
input:focus ~ .input-group-prepend .btn.btn-input {
  background-color: #f0f0f0;
}

input:hover ~ .input-group-append .btn.btn-input,
input:hover ~ .input-group-prepend .btn.btn-input {
  border-color: #483d3f;
}

.input-with-append {
  border-right: 0px !important;
}

.input-with-prepend {
  border-left: 0px !important;
}

.input-group-append .btn-input,
.input-group-prepend .btn-input {
  color: #63788e;
  border: 1px solid #a5b0bf;
}

.input-group-append .btn-input {
  border-left: none;
}

.input-group-prepend .btn-input {
  border-right: none;
}

.input-group-prepend {
  order: 0;
}

.input-with-prepend {
  order: 1;
}

.btn-input:focus {
  box-shadow: none;
}

.datatable-row-center.datatable-row-group {
  cursor: pointer;
}

.datatable-row-center {
  justify-content: space-between;
}

//start dumb tables

.datagrid-no-tables-header .datagrid-no-tables-cell:first-child {
  border-left: 1px solid #231f20;
}

.datagrid-no-tables-header .datagrid-no-tables-cell {
  border-bottom: none;
}

.datagrid-no-tables-header {
  background-color: #293f96;
}

.datagrid-no-tables-clickable .datagrid-no-tables-body .datagrid-no-tables-row:hover {
  background-color: #f4f5ff;
  font-weight: 500;
}

.datagrid-no-tables-clickable .datagrid-no-tables-body .datagrid-no-tables-row:hover > :first-child {
  border-left: 2px solid #f16752;
}

.datagrid-no-tables-header-light {
  background: #f0f0f0;
  color: #231f20;
  font-weight: 500;
}

//end dumb tables

.text-underlined {
  text-decoration: underline;
}

.datatable-summary-row .datatable-row-center.datatable-row-group {
  cursor: auto;
}

.language-options-flag {
  width: 28px;
  height: 18px;
  display: inline-block;
  background: url(/assets/img/flags/sprite-languages.svg) no-repeat;
}

.language-options-flag--en {
  background-position: -50px 0;
}

@media (min-width: 576px) {
  .modal-dialog-lg {
    max-width: 800px;
  }
}

.carousel-indicators button {
  border: 1px solid #293f96;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50%;
  margin-right: 7px !important;
  margin-left: 7px !important;
  box-sizing: border-box;
  opacity: 0.5;
}

.carousel-indicators button.active {
  background-color: #293f96;
  opacity: 1;
}

ngb-carousel:focus {
  outline: none;
}

.modal-introduction-close svg {
  width: 32px !important;
  height: 32px !important;
  stroke-width: 1px;
}

html {
  height: 100%;
}

body {
  height: 100%;
  min-width: auto !important;
}

.feather-icon-color {
  color: #148750 !important;
}

.plus-sign {
  font-size: 17px;
}

datatable-summary-row datatable-body-cell.datatable-body-cell.sort-active {
  height: fit-content !important;
}

datatable-summary-row datatable-body-row {
  height: fit-content !important;
}

.plus-sign-lg {
  font-size: 22px;
}

.plus-sign-xl {
  font-size: 26px;
}

app-vertical-icon-label img {
  width: 20px;
  height: 20px;
}

.accordion-item {
  display: none;
  text-decoration: none !important;
  margin: 0 -21px;
  color: #231f20 !important;
}

.accordion-item[aria-expanded='true'] {
  border-bottom: 1px solid #e1e8ee;
  margin-bottom: 24px;
  background: #f0f0f0;
}
.accordion-item[aria-expanded='true'] svg:last-child {
  transform: rotate(180deg);
}

@media only screen and (max-width: 576px) {
  .accordion-item {
    padding: 1rem 16px;
  }
}

@media only screen and (max-width: 768px) {
  .accordion-item {
    display: flex;
  }

  .accordion-item div {
    width: 100%;
  }

  datatable-body-cell.datatable-body-cell.sort-active {
    min-height: 30px !important;
  }

  .hide-on-mobile {
    display: none;
  }

  .accordion-mobile .accordion-mobile-tab-content.active,
  .accordion-mobile .accordion-mobile-tab-content > .tab-pane {
    display: block;
    opacity: 1;
  }
}

.spinner-border {
  color: #293f96;
}

.custom-recommended-label {
  border-radius: 5px;
  padding: 8px;
  margin-left: 5px;
  background-color: #e5ebf1;
  color: #293f96;
  font-size: 0.9rem;
  font-weight: 500;
}

.error-duplicate-email-address {
  margin-top: -6px;
}

.placeholder {
  opacity: 1 !important;
  cursor: default !important;
  background-color: inherit !important;
}
